import { useSelector } from "react-redux";
import styles from "./CreatedFrom.module.css";

const CreatedFrom = ({ id, dateTime }) => {
	const me = useSelector((state) => state.userData.name);
	const users = useSelector((state) => state.values.users);
	const user = id ? users.find((entry) => entry.id === id)?.name || "???" : me;

	return (
		<div className={styles.createdFrom}>
			<span>
				{dateTime ? dateTime : "Jetzt"} • {user}
			</span>
		</div>
	);
};

export default CreatedFrom;
