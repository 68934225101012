import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../shared/axios";
import { logout } from "../auth/authSlice";

const namespace = "region";

const initialState = null;

export const getRegions = createAsyncThunk(`${namespace}/getRegions`, async (_, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.get("region");
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

const regionSlice = createSlice({
	name: namespace,
	initialState,
	extraReducers: (builder) => {
		builder
			.addCase(getRegions.fulfilled, (state, { payload }) => {
				return payload;
			})
			.addCase(logout.fulfilled, (state, { payload }) => {
				return initialState;
			});
	}
});

export default regionSlice.reducer;
