import { Fragment } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import CategoryEditor from "../../components/Category/CategoryEditor";
import Panel from "../../hoc/Panel/Panel";

const Structure = () => {
	const categories = useSelector((state) => state.category.categories);

	return (
		<Fragment>
			<Panel size="large" title="Struktur">
				{categories && <CategoryEditor />}
			</Panel>
			<Outlet />
		</Fragment>
	);
};

export default Structure;
