import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import styles from "./SideMenuItem.module.css";

const SideMenuItem = ({ name, baseUrl, url }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const classNames = [styles.menuItem];
	location.pathname === baseUrl + url && classNames.push(styles.selected);
	return (
		<li className={classNames.join(" ")} onClick={() => navigate(baseUrl + url)}>
			{name}
		</li>
	);
};

export default SideMenuItem;
