import { displayFloatTimeRange, endTimeToPercent, startTimeToPercent } from "../../../../../../shared/functions";
import { serviceTemplateTypes } from "../../../../../../shared/globals";
import styles from "./TimeBar.module.css";

const TimeBar = ({ time, type, dateInt, groupId, inGroup, group, hoverTimeBar, setHoverTimeBar, actions }) => {
    let showTitle = true;
    let addable = false;
    let action = null;

    const classNames = [styles.time];
    if (type === serviceTemplateTypes.bookable) {
        if (hoverTimeBar === time.id) {
            classNames.push(styles.addable);
            if (time.startDate === time.endDate) {
                classNames.push(styles.selected);
            } else {
                if (dateInt === time.startDate) {
                    classNames.push(styles.selectedBeginn);
                } else {
                    classNames.push(styles.selectedEnd);
                }
            }
        }
        if (inGroup) {
            if (time.startDate === time.endDate) {
                classNames.push(styles.selected);
            } else {
                if (dateInt === time.startDate) {
                    classNames.push(styles.selectedBeginn);
                } else {
                    classNames.push(styles.selectedEnd);
                }
            }
        }
        if (group) {
            if (inGroup) {
                classNames.push(styles.removable);
                action = () => actions.removeFromGroup(time.id);
            } else if (groupId) {
                classNames.push(styles.disabled);
                // Already in other group
            } else {
                addable = true;
                group.entries.forEach((service) => {
                    if (!(time.endTimeStamp <= service.startTimeStamp || time.startTimeStamp >= service.endTimeStamp)) {
                        addable = false;
                    }
                });
                if (addable) {
                    classNames.push(styles.addable);
                    action = () => actions.addToGroup(time.id);
                } else {
                    classNames.push(styles.disabled);
                }
            }
        }
    }
    const style = {
        backgroundColor: time.color,
        color: time.whiteText ? "white" : "#535557"
    };
    if (time.start > time.end) {
        if (time.startDate === dateInt) {
            style.left = startTimeToPercent(time.start) + "%";
            style.right = "0%";
        } else {
            style.left = "0%";
            style.right = endTimeToPercent(time.end) + "%";
            showTitle = false;
        }
    } else {
        style.left = startTimeToPercent(time.start) + "%";
        style.right = endTimeToPercent(time.end) + "%";
    }
    const title = displayFloatTimeRange(time.start, time.end, false);

    const mouseEnter = () => {
        if (addable) {
            setHoverTimeBar(time.id);
        }
    };

    const mouseLeave = () => {
        if (addable) {
            setHoverTimeBar(0);
        }
    };

    return (
        <div className={classNames.join(" ")} style={style} title={title} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} onClick={action}>
            {showTitle && title}
        </div>
    );
};

export default TimeBar;
