import styles from "./TableRow.module.css";
import { ReactComponent as ErrorIcon } from "../../../assets/images/icon_warning_red.svg";

const TableRow = ({ index, row, fields, rowClickAction = null }) => {
	const classNames = [styles.row];
	if (index % 2) {
		classNames.push(styles.alt);
	}

	const result = fields.map((field) => (
		<div className={field.className} key={field.key}>
			<span>{field.showField ? field.showField(row) : row[field.key]}</span>
		</div>
	));

	if (row.error !== undefined) {
		result.unshift(
			<div className={styles.error} key="error">
				{row.error ? <ErrorIcon className={styles.errorIcon} /> : ""}
			</div>
		);
	}

	return (
		<div className={classNames.join(" ")} onClick={rowClickAction ? () => rowClickAction(row) : null}>
			{result}
		</div>
	);
};

export default TableRow;
