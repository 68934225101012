import styles from "./PanelError.module.css";
import nl2br from "react-nl2br";

import { ReactComponent as Warning } from "../../assets/images/icon_warning_red.svg";

const PanelError = ({ errors }) => {
	if (!errors) {
		return null;
	}
	return (
		<div className={styles.panel}>
			<Warning />
			<div>{nl2br(errors)}</div>
		</div>
	);
};

export default PanelError;
