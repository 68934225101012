import React from "react";
import styles from "./FieldLabel.module.css";

const FieldLabel = ({ label, children }) => {
	return (
		<label className={styles.label}>
			<span>{label}</span>
			{children}
		</label>
	);
};

export default FieldLabel;
