import { createSlice } from "@reduxjs/toolkit";

const namespace = "app";

const isLoadingAction = (action) => {
	return action.type.endsWith("pending");
};

const isFinishedLoadingAction = (action) => {
	return action.type.endsWith("fulfilled") || action.type.endsWith("rejected");
};

export const isLoading = (state) => state.loading;

const initialState = {
	loading: false,
	redirect: null
};

const appSlice = createSlice({
	name: namespace,
	initialState,
	reducers: {
		startLoading: (state) => (state.loading = true),
		setRedirect: (state, { payload }) => {
			state.redirect = payload;
		},
		clearRedirect: (state, { payload }) => {
			state.redirect = null;
		}
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(isLoadingAction, (state, action) => {
				state.loading = true;
			})
			.addMatcher(isFinishedLoadingAction, (state, action) => {
				state.loading = false;
			});
	}
});

export const { startLoading, setRedirect, clearRedirect } = appSlice.actions;

export default appSlice.reducer;
