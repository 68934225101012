import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearCloseOnExit } from "../../../../../features/message/messageSlice";
import { addContingent, updateContingent } from "../../../../../features/serviceCircle/serviceCircleSlice";
import styles from "./EditContingentRow.module.css";
import FooterButtons from "../../../../../ui/FooterButtons/FooterButtons";

const EditContingentRow = ({ row, cancel, close, serviceCircleId }) => {
	const [editRow, setEditRow] = useState({
		workDays: row.workDays,
		weekendDays: row.weekendDays,
		publicHoliDays: row.publicHoliDays
	});
	const ref = useRef();
	const dispatch = useDispatch();
	const closeOnExit = useSelector((state) => state.message.closeOnExit);

	const disabled = editRow.workDays === "" || editRow.weekendDays === "" || editRow.publicHoliDays === "";

	useEffect(() => {
		ref.current.focus();
	}, []);

	useEffect(() => {
		if (closeOnExit) {
			dispatch(clearCloseOnExit());
			close();
		}
	}, [dispatch, close, closeOnExit]);

	const change = (e) => {
		const value = e.target.value;
		const fieldname = e.target.name;

		if (value === "" || value.match(/^\d*$/)) {
			setEditRow((prev) => ({ ...prev, [fieldname]: value }));
		}
	};

	const save = () => {
		console.log("save");
		if (row.id) {
			dispatch(updateContingent({ id: row.id, serviceCircleId, data: { year: row.year, ...editRow } }));
		} else {
			dispatch(addContingent({ id: serviceCircleId, data: { year: row.year, ...editRow } }));
		}
	};

	return (
		<Fragment>
			<tr className={styles.editRow}>
				<td>{row.year}</td>
				<td>
					<input type="text" ref={ref} name="workDays" value={editRow.workDays} maxLength={3} onChange={change} />{" "}
				</td>
				<td>
					<input type="text" name="weekendDays" value={editRow.weekendDays} maxLength={3} onChange={change} />{" "}
				</td>
				<td>
					<input type="text" name="publicHoliDays" value={editRow.publicHoliDays} maxLength={3} onChange={change} />{" "}
				</td>
				<td></td>
			</tr>
			<tr>
				<td colSpan={5}>
					<FooterButtons paddingTop>
						<button type="button" onClick={() => cancel()}>
							Abbrechen
						</button>
						<button type="button" disabled={disabled} onClick={save}>
							Speichern
						</button>
					</FooterButtons>
				</td>
			</tr>
		</Fragment>
	);
};

export default EditContingentRow;
