import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import Panel from "../../../../hoc/Panel/Panel";
import Window from "../../../../hoc/Window/Window";
import FooterButtons from "../../../../ui/FooterButtons/FooterButtons";

import { createMedicalPractice } from "../../../../features/medicalPractice/medicalPracticeSlice";

import styles from "./NewMedicalPractice.module.css";
import * as routes from "../../../../shared/routes";
import { validateGLN } from "../../../../shared/functions";
import RadioSelect from "../../../../ui/RadioSelect/RadioSelect";
import { UseInEveryServiceCircle } from "../MedicalPracticeData/Address/Address";

const NewMedicalPractice = ({ cancel }) => {
    const [medicalPractice, setMedicalPractice] = useState({
        name: "",
        street: "",
        street2: "",
        zip: "",
        city: "",
        gln: "",
        useInEveryServiceCircle: "no"
    });
    const dispatch = useDispatch();
    const ref = useRef();

    const disabled = medicalPractice.street.trim().length < 2 || medicalPractice.zip.trim().length < 4 || medicalPractice.city.trim().length < 4 || (medicalPractice.gln !== "" && !validateGLN(medicalPractice.gln));

    useEffect(() => {
        ref.current.focus();
    }, []);

    const save = () => {
        dispatch(createMedicalPractice({ redirect: routes.MEDICAL_PRACTICES + "/{id}" + routes.PRAXIS_DATA, data: { ...medicalPractice } }));
    };

    const change = (e) => {
        const fieldname = e.target.name;
        const value = e.target.value;

        if ((fieldname === "zip" || fieldname === "gln") && !value.match(/^\d*$/)) {
            return;
        }

        setMedicalPractice((prev) => ({ ...prev, [fieldname]: value }));
    };

    const changeUseInEveryServiceCircle = (useInEveryServiceCircle) => {
        setMedicalPractice((prev) => ({ ...prev, useInEveryServiceCircle }));
    };

    return (
        <Window>
            <Panel title="Neue Praxis" size="medium" padding>
                <div className={styles.content}>
                    <label className={styles.label}>
                        <span>Praxisname</span>
                        <input type="text" name="name" ref={ref} value={medicalPractice.name} onChange={change} maxLength={128} />
                    </label>
                    <label className={styles.label}>
                        <span>Strasse</span>
                        <input type="text" name="street" value={medicalPractice.street} onChange={change} maxLength={128} />
                    </label>
                    <label className={styles.label}>
                        <span>Strasse 2</span>
                        <input type="text" name="street2" value={medicalPractice.street2} onChange={change} maxLength={64} />
                    </label>
                    <label className={styles.label}>
                        <span>PLZ</span>
                        <input type="text" name="zip" value={medicalPractice.zip} onChange={change} maxLength={4} className={medicalPractice.zip === "" || medicalPractice.zip.trim().length === 4 ? "" : styles.error} />
                    </label>
                    <label className={styles.label}>
                        <span>Ort</span>
                        <input type="text" name="city" value={medicalPractice.city} onChange={change} maxLength={64} />
                    </label>
                    <label className={styles.label}>
                        <span>GLN</span>
                        <input type="text" name="gln" value={medicalPractice.gln} onChange={change} maxLength={13} className={medicalPractice.gln === "" || validateGLN(medicalPractice.gln) ? "" : styles.error} />
                    </label>
                    <RadioSelect data={UseInEveryServiceCircle} label="Notfalldienst" keyName="id" valueName="value" selected={medicalPractice.useInEveryServiceCircle} setSelected={changeUseInEveryServiceCircle} />
                </div>
                <FooterButtons paddingTop>
                    <button type="button" onClick={cancel}>
                        Abbrechen
                    </button>
                    <button type="button" onClick={save} disabled={disabled}>
                        Ok
                    </button>
                </FooterButtons>
            </Panel>
        </Window>
    );
};

export default NewMedicalPractice;
