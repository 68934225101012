import { useEffect, useRef, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import nl2br from "react-nl2br";

import CreatedFrom from "../CreatedFrom/CreatedFrom";

import styles from "./Notes.module.css";
import CheckBox from "../../ui/CheckBox/CheckBox";

const emptyNote = { note: "", marked: false };

const Notes = ({ notes, addNote, updateNote }) => {
	const [show, setShow] = useState(false);
	const [note, setNote] = useState({ ...emptyNote });
	const ref = useRef();

	const countNotes = notes && notes.length > 0 ? notes.length : null;
	const title = countNotes ? (countNotes === 1 ? "Eine Notiz" : countNotes + " Notizen") : "Notizen";

	const save = () => {
		addNote(note);
		setNote({ ...emptyNote });
	};

	const update = (checked, id) => {
		updateNote(id, checked);
	};

	useEffect(() => {
		if (show) {
			ref.current.focus();
		}
	}, [show]);

	if (!show) {
		return (
			<div className={styles.noteBatch} onClick={() => setShow(true)}>
				{title}
			</div>
		);
	} else {
		return (
			<div className={styles.content}>
				{countNotes && <h4 className={styles.title}>{title}</h4>}
				<ul>
					<li>
						<CreatedFrom />
						<div className={styles.note}>
							<TextareaAutosize name="symptoms" ref={ref} className={styles.textarea} value={note.note} onChange={(event) => setNote((prev) => ({ ...prev, note: event.target.value }))} minRows={1} placeholder="Neue Notiz" />
						</div>
						<CheckBox name="Noch nicht erledigt" checked={note.marked} set={(marked) => setNote((prev) => ({ ...prev, marked }))} />
						{note.note.trim().length > 4 && (
							<div className={styles.right}>
								<button type="button" onClick={save}>
									Speichern
								</button>
							</div>
						)}
					</li>
					{countNotes &&
						notes.map((entry, index) => (
							<li key={index}>
								<CreatedFrom id={entry.createdFrom} dateTime={entry.createdOn} />
								<div className={styles.note}>{nl2br(entry.note)}</div>
								<CheckBox name="Noch nicht erledigt" value={entry.id} checked={entry.marked} set={update} />
							</li>
						))}
				</ul>
				<button type="button" onClick={() => setShow(false)}>
					Schliessen
				</button>
			</div>
		);
	}
};

export default Notes;
