import { ReactComponent as ArrowLeft } from "../../assets/images/icon_chevron_left.svg";
import { ReactComponent as ArrowRight } from "../../assets/images/icon_chevron_right.svg";

import styles from "./YearSelector.module.css";

const YearSelector = ({ year, setYear }) => {
	const decrease = () => {
		setYear(year - 1);
	};

	const increase = () => {
		setYear(year + 1);
	};
	return (
		<div className={styles.yearSelector}>
			<ArrowLeft onClick={decrease} />
			<span>{year}</span>
			<ArrowRight onClick={increase} />
		</div>
	);
};

export default YearSelector;
