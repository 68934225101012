import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

import { tryAutoSignUp } from "./features/auth/authSlice";
import { clearRedirect } from "./features/app/appSlice";
import * as routes from "./shared/routes";

import Login from "./containers/Login/Login";
import Home from "./containers/Home/Home";
import Layout from "./hoc/Layout/Layout";
import RequireAuth from "./components/RequireAuth/RequireAuth";
import RequireNoAuth from "./components/RequireNoAuth/RequireNoAuth";
import Structure from "./containers/Structure/Structure";
import Geography from "./containers/Geography/Geography";
import PublicHolidays from "./containers/PublicHolidays/PublicHolidays";
import PublicHoliday from "./containers/PublicHolidays/PublicHoliday/PublicHoliday";

import RosterPageSelector from "./containers/Roster/RosterPageSelector/RosterPageSelector";
import RosterState from "./containers/Roster/RosterState/RosterState";
import Services from "./containers/Roster/Services/Services";
import Doctors from "./containers/Roster/Doctors/Doctors";
import AllocationTable from "./containers/Roster/Allocation/AllocationTable/AllocationTable";
import AllocationCalendar from "./containers/Roster/Allocation/AllocationCalendar/AllocationCalendar";

import EditServiceCircle from "./components/Category/EditServiceCircle/EditServiceCircle";
import MedicalPracticeDetailSelector from "./components/MedicalPractices/MedicalPractice/MedicalPracticeDetailSelector/MedicalPracticeDetailSelector";
import PersonDetailSelector from "./components/Persons/Person/PersonDetailSelector/PersonDetailSelector";
import Persons from "./containers/Persons/Persons";
import MedicalPractices from "./containers/MedicalPractices/MedicalPractices";
import Allocation from "./containers/Roster/Allocation/Allocation";

const App = () => {
	const autoSignupTried = useSelector((state) => state.auth.autoSignupTried);
	const redirect = useSelector((state) => state.app.redirect);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (!autoSignupTried) {
			dispatch(tryAutoSignUp());
		}
	}, [autoSignupTried, dispatch]);

	useEffect(() => {
		if (redirect) {
			navigate(redirect);
			return () => {
				dispatch(clearRedirect());
			};
		}
	}, [redirect, dispatch, navigate]);

	if (!autoSignupTried) {
		return null;
	}

	return (
		<Routes>
			<Route element={<Layout />}>
				<Route element={<RequireAuth />}>
					<Route path="/" element={<Home />} />
					<Route path={routes.STRUCTURE} element={<Structure />}>
						<Route path=":id/*" element={<EditServiceCircle />} />
					</Route>
					<Route path={routes.GEOGRAPHY} element={<Geography />} />
					<Route path={routes.PUBLIC_HOLIDAYS} element={<PublicHolidays />} />
					<Route path={routes.PUBLIC_HOLIDAYS + "/:year"} element={<PublicHolidays />}>
						<Route path=":month/:day" element={<PublicHoliday />} />
						<Route path="*" element={<Navigate to={routes.PUBLIC_HOLIDAYS} />} />
					</Route>
					<Route path={routes.ROSTER} element={<RosterPageSelector />}>
						<Route path={routes.ROSTER_STATE} element={<RosterState />} />
						<Route path={routes.ROSTER_TIMES} element={<Services />} />
						<Route path={routes.ROSTER_DOCTORS} element={<Doctors />} />
						<Route path={routes.ROSTER_ALLOCATION} element={<Allocation />}>
							<Route path={routes.ROSTER_ALLOCATION_TABLE} element={<AllocationTable />} />
							<Route path={routes.ROSTER_ALLOCATION_CALENDAR} element={<AllocationCalendar />} />
						</Route>
					</Route>
					<Route path={routes.PERSONS} element={<Persons />}>
						<Route path={":id/*"} element={<PersonDetailSelector />} />
					</Route>
					<Route path={routes.MEDICAL_PRACTICES} element={<MedicalPractices />}>
						<Route path={":id/*"} element={<MedicalPracticeDetailSelector />} />
					</Route>
				</Route>
				<Route element={<RequireNoAuth />}>
					<Route path={routes.LOGIN} element={<Login />} />
				</Route>
			</Route>
		</Routes>
	);
};

export default App;
