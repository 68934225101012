import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import styles from "./BookNow.module.css";
import { ask, clear } from "../../../../features/ask/askSlice";
import * as askTypes from "../../../../shared/askTypes";
import { createRoster } from "../../../../features/roster/rosterSlice";

const BookNow = () => {
    const { rosterPeriodId } = useSelector((state) => state.category.selected);
    const rosterState = useSelector((state) => state.roster.state);
    const bookingsChecksum = useSelector((state) => state.roster.rosterPreview.bookingsChecksum);
    const answer = useSelector((state) => state.ask.answer);

    const dispatch = useDispatch();

    useEffect(() => {
        if (answer) {
            if (answer.type === askTypes.CREATE_ROSTER && answer.value) {
                dispatch(clear());
                dispatch(createRoster({ rosterPeriodId, data: { bookingsChecksum } }));
            }
        }
    }, [answer, rosterPeriodId, bookingsChecksum, dispatch]);

    const askCreateRoster = () => {
        dispatch(
            ask({
                type: askTypes.CREATE_ROSTER,
                title: "Dienstplan erstellen",
                question: "Möchtest Du den Dienstplan nun erstellen? Dieser Schritt kann nicht rückgängig gemacht werden.",
                buttons: [
                    { label: "Abbrechen", answer: null },
                    { label: "Ja", answer: true }
                ]
            })
        );
    };

    return (
        <div className={styles.bookNow}>
            <button type="button" disabled={rosterState.state !== "waitForMagic" || !bookingsChecksum} onClick={askCreateRoster}>
                Dienstplan erstellen
            </button>
        </div>
    );
};

export default BookNow;
