import { ReactComponent as Download } from "../../../../../assets/images/icon_download.svg";
import ToolTip from "../../../../../ui/ToolTip/ToolTip";
import ActionTools from "../../../../ActionTools/ActionTools";
import TimeBar from "./TimeBar/TimeBar";

import styles from "./Day.module.css";
import { serviceTemplateTypes } from "../../../../../shared/globals";

const Day = ({ date, dateInt, entries, disabled, hoverServiceId, setHoverServiceId, publicHoliday, group, hoverTimeBar, setHoverTimeBar, actions, locked }) => {
    const dayClassNames = [];

    const showAddService = !disabled && !group;
    let toolTip = null;
    if (publicHoliday) {
        toolTip = (
            <ToolTip>
                <span>{publicHoliday.name}</span>
            </ToolTip>
        );
        if (publicHoliday.isGlobal) {
            dayClassNames.push(styles.publicHoliday);
        } else {
            dayClassNames.push(styles.regionalHoliday);
        }
    }

    const addServiceToDay = () => {
        if (actions.askInsertOptions) {
            actions.askInsertOptions(date);
        }
    };

    const bars = [];
    if (entries.length) {
        const maxPos = Math.max(...entries.map((entry) => entry.pos));
        for (let i = 0; i <= maxPos; i++) {
            const entry = entries.find((entry) => entry.pos === i);
            if (entry) {
                const grouped = entry.groupId !== null;
                const barClasses = [styles.bar];
                entry.type === serviceTemplateTypes.fix && barClasses.push(styles.fix);
                const style = {};
                const inGroup = group && group.entries.find((groupEntry) => groupEntry.id === entry.id);
                const hover = hoverServiceId === entry.id;
                hover && barClasses.push(styles.hover);
                const barStart = entry.times.find((timeEntry) => timeEntry.startDate < dateInt) === undefined;
                barStart && barClasses.push(styles.barStart);
                entry.times.find((timeEntry) => timeEntry.endDate > dateInt) === undefined && barClasses.push(styles.barEnd);
                grouped && barClasses.push(styles.group);

                const times = entry.times
                    .filter((time) => time.startDate <= dateInt && time.endDate >= dateInt)
                    .map((time) => <TimeBar key={time.id} time={time} type={entry.type} groupId={entry.groupId} dateInt={dateInt} inGroup={inGroup} group={group} hoverTimeBar={hoverTimeBar} setHoverTimeBar={setHoverTimeBar} actions={actions} />);

                const actionMenuItems = [];
                if (hover && !locked && barStart && !group) {
                    if (entry.type === serviceTemplateTypes.bookable) {
                        if (grouped) {
                            actionMenuItems.push({ name: "Gruppierung bearbeiten", action: () => actions.editGroup(entry.groupId), icon: "edit" });
                            actionMenuItems.push({ name: "Gruppierung auflösen...", action: () => actions.askResolveGroup(entry.groupId), icon: "ungroup" });
                        } else {
                            actionMenuItems.push({ name: "Gruppieren...", action: () => actions.editGroup(entry.id), icon: "group" });
                            actionMenuItems.push({ name: "Dienst löschen", action: () => actions.deleteService(entry.id), icon: "delete" });
                        }
                    } else {
                        actionMenuItems.push({ name: "Dienst löschen", action: () => actions.deleteService(entry.id), icon: "delete" });
                    }
                }

                bars.push(
                    <div className={barClasses.join(" ")} style={style} key={i} onMouseEnter={disabled ? null : () => setHoverServiceId(entry.id)} onMouseLeave={() => setHoverServiceId(0)}>
                        {actionMenuItems.length > 0 && (
                            <div className={styles.actionTools}>
                                <ActionTools menuItems={actionMenuItems} dark />
                            </div>
                        )}
                        {times}
                    </div>
                );
            } else {
                bars.push(<div className={styles.empty} key={i}></div>);
            }
        }
    }

    return (
        <div className={disabled ? styles.disabled : ""}>
            <div className={styles.head}>
                <div className={dayClassNames.join(" ")}>
                    {toolTip}
                    {(date.getDate() + 100).toString().slice(1)}
                </div>
                {showAddService && !locked && <Download className={styles.addService + (actions.askInsertOptions ? " " + styles.addServiceEnabled : "")} onClick={addServiceToDay} />}
            </div>
            {bars}
        </div>
    );
};

export default Day;
