import styles from "./ProgressBar.module.css";

const ProgressBar = ({ value, title, width = "100%", height = 24 }) => {
	const style = {
		width,
		height: height + "px"
	};
	return (
		<div className={styles.progressBar} style={style}>
			<div style={{ width: `${value}%` }}></div>
			{title && <span>{title}</span>}
		</div>
	);
};

export default ProgressBar;
