import styles from "./TableHeader.module.css";

import { ReactComponent as Down } from "../../../assets/images/icon_chevron_down.svg";
import { ReactComponent as Up } from "../../../assets/images/icon_chevron_up.svg";
import { ReactComponent as ErrorIcon } from "../../../assets/images/icon_warning_red.svg";

const TableHeader = ({ orderBy, setOrderBy, fields, showErrorCol = true, groups, showGroup, setShowGroup }) => {
	const row = fields.map((field) => {
		if (field.notSortable) {
			return (
				<div key={field.key} className={field.className}>
					{field.name}
				</div>
			);
		} else {
			const orderIcon = orderBy.order === "asc" ? <Up className={styles.sortIcon} /> : <Down className={styles.sortIcon} />;
			const classNames = [field.className];
			orderBy.key !== field.key && classNames.push(styles.disabled);

			return (
				<div onClick={() => setOrderBy(field.key)} className={classNames.join(" ")} key={field.key}>
					<span>{field.name}</span>
					{orderIcon}
				</div>
			);
		}
	});
	if (showErrorCol) {
		const orderIcon = orderBy.order === "asc" ? <Up className={styles.sortIcon} /> : <Down className={styles.sortIcon} />;
		const classNames = [styles.error];
		orderBy.key !== "error" && classNames.push(styles.disabled);
		row.unshift(
			<div onClick={() => setOrderBy("error")} key="error" className={classNames.join(" ")}>
				<ErrorIcon className={styles.errorIcon} />
				{orderIcon}
			</div>
		);
	}

	return (
		<>
			{groups && (
				<div className={styles.groupHeader}>
					{fields
						.filter((field) => !field.group)
						.map((field) => (
							<div key={field.key} className={field.className}></div>
						))}
					<div className={styles.groups}>
						{groups.map((group) => (
							<div key={group.key} onClick={() => setShowGroup(group.key)} className={showGroup === group.key ? styles.selected : ""}>
								{group.name}
							</div>
						))}
					</div>
				</div>
			)}
			<div className={styles.header}>{row}</div>
		</>
	);
};

export default TableHeader;
