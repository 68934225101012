import { useSelector } from "react-redux";

const ServiceType = () => {
	const serviceTypes = useSelector((state) => state.category.serviceTypes);
	const { serviceTypeId } = useSelector((state) => state.category.selected);
	const serviceType = serviceTypes.find((entry) => entry.id === serviceTypeId);

	if (!serviceType) return null;

	return serviceType.name;
};

export default ServiceType;
