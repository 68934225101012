import { monthNames, dayNames } from "../../../../shared/globals";

import styles from "./Month.module.css";

const Month = ({ dates, getDay }) => {
	const calendar = [];
	let i = 0;

	// Dayname Header
	dayNames.forEach((day) => {
		calendar.push(<div key={day}>{day}</div>);
	});

	// Empty Days before start of month
	let firstDay = new Date(dates[0].year, dates[0].month - 1, 1).getDay();
	firstDay = firstDay ? firstDay - 1 : 6;
	// Sunday - Saturday : 0 - 6
	for (i = 0; i < firstDay; i++) {
		calendar.push(
			<div key={"eb" + i}>
				<br />
			</div>
		);
	}

	// Fill Calendar before if days start not at 1
	const countDaysBefore = dates[0].day !== 1 ? dates[0].day - 1 : 0;
	if (countDaysBefore) {
		for (i = 1; i <= countDaysBefore; i++) {
			calendar.push(
				<div className="disabled" key={"vb" + i}>
					{i}
				</div>
			);
		}
	}

	// Fill Calendar with submitted days
	dates.forEach((entry) => {
		i++;
		calendar.push(getDay(entry));
	});

	// Fill Calendar after if days ends not with last day
	const lastDate = new Date(dates[dates.length - 1].year, dates[dates.length - 1].month - 1, dates[dates.length - 1].day);
	const lastDay = lastDate.getDate();
	lastDate.setDate(1);
	lastDate.setMonth(lastDate.getMonth() + 1);
	lastDate.setDate(0);
	if (lastDay !== lastDate.getDate()) {
		for (i = lastDay + 1; i <= lastDate.getDate(); i++) {
			calendar.push(
				<div className="disabled" key={"va" + i}>
					{i}
				</div>
			);
		}
	}

	// Empty days after last day so that the calendar has always same the height
	while (calendar.length < 49) {
		i++;
		calendar.push(
			<div key={"ea" + i}>
				<br />
			</div>
		);
	}

	return (
		<div className={styles.month}>
			<span>
				{monthNames[dates[0].month - 1]} {dates[0].year}
			</span>
			{calendar}
		</div>
	);
};

export default Month;
