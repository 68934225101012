import React from "react";
import styles from "./Col.module.css";

const Col = ({ colCount, children }) => {
	const classNames = [styles.col];
	switch (colCount) {
		case 2:
			classNames.push(styles.col2);
			break;

		case 4:
			classNames.push(styles.col4);
			break;

		default:
	}

	return <div className={classNames.join(" ")}>{children}</div>;
};

export default Col;
