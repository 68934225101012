import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import styles from "./MedicalPracticeChooser.module.css";
import Window from "../../hoc/Window/Window";
import Panel from "../../hoc/Panel/Panel";
import SearchFilter from "../SearchFilter/SearchFilter";
import FooterButtons from "../../ui/FooterButtons/FooterButtons";
import { getMedicalPractices } from "../../features/medicalPractices/medicalPracticesSlice";

const MedicalPracticeChooser = ({ medicalPracticeId, setMedicalPracticeId, close, title = "Praxis wählen", exclude = [] }) => {
	const [filterText, setFilterText] = useState("");
	const [newMedicalPracticeId, setNewMedicalPracticeId] = useState(medicalPracticeId);
	const medicalPractices = useSelector((state) => state.medicalPractices.entries);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!medicalPractices) {
			dispatch(getMedicalPractices());
		}
	}, [dispatch, medicalPractices]);

	useEffect(() => {
		document.getElementById(`medicalPractice${medicalPracticeId}`)?.scrollIntoView({ block: "center" });
	}, [medicalPracticeId]);

	const contains = (row) => {
		let searchStrings = [filterText];
		if (row.selected) {
			return true;
		}
		if (filterText.indexOf(" ") > -1) {
			searchStrings = filterText.split(" ");
		}

		const found = searchStrings.map((search) => {
			search = search.toLowerCase();
			if (row.name.toLowerCase().includes(search) || row.city.toLowerCase().includes(search)) {
				return 1;
			} else {
				return 0;
			}
		});
		const foundCount = found.reduce((a, b) => a + b, 0);
		return foundCount === searchStrings.length;
	};

	if (!medicalPractices) {
		return null;
	}

	const select = (id) => {
		setNewMedicalPracticeId(id);
	};

	const ok = () => {
		setMedicalPracticeId(newMedicalPracticeId);
		close();
	};

	const setAndClose = (id) => {
		setMedicalPracticeId(id);
		close();
	};

	return (
		<Window>
			<Panel title={title} size="medium" className={styles.panel}>
				<div className={styles.content}>
					<div className={styles.filter}>
						<SearchFilter filterText={filterText} setFilterText={setFilterText} focusOnLoad />
					</div>
					<header className={styles.row + " " + styles.header}>
						<div>Praxisname</div>
						<div>Ort</div>
					</header>
					<div className={styles.list}>
						{medicalPractices
							.filter((medicalPractice) => contains(medicalPractice) && !exclude.includes(medicalPractice.id))
							.map((medicalPractice) => (
								<div
									id={`medicalPractice${medicalPractice.id}`}
									className={styles.row + (newMedicalPracticeId === medicalPractice.id ? ` ${styles.selected}` : "")}
									key={medicalPractice.id}
									onClick={() => select(medicalPractice.id)}
									onDoubleClick={() => setAndClose(medicalPractice.id)}
								>
									<div>{medicalPractice.name}</div>
									<div>{medicalPractice.city}</div>
								</div>
							))}
					</div>
					<FooterButtons padding>
						<button type="button" onClick={close}>
							Abbrechen
						</button>
						<button type="button" onClick={ok} disabled={!newMedicalPracticeId}>
							OK
						</button>
					</FooterButtons>
				</div>
			</Panel>
		</Window>
	);
};

export default MedicalPracticeChooser;
