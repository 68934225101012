import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import SearchFilter from "../../SearchFilter/SearchFilter";

import { setSearch } from "../../../features/medicalPractices/medicalPracticesSlice";

import styles from "./MedicalPracticeFilter.module.css";
import NewMedicalPractice from "../MedicalPractice/NewMedicalPractice/NewMedicalPractice";
import { useLocation } from "react-router-dom";

const MedicalPracticeFilter = () => {
	const [addMedicalPractice, setAddMedicalPractice] = useState(false);
	const filter = useSelector((state) => state.medicalPractices.filter);
	const { pathname } = useLocation();

	//const zipsOfServiceCircle = useSelector((state) => state.medicalPractices.zipsOfServiceCircle.zips);
	//const areasOfExpertise = useSelector((state) => state.values.areasOfExpertise);

	//const { categoryId, serviceTypeId, serviceCircleId, rosterPeriodId } = useSelector((state) => state.category.selected);

	const dispatch = useDispatch();

	// useEffect(() => {
	// 	if (serviceCircleId) {
	// 		dispatch(getZipsOfServiceCircle(serviceCircleId));
	// 	}
	// }, [serviceCircleId, dispatch]);

	// useEffect(() => {
	// 	if (rosterPeriodId) {
	// 		dispatch(getDoctorsOfRosterPeriod(rosterPeriodId));
	// 	}
	// }, [rosterPeriodId, dispatch]);

	useEffect(() => {
		setAddMedicalPractice(false);
	}, [pathname]);

	const setFilterText = (value) => {
		dispatch(setSearch(value));
	};

	return (
		<div className={styles.content}>
			<div className={styles.contentTop}>
				<button type="button" onClick={() => setAddMedicalPractice(true)}>
					Neue Praxis
				</button>
				<SearchFilter filterText={filter.search} setFilterText={setFilterText} placeholder="Suche Praxisname, Strasse, PLZ, Ort, Notiz" />
			</div>

			{/* <FilterPanel name="Aktueller Selektor">
				<div>
					<label className={styles.radio}>
						<input type="radio" checked={filter.bySelector === null} onChange={() => dispatch(filterBySelector(null))} />
						<span>Nicht beachten</span>
					</label>
				</div>
				<label className={styles.radio}>
					<input type="radio" checked={filter.bySelector === "category"} disabled={!(categoryId > 0)} onChange={() => dispatch(filterBySelector("category"))} />
					<span>Fachbereich</span>
				</label>
				{serviceTypeId > 0 && (
					<Fragment>
						<div className={styles.indent}>
							<label className={styles.radio}>
								<input type="checkbox" checked={filter.byServiceType} disabled={filter.bySelector !== "category" || filter.byServiceCircle} onChange={() => dispatch(toggleFilter("byServiceType"))} />
								<span>Dienstart</span>
							</label>
							{serviceCircleId > 0 && (
								<div className={styles.indent}>
									<label className={styles.radio}>
										<input type="checkbox" checked={filter.byServiceCircle} disabled={filter.bySelector !== "category" || zipsOfServiceCircle === null || zipsOfServiceCircle.length === 0} onChange={() => dispatch(toggleFilter("byServiceCircle"))} />
										<span>Dienstkreis</span>
									</label>
									{(zipsOfServiceCircle === null || zipsOfServiceCircle.length === 0) && <Note>Bei der gewählten Dienstart wurde keine Basiskarte hinterlegt oder diese enthält keine Orte für den gewählten Dienstkreis</Note>}
								</div>
							)}
						</div>
					</Fragment>
				)}
				<div>
					<label className={styles.radio}>
						<input type="radio" checked={filter.bySelector === "period"} disabled={!(rosterPeriodId > 0)} onChange={() => dispatch(filterBySelector("period"))} />
						<span>Dienstperiode</span>
					</label>
				</div>
			</FilterPanel>
			<FilterPanel name="Andere Filter">
				<label>
					Spezialgebiete
					<select size={1} value={filter.areaOfExpertise} onChange={changeAreaOfExpertise}>
						<option value="0">Alle Spezialgebiete</option>
						{areasOfExpertise.map((entry) => (
							<option key={entry.id} value={entry.id}>
								{entry.name}
							</option>
						))}
					</select>
				</label>
				<br />
				<br />
				<label className={styles.radio}>
					<input type="checkbox" checked={filter.errors} onChange={() => dispatch(toggleFilter("errors"))} />
					<span>Personen mit fehlerhaften Daten</span>
				</label>
			</FilterPanel> */}
			{addMedicalPractice && <NewMedicalPractice cancel={() => setAddMedicalPractice(false)} />}
		</div>
	);
};

export default MedicalPracticeFilter;
