import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import NoteIcon from "../../../ui/NoteIcon/NoteIcon";
import SortableTable from "../../SortableTable/SortableTable";

import { changeOrderBy, setOrderBy } from "../../../features/medicalPractices/medicalPracticesSlice";
import * as routes from "../../../shared/routes";
import styles from "./MedicalPracticeList.module.css";

const fields = [
	{
		key: "name",
		name: "Paxisname",
		search: true,
		className: styles.name
	},
	{
		key: "street",
		name: "Strasse",
		search: true,
		className: styles.street
	},
	{
		key: "zip",
		name: "PLZ",
		search: true,
		className: styles.zip
	},
	{
		key: "city",
		name: "Ort",
		search: true,
		className: styles.city
	},
	{
		key: "notes",
		name: <NoteIcon />,
		search: true,
		showField: (row) => (row.notes.length > 0 ? <NoteIcon marked={row.markedNotes} count={row.notes.length} /> : ""),
		className: styles.notes,
		sort: (a, b, order) => {
			const aCount = a.notes.length + (a.markedNotes ? a.markedNotes * 1000 : 0);
			const bCount = b.notes.length + (b.markedNotes ? b.markedNotes * 1000 : 0);
			return order === "asc" ? aCount - bCount : bCount - aCount;
		}
	}
];

const MedicalPracticeList = ({ medicalPractices }) => {
	const filter = useSelector((state) => state.medicalPractices.filter);
	const orderBy = useSelector((state) => state.medicalPractices.orderBy);
	const searchFields = fields.filter((entry) => entry.search).map((entry) => entry.key);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (orderBy === null) {
			dispatch(
				setOrderBy({
					key: fields[0].key,
					order: "asc"
				})
			);
		}
	}, [orderBy, dispatch]);

	if (orderBy === null) {
		return null;
	}

	const contains = (row, fields) => {
		if (filter.search.trim().length < 3) {
			return true;
		}
		const searchStrings = filter.search.split(" ");

		const found = searchStrings.map((search) => {
			search = search.toLowerCase();
			const found = fields.reduce((isFound, field) => {
				if (isFound) return isFound;
				if (field === "notes") {
					return row[field].reduce((noteFound, value) => noteFound || value.toLowerCase().indexOf(search) > 1, false);
				} else {
					return row[field] && row[field].toLowerCase().indexOf(search) > -1;
				}
			}, false);
			if (found) {
				return 1;
			} else {
				return 0;
			}
		});
		const foundCount = found.reduce((a, b) => a + b, 0);
		return foundCount === searchStrings.length;
	};

	const changeOrder = (key) => {
		dispatch(changeOrderBy(key));
	};

	const rowClickAction = (row) => {
		navigate(routes.MEDICAL_PRACTICES + "/" + row.id + routes.PRAXIS_DATA);
	};

	const filterMedicalPractices = (row) => {
		let include = true;

		include = include && (!filter.errors || row.error);
		// if (filter.bySelector === "category") {
		// 	if (!filter.byServiceType) {
		// 		const includedServiceTypeIds = serviceTypes.filter((entry) => entry.categoryId === categoryId).map((entry) => entry.id);
		// 		include =
		// 			include &&
		// 			row.serviceTypeIds.reduce((found, doctorServiceTypeId) => {
		// 				return found || includedServiceTypeIds.includes(doctorServiceTypeId);
		// 			}, false);
		// 	} else {
		// 		include = include && row.serviceTypeIds.includes(serviceTypeId);
		// 		include = include && (!filter.byServiceCircle || (zipsOfServiceCircle && zipsOfServiceCircle.includes(parseInt(row.zip))));
		// 	}
		// } else if (filter.bySelector === "period") {
		// 	include = include && rosterPeriodDoctorIds.includes(row.id);
		// }

		// include = include && (!filter.areaOfExpertise || row.areasOfExpertise.includes(filter.areaOfExpertise));
		return include;
	};

	const filteredMedicalPractices = medicalPractices.filter((entry) => contains(entry, searchFields) && filterMedicalPractices(entry));

	const resultInfo =
		medicalPractices.length > filteredMedicalPractices.length ? `${filteredMedicalPractices.length} von ${medicalPractices.length} ${medicalPractices.length === 1 ? "Praxis" : "Praxen"} gefunden` : `${medicalPractices.length === 1 ? "Eine Praxis" : medicalPractices.length + " Praxen"}`;

	return <SortableTable data={filteredMedicalPractices} fields={fields} resultInfo={resultInfo} orderBy={orderBy} changeOrder={changeOrder} rowClickAction={rowClickAction} tableStyle={styles.table} />;
};

export default MedicalPracticeList;
