import { useSelector } from "react-redux";
import Panel from "../../hoc/Panel/Panel";
import Window from "../../hoc/Window/Window";
import Count from "../../ui/Count/Count";

import styles from "./ColorChooser.module.css";

const ColorChooser = ({ cancel, selected = 0, usedColors = [], setColor, colorMustBeUnique = true }) => {
	const colors = useSelector((state) => state.values.color);

	return (
		<Window>
			<Panel title="Farbe wählen" size="small" padding>
				<div className={styles.content}>
					{colors.map((entry) => {
						const classNames = [styles.color];
						let action = () => setColor(entry.id);
						let countUsed = usedColors.filter((colorId) => colorId === entry.id).length;
						if (selected === entry.id) {
							classNames.push(styles.selected);
						} else if (colorMustBeUnique && countUsed > 0) {
							classNames.push(styles.disabled);
							action = null;
						}
						return (
							<div key={entry.id} style={{ backgroundColor: `#${entry.color}` }} className={classNames.join(" ")} onClick={action}>
								<Count>{countUsed}</Count>
							</div>
						);
					})}
				</div>
				<div className={"buttons " + styles.buttons}>
					<button type="button" onClick={() => cancel()}>
						Abbrechen
					</button>
				</div>
			</Panel>
		</Window>
	);
};

export default ColorChooser;
