import { useEffect } from "react";
import { useDispatch } from "react-redux";

import Panel from "../../hoc/Panel/Panel";

import { clearPersons } from "../../features/persons/personsSlice";

import styles from "./DoctorsMedicalPractices.module.css";
import * as routes from "../../shared/routes";

const pages = [
	{
		location: routes.PERSONS,
		name: "Personen"
	},
	{
		location: routes.MEDICAL_PRACTICES,
		name: "Praxen"
	}
];

const DoctorsMedicalPractices = ({ children }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		return () => dispatch(clearPersons());
	}, [dispatch]);

	return (
		<Panel size="xLarge" className={styles.panel} tabs={pages} title="Personen & Praxen" categorieLevels={1} maxRequiredLevel={4}>
			<div className={styles.content}>{children}</div>
		</Panel>
	);
};

export default DoctorsMedicalPractices;
