import { Fragment, useEffect, useState } from "react";
import { monthNames, dayNames } from "../../shared/globals";
import { ReactComponent as ArrowLeft } from "../../assets/images/icon_chevron_left.svg";
import { ReactComponent as ArrowRight } from "../../assets/images/icon_chevron_right.svg";
import styles from "./DateRangeSelector.module.css";
import { formatDate, formatDateTime } from "../../shared/functions";
import Note from "../Note/Note";
import FieldLabel from "../../ui/FieldLabel/FieldLabel";

const instructions = {
    start: "Bitte wähle den Start des Zeitraums",
    end: "Bitte wähle das Ende des Zeitraums"
};

const DateRangeSelector = ({ startDate, endDate, inFuture = false, minEndDate, startDateProtected = false, includeHour = false, setPeriode }) => {
    const [displayMonth, setDisplayMonth] = useState({
        month: startDate ? startDate.getMonth() : new Date().getMonth(),
        year: startDate ? startDate.getFullYear() : new Date().getFullYear()
    });
    const [startHour, setStartHour] = useState(includeHour ? (startDate ? startDate.getHours() : 8) : 0);
    const [endHour, setEndHour] = useState(includeHour ? (endDate ? endDate.getHours() : 17) : 0);
    const [dateRange, setDateRange] = useState({
        start: startDate ? startDate : null,
        end: endDate ? endDate : null
    });
    const [instruction, setInstruction] = useState(startDate ? (endDate ? "" : instructions.end) : instructions.start);

    const lastDay = new Date(displayMonth.year, displayMonth.month + 1);

    lastDay.setDate(0);
    const countDaysInMonth = lastDay.getDate();
    const today = new Date();
    //today.setHours(0, 0, 0, 0);

    const firstDay = new Date(displayMonth.year, displayMonth.month);
    let firstDayNr = firstDay.getDay();
    firstDayNr = firstDayNr ? firstDayNr - 1 : 6;
    // Sunday - Saturday : 0 - 6

    useEffect(() => {
        setPeriode({ ...dateRange });
    }, [setPeriode, dateRange]);

    const nextMonth = () => {
        const date = new Date(displayMonth.year, displayMonth.month + 1);
        setDisplayMonth({
            month: date.getMonth(),
            year: date.getFullYear()
        });
    };

    const prevMonth = () => {
        const date = new Date(displayMonth.year, displayMonth.month - 1);
        setDisplayMonth({
            month: date.getMonth(),
            year: date.getFullYear()
        });
    };

    const chooseDate = (day) => {
        if (startDateProtected) {
            setDateRange((prev) => ({ ...prev, end: new Date(displayMonth.year, displayMonth.month, day, endHour) }));
            setInstruction("");
        } else if (dateRange.end || !dateRange.start) {
            setDateRange({ start: new Date(displayMonth.year, displayMonth.month, day, startHour), end: null });
            setInstruction(instructions.end);
        } else {
            setDateRange((prev) => ({ ...prev, end: new Date(displayMonth.year, displayMonth.month, day, endHour) }));
            setInstruction("");
        }
    };

    const changeHour = (e) => {
        const hour = e.target.name;
        const newHour = e.target.value;
        if (newHour.match(/^\d{0,2}$/) && (parseInt(newHour) < 24 || newHour === "")) {
            hour === "startHour" ? setStartHour(parseInt(newHour) || 0) : setEndHour(parseInt(newHour) || 0);
            setDateRange((prev) => {
                const start = prev.start ? new Date(prev.start.getTime()) : null;
                const end = prev.end ? new Date(prev.end.getTime()) : null;
                hour === "startHour" && start && start.setHours(parseInt(newHour) || 0);
                hour !== "startHour" && end && end.setHours(parseInt(newHour) || 0);
                return {
                    start,
                    end
                };
            });
        }
    };

    const calendar = [];

    dayNames.forEach((day) => {
        calendar.push(<div key={day}>{day}</div>);
    });

    let i = 0;
    for (i = 0; i < firstDayNr; i++) {
        calendar.push(
            <div key={i}>
                <br />
            </div>
        );
    }
    for (let day = 1; day <= countDaysInMonth; day++) {
        const theDate = new Date(displayMonth.year, displayMonth.month, day);
        const classNames = [];

        if ((minEndDate && theDate.getTime() < minEndDate.getTime()) || (inFuture && !startDateProtected && theDate.getTime() <= today.getTime())) {
            classNames.push(styles.disabled);
        }
        if (!classNames.includes(styles.disabled) && !(startDateProtected && theDate.getTime() <= dateRange.start.getTime()) && (!(dateRange.start && theDate.getTime() <= dateRange.start.getTime()) || dateRange.end)) {
            classNames.push(styles.click);
        }
        if (dateRange.start && theDate.toDateString() === dateRange.start.toDateString()) {
            classNames.push(styles.start);
        }
        if (dateRange.start && dateRange.end && theDate.getTime() > dateRange.start.getTime() && theDate.getTime() < dateRange.end.getTime()) {
            classNames.push(styles.selected);
        }
        if (dateRange.end && theDate.getTime() === dateRange.end.getTime()) {
            classNames.push(styles.end);
        }
        calendar.push(
            <div key={i} className={classNames.join(" ")} onClick={classNames.includes(styles.click) ? () => chooseDate(day) : () => {}}>
                {day}
            </div>
        );
        i++;
    }

    while (calendar.length < 49) {
        i++;
        calendar.push(
            <div key={i}>
                <br />
            </div>
        );
    }

    return (
        <div>
            <Note marginBottom>
                {instruction || (
                    <Fragment>
                        <strong>Gewählter Zeitraum:</strong>
                        <br />
                        <span>
                            {includeHour ? formatDateTime(dateRange.start) : formatDate(dateRange.start)} – {includeHour ? formatDateTime(dateRange.end) : formatDate(dateRange.end)}
                        </span>
                    </Fragment>
                )}
            </Note>
            {includeHour && (
                <div className={styles.hours}>
                    {dateRange.start && (
                        <FieldLabel label="Startzeit">
                            <input type="text" className={styles.hour} name="startHour" value={startHour} onChange={changeHour} maxLength={2} /> Uhr
                        </FieldLabel>
                    )}
                    {dateRange.end && (
                        <FieldLabel label="Endzeit">
                            <input type="text" className={styles.hour} name="endHour" value={endHour} onChange={changeHour} maxLength={2} /> Uhr
                        </FieldLabel>
                    )}
                </div>
            )}
            <div className={styles.calendar}>
                <nav>
                    <ArrowLeft onClick={prevMonth} />
                    <span>
                        {monthNames[displayMonth.month]} {displayMonth.year}
                    </span>
                    <ArrowRight onClick={nextMonth} />
                </nav>
                <div>{calendar}</div>
            </div>
        </div>
    );
};

export default DateRangeSelector;
