import Label from "../Label/Label";
import styles from "./ResourceRow.module.css";

const ResourceRow = ({ label, labelColor, icon, secret = false, auth = false, children }) => {
	return (
		<div className={styles.resourceRow}>
			<Label label={label} labelColor={labelColor} icon={icon} secret={secret} auth={auth} />
			{children}
		</div>
	);
};

export default ResourceRow;
