import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBaseMapOfDay, setBaseMapRepetitive, setGeographyPlanOfDay, setGeographyPlanRepetitive } from "../../../features/geographyPlan/geographyPlanSlice";
import { getMySqlDateFromDayMonthYear } from "../../../shared/functions";
import Day from "./Day/Day";
import InsertOptions from "./InsertOptions/InsertOptions";
import Month from "./Month/Month";

import styles from "./YearCalendar.module.css";

const YearCalendar = ({ baseMaps, plans, serviceTypeId, selectedPlan, selectedBaseMap, year }) => {
	const dispatch = useDispatch();
	const geographyRoster = useSelector((state) => state.geographyPlan.roster);
	const [insertOptions, setInsertOptions] = useState(null);

	const askInsertOptions = (type, day, month, year) => {
		const name = selectedPlan ? selectedPlan.name : baseMaps.find((entry) => entry.id === selectedBaseMap.id).name;

		setInsertOptions({
			type,
			day,
			month,
			year,
			title: `${type === "plan" ? "Zeitabhängige Karte" : "Basiskarte"} «${name}» einfügen`
		});
	};

	const insert = (options) => {
		const date = getMySqlDateFromDayMonthYear(insertOptions.day, insertOptions.month, insertOptions.year);
		if (options.mode === "single") {
			if (insertOptions.type === "plan") {
				dispatch(setGeographyPlanOfDay({ serviceTypeId, data: { date, geographyPlanId: selectedPlan.id } }));
			} else if (insertOptions.type === "map") {
				dispatch(setBaseMapOfDay({ serviceTypeId, data: { date, baseMapId: selectedBaseMap.id } }));
			}
		} else {
			if (insertOptions.type === "plan") {
				dispatch(setGeographyPlanRepetitive({ serviceTypeId, data: { date, geographyPlanId: selectedPlan.id, ...options } }));
			} else if (insertOptions.type === "map") {
				dispatch(setBaseMapRepetitive({ serviceTypeId, data: { date, baseMapId: selectedBaseMap.id, ...options } }));
			}
		}
		setInsertOptions(null);
	};

	const getDay = (day) => {
		const map = day.baseMapId ? baseMaps.find((row) => row.id === day.baseMapId) : null;
		const plan = day.geographyPlanId ? plans.find((row) => row.id === day.geographyPlanId) : null;
		return <Day key={day.day + "-" + day.month} day={day} map={map} plan={plan} selectedPlan={selectedPlan} selectedBaseMap={selectedBaseMap} askInsertOptions={askInsertOptions} />;
	};

	const calendars = [...Array(12)].map((_, month) => {
		const date = new Date(year, month, 1);
		const dates = [];

		while (date.getMonth() === month) {
			const dayData = geographyRoster ? geographyRoster.find((entry) => entry.day === date.getDate() && entry.month === month + 1 && entry.year === year) : undefined;
			dates.push({
				day: date.getDate(),
				month: month + 1,
				year,
				baseMapId: dayData ? dayData.baseMapId : 0,
				geographyPlanId: dayData ? dayData.geographyPlanId : 0,
				id: dayData ? dayData.id : 0
			});
			date.setDate(date.getDate() + 1);
		}
		return <Month key={month} dates={dates} getDay={getDay} />;
	});

	return (
		<Fragment>
			<div className={styles.content}>{calendars}</div>
			{insertOptions && <InsertOptions title={insertOptions.title} type={insertOptions.type} insert={insert} cancel={() => setInsertOptions(null)} />}
		</Fragment>
	);
};

export default YearCalendar;
