export const DELETE_DAY_PLAN = "DELETE_DAY_PLAN";
export const DELETE_TIME_RANGE = "DELETE_TIME_RANGE";
export const DELETE_TIME_PLAN = "DELETE_TIME_PLAN";
export const DELETE_SERVICE_TEMPLATE = "DELETE_SERVICE_TEMPLATE";
export const DELETE_SERVICE_TEMPLATE_FIX = "DELETE_SERVICE_TEMPLATE_FIX";
export const DELETE_WORKTIME_RANGE = "DELETE_WORKTIME_RANGE";
export const DELETE_AREA_OF_EXPERTISE = "DELETE_AREA_OF_EXPERTISE";
export const DELETE_PHONE = "DELETE_PHONE";
export const DELETE_EMAIL = "DELETE_EMAIL";
export const DELETE_PUBLIC_HOLIDAY_REGION = "DELETE_PUBLIC_HOLIDAY_REGION";
export const REMOVE_DOCTOR_FROM_SERVICE_TYPE = "REMOVE_DOCTOR_FROM_SERVICE_TYPE";
export const DELETE_BASE_MAP = "DELETE_BASE_MAP";
export const PASTE_ZIPS = "PASTE_ZIPS";
export const RESOLVE_GROUP = "RESOLVE_GROUP";
export const REMOVE_PERSON_FROM_MEDICAL_PRACTICE = "REMOVE_PERSON_FROM_MEDICAL_PRACTICE";
export const DELETE_MEDICAL_PRACTICE = "DELETE_MEDICAL_PRACTICE";
export const DELETE_PERSON = "DELETE_PERSON";
export const CREATE_ROSTER = "CREATE_ROSTER";
export const REMOVE_DOCTOR_FROM_ROSTER = "REMOVE_DOCTOR_FROM_ROSTER";
