import { useState } from "react";
import { ReactComponent as Gear } from "../../assets/images/gear.svg";
import { ReactComponent as Group } from "../../assets/images/icon_group.svg";
import { ReactComponent as UnGroup } from "../../assets/images/icon_ungroup.svg";
import { ReactComponent as Trash } from "../../assets/images/icon_trash.svg";
import { ReactComponent as Edit } from "../../assets/images/icon_edit.svg";

import styles from "./ActionTools.module.css";

const icons = {
	group: <Group />,
	ungroup: <UnGroup />,
	delete: <Trash />,
	edit: <Edit />
};

// menuItems = {
//	name: "",
//	action: () => {},
//	disabled: false
//}

const ActionTools = ({ menuItems, dark = false, up = false, right = false }) => {
	const [show, setShow] = useState(false);

	const classNames = [styles.actionTools];
	dark && classNames.push(styles.dark);
	up && classNames.push(styles.up);
	right && classNames.push(styles.right);

	const action = (menuAction) => {
		setShow(false);
		menuAction();
	};

	return (
		<div className={classNames.join(" ")} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
			{!up && <Gear className={styles.gear} alt="Funktionen" />}
			{show && (
				<ul>
					{menuItems.map((menu) => {
						if (menu.disabled) {
							return (
								<li key={menu.name} className={styles.disabled}>
									{menu.icon && icons[menu.icon]}
									{menu.name}
								</li>
							);
						} else {
							return (
								<li key={menu.name} onClick={() => action(menu.action)}>
									{menu.icon && icons[menu.icon]}
									{menu.name}
								</li>
							);
						}
					})}
				</ul>
			)}
			{up && <Gear className={styles.gear} alt="Funktionen" />}
		</div>
	);
};

export default ActionTools;
