import ResourceListItem from "./ResourceListItem/ResourceListItem";
import styles from "./ResourceList.module.css";

const ResourceList = ({ entries, editItem = null, deleteItem = null, jumpToItem = null, notDeletable = [], alternating = true }) => {
	const classNames = [styles.list];
	alternating && classNames.push(styles.alternating);

	return (
		<ul className={classNames.join(" ")}>
			{entries.map((entry) => {
				const deleteAction = notDeletable.includes(entry.id) ? null : deleteItem;
				return <ResourceListItem key={entry.id} id={entry.id} name={entry.name} editItem={editItem} jumpToItem={jumpToItem} deleteItem={deleteAction} />;
			})}
			{entries.length === 0 && (
				<li key="none" className={styles.none}>
					Keine
				</li>
			)}
		</ul>
	);
};

export default ResourceList;
