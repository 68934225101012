import { labelColors } from "../../shared/globals";
import styles from "./Label.module.css";

import { ReactComponent as IconPhone } from "../../assets/images/icon_phone.svg";
import { ReactComponent as IconMobile } from "../../assets/images/icon_mobile.svg";
import { ReactComponent as IconFax } from "../../assets/images/icon_fax.svg";
import { ReactComponent as IconMail } from "../../assets/images/icon_mail.svg";
import { ReactComponent as IconSecret } from "../../assets/images/icon_secret.svg";
import { ReactComponent as Key } from "../../assets/images/icon_key.svg";

const icons = {
	phone: <IconPhone />,
	mobile: <IconMobile />,
	fax: <IconFax />,
	eMail: <IconMail />
};

const Label = ({ label = "", labelColor, icon, secret = false, auth = false }) => {
	return (
		<div className={styles.label} style={labelColors[labelColor]}>
			<div>{icons[icon]}</div>
			<span>{label}</span>
			{secret && <IconSecret />}
			{auth && <Key />}
		</div>
	);
};

export default Label;
