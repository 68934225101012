import { useState } from "react";
import Panel from "../../../../hoc/Panel/Panel";
import Window from "../../../../hoc/Window/Window";
import CheckBox from "../../../../ui/CheckBox/CheckBox";
import RadioButton from "../../../../ui/RadioSelect/RadioButton/RadioButton";
import styles from "./InsertOptions.module.css";
import FooterButtons from "../../../../ui/FooterButtons/FooterButtons";

const InsertOptions = ({ type, title, cancel, insert }) => {
	const [insertOptions, setInsertOptions] = useState({
		mode: "single",
		overWrite: false
	});

	const setMode = (mode) => {
		setInsertOptions((prev) => ({ ...prev, mode, overWrite: false }));
	};

	const setOverWrite = (overWrite) => {
		setInsertOptions((prev) => ({ ...prev, overWrite }));
	};

	const ok = () => {
		insert(insertOptions);
	};

	const askOverWrite = (
		<div className={styles.indent}>
			<CheckBox value="overwrite" name={`Bestehende ${type === "plan" ? "zeitabhängige Karten" : "Basiskarten"} ersetzen`} checked={insertOptions.overWrite} set={setOverWrite} />
		</div>
	);

	return (
		<Window>
			<Panel title={title} size="small" padding>
				<div className={styles.label}>
					<RadioButton value="single" name="An diesem Tag" selected={insertOptions.mode} set={setMode} />
					<RadioButton value="daily" name="Täglich bis Ende Jahr" selected={insertOptions.mode} set={setMode} />
					{insertOptions.mode === "daily" && askOverWrite}
					<RadioButton value="weekly" name="Wöchentlich bis Ende Jahr" selected={insertOptions.mode} set={setMode} />
					{insertOptions.mode === "weekly" && askOverWrite}
				</div>
				<FooterButtons>
					<button type="button" onClick={cancel}>
						Abbrechen
					</button>
					<button type="button" onClick={ok}>
						OK
					</button>
				</FooterButtons>
			</Panel>
		</Window>
	);
};

export default InsertOptions;
