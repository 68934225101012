import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";

import Col from "../Col/Col";
import DisplayDate from "../../Display/DisplayDate/DisplayDate";
import EditRosterPeriod from "./EditRosterPeriod/EditRosterPeriod";
import ColHeader from "../Col/ColHeader/ColHeader";
import ColContent from "../Col/ColContent/ColContent";
import CategoryColItem from "../CategoryCol/CategoryColItem/CategoryColItem";

const RosterPeriodeCol = ({ serviceCircleId, rosterPeriodes, selectedId, select }) => {
	const [editPeriodeId, setEditPeriodeId] = useState(null);
	const dispatch = useDispatch();

	const selectMenu = (id) => {
		dispatch(select(id));
	};

	const edit = () => {
		setEditPeriodeId(selectedId);
	};

	return (
		<Fragment>
			<Col colCount={4}>
				<ColHeader title="Dienstpläne" add={() => setEditPeriodeId(0)} />
				<ColContent>
					{rosterPeriodes.map((rosterPeriod, index) => {
						const periodEditable = !index && rosterPeriod.state === "planning";
						const selected = selectedId === rosterPeriod.id && periodEditable;
						return (
							<CategoryColItem key={rosterPeriod.id} action={periodEditable ? () => selectMenu(rosterPeriod.id) : null} selected={selected} edit={selected ? edit : null}>
								<span>
									<DisplayDate date={rosterPeriod.start} short /> - <DisplayDate date={rosterPeriod.end} short />
								</span>
							</CategoryColItem>
						);
					})}
				</ColContent>
			</Col>
			{editPeriodeId !== null && <EditRosterPeriod id={editPeriodeId} rosterPeriodes={rosterPeriodes} cancel={() => setEditPeriodeId(null)} serviceCircleId={serviceCircleId} />}
		</Fragment>
	);
};

export default RosterPeriodeCol;
