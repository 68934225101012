import { useLoadScript } from "@react-google-maps/api";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Map from "./Map/Map";
import ServiceCircleEditor from "../ServiceCircleEditor/ServiceCircleEditor";
import ServiceCircleSelector from "../../ServiceCircleSelector/ServiceCircleSelector";

import { getRegions } from "../../../features/region/regionSlice";
import Panel from "../../../hoc/Panel/Panel";
import Spinner from "../../../ui/Spinner";
import Window from "../../../hoc/Window/Window";

import styles from "./EditMap.module.css";
import PlanName from "./PlanName/PlanName";
import { copy, paste, createBaseMap, updateBaseMap, updateBaseMapName, addZipsToServiceCircle, removeZipsFromServiceCircle } from "../../../features/geographyPlan/geographyPlanSlice";
import ActionTools from "../../ActionTools/ActionTools";

import * as askTypes from "../../../shared/askTypes";
import { ask, clear } from "../../../features/ask/askSlice";
import Note from "../../Note/Note";

const EditMap = ({ cancel }) => {
    const regions = useSelector((state) => state.regions);
    const map = useSelector((state) => state.geographyPlan.map);
    const clipboard = useSelector((state) => state.geographyPlan.clipboard);
    const serviceCircles = useSelector((state) => state.category.serviceCircles);
    const answer = useSelector((state) => state.ask.answer);

    const { serviceTypeId, serviceCircleId } = useSelector((state) => state.category.selected);
    const currentServiceCircles = useMemo(() => (serviceTypeId ? serviceCircles.filter((entry) => entry.serviceTypeId === serviceTypeId) : []), [serviceTypeId, serviceCircles]);

    const [mouseOverServiceCircle, setMouseOverServiceCircle] = useState("");
    const [name, setName] = useState(map.name);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    });
    const dispatch = useDispatch();

    useEffect(() => {
        if (!regions) dispatch(getRegions());
    }, [dispatch, regions]);

    useEffect(() => {
        if (answer) {
            if (answer.type === askTypes.PASTE_ZIPS && answer.value) {
                dispatch(paste());
                dispatch(clear());
            }
        }
    }, [answer, dispatch]);

    if (!regions) {
        return null;
    }

    const over = (id, isOver = true) => {
        setMouseOverServiceCircle(isOver ? id : "");
    };

    const save = () => {
        if (map.id) {
            if (map.locked) {
                dispatch(updateBaseMapName({ id: map.id, data: { name }, success: `Die Basiskarte «${name}» wurde erfolgreich gespeichert.` }));
            } else {
                dispatch(updateBaseMap({ id: map.id, data: { ...map, name }, success: `Die Basiskarte «${name}» wurde erfolgreich gespeichert.` }));
            }
        } else {
            dispatch(createBaseMap({ serviceTypeId, data: { ...map, name }, success: `Die Basiskarte «${name}» wurde erfolgreich erstellt.` }));
        }
    };

    const copyZips = () => {
        dispatch(copy({ name: map.name, zips: [...map.zips] }));
    };

    const pasteZips = () => {
        if (map.zips.length > 0) {
            dispatch(
                ask({
                    type: askTypes.PASTE_ZIPS,
                    title: "Karte einfügen",
                    question: `Möchtest Du die Karte «${clipboard.name}» wirklich einfügen? Damit werden alle vorhandenen Regionen ersetzt.`,
                    buttons: [
                        { label: "Abbrechen", answer: null },
                        { label: "Ja, einfügen", answer: true }
                    ]
                })
            );
        } else {
            dispatch(paste());
        }
    };

    const addZips = (regions) => {
        dispatch(addZipsToServiceCircle({ serviceCircleId, regions }));
    };

    const removeZips = (regions) => {
        dispatch(removeZipsFromServiceCircle({ serviceCircleId, regions }));
    };

    const actionToolsMenuItems = [];

    if (map.zips.length > 0) {
        actionToolsMenuItems.push({ name: "Karte kopieren", action: () => copyZips() });
    }
    if (clipboard) {
        actionToolsMenuItems.push({ name: `Karte «${clipboard.name}» einfügen`, action: () => pasteZips(), disabled: map.locked });
    }

    return (
        <Window>
            <Panel size="full" title="Basiskarte bearbeiten" empty padding>
                <div className={styles.map}>
                    <div className={styles.mapPanel}>{isLoaded ? <Map serviceCircles={currentServiceCircles} mouseOverServiceCircle={mouseOverServiceCircle} locked={map.locked} /> : <Spinner />}</div>
                    <div className={styles.sidePanel}>
                        <div>
                            <PlanName name={name} setName={setName} />
                            {map.locked && <Note marginTop>Diese Basiskarte kann nicht mehr verändert werden, da sie in der Vergangenheit verwendet wurde.</Note>}
                        </div>
                        <div className={styles.mainSidePanel}>
                            <div className={styles.serviceCircle}>
                                {actionToolsMenuItems.length > 0 && <ActionTools menuItems={actionToolsMenuItems} dark />}
                                <ServiceCircleSelector serviceCircles={currentServiceCircles} serviceCircleId={serviceCircleId} over={over} />
                                {serviceCircleId > 0 && <ServiceCircleEditor serviceCircleId={serviceCircleId} zips={map.zips} locked={map.locked} addZipsToServiceCircle={addZips} removeZipsFromServiceCircle={removeZips} />}
                            </div>
                        </div>
                        <div className={styles.buttons}>
                            <button type="button" onClick={cancel}>
                                Abbrechen
                            </button>
                            <button type="button" onClick={save} disabled={name.trim().length < 2}>
                                Speichern
                            </button>
                        </div>
                    </div>
                </div>
            </Panel>
        </Window>
    );
};

export default EditMap;
