const TimeBarHandle = (props) => {
	const classNames = [props.mainCssClass];
	classNames.push(props.axis === "n" ? props.topClass : props.bottomClass);
	const forwardProps = { ...props };
	delete forwardProps.mainCssClass;
	delete forwardProps.topClass;
	delete forwardProps.bottomClass;
	return (
		<div ref={props.innerref} className={classNames.join(" ")} {...forwardProps}>
			<div></div>
		</div>
	);
};

export default TimeBarHandle;
