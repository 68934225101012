import styles from "./Points.module.css";

const Points = ({ title, children }) => {
    return (
        <div className={styles.points} title={title || `${children} Punkte`}>
            {children}
        </div>
    );
};

export default Points;
