import { useSelector } from "react-redux";

import styles from "./Phone.module.css";

const Phone = ({ phone }) => {
	const phoneCountries = useSelector((state) => state.values.phoneCountry);

	const plainNumber = phone.number.replace(/ /g, "");
	const error = !plainNumber.match(/^\d+$/) || plainNumber.slice(0, 2) === "00";
	const phoneCountry = phone.phoneCountryId === 41 ? "" : "(" + phoneCountries.find((entry) => entry.id === phone.phoneCountryId).country + ")";
	const number = phone.phoneCountryId === 41 ? `${plainNumber.slice(0, 3)} ${plainNumber.slice(3, 6)} ${plainNumber.slice(6, 8)} ${plainNumber.slice(8, 10)}` : plainNumber;

	return (
		<div className={styles.phone}>
			{error ? <div className={styles.error}>{phone.number}</div> : <div>{number}</div>}
			<div>{phoneCountry}</div>
		</div>
	);
};

export default Phone;
