import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import styles from "./AllocationTable.module.css";

import ShowPerson from "../../../../components/Display/ShowPerson/ShowPerson";
import Ok from "../../../../components/Display/Ok/Ok";
import NewSortableTable from "../../../../components/NewSortableTable/NewSortableTable";
import BookingPreview from "../../../../components/Roster/Allocation/BookingPreview/BookingPreview";
import ProgressBar from "../../../../components/Display/ProgressBar/ProgressBar";
import Panel from "../../../../hoc/Panel/Panel";

import { getRosterTabs } from "../../../../shared/globals";
import Points from "../../../../components/Display/Points/Points";
import BookNow from "../BookNow/BookNow";

import ViewChooser from "../ViewChooser/ViewChooser";

const groups = [
    {
        key: "settings",
        name: "Einstellungen"
    },
    {
        key: "absences",
        name: "Absenzen"
    },
    {
        key: "preview",
        name: "Buchungsvorschau"
    }
];

const fields = [
    {
        key: "person",
        name: "Arzt",
        showField: (row) => <ShowPerson id={row.personId} />
    },
    {
        key: "amount",
        name: "Pensum",
        type: "number",
        showField: (row) => row.amount + "%",
        align: "right"
    },
    {
        key: "pointsToReach",
        name: "Punkte",
        type: "number",
        showField: (row) => <Points title={row.balance !== 0 ? `${row.pointsToReach + row.balance} ${row.balance > 0 ? "- " + row.balance : "+ " + Math.abs(row.balance)} = ${row.pointsToReach} Punkte` : undefined}>{row.pointsToReach}</Points>,
        align: "right"
    },
    {
        key: "settingsDone",
        name: "Gemacht",
        group: "settings",
        type: "boolean",
        showField: (row) => <Ok value={row.settingsDone} />,
        align: "right"
    },
    {
        key: "lockOutEvening",
        name: "Abenddienst",
        group: "settings",
        type: "boolean",
        showField: (row) => <Ok value={!row.lockOutEvening} />,
        align: "right"
    },
    {
        key: "lockPublicHolidays",
        name: "Feiertage",
        group: "settings",
        type: "boolean",
        showField: (row) => <Ok value={!row.lockPublicHolidays} />,
        align: "right"
    },
    {
        key: "allocation",
        name: "Verteilung",
        group: "settings",
        showField: (row) => (row.allocation === "noPreference" ? "?" : row.allocation === "near" ? <span title="Dienste möglichst nahe nacheinander">→←</span> : <span title="Dienste möglichst über die ganze Planperiode verteilen">↔</span>),
        align: "right"
    },
    {
        key: "dayAndNightService",
        name: "Tages- & Abenddienst am selben Tag",
        group: "settings",
        showField: (row) => (row.dayAndNightService === "noPreference" ? "?" : <Ok value={row.dayAndNightService === "together"} />),
        align: "right"
    },
    {
        key: "weekDays",
        name: "Werktage",
        group: "absences",
        type: "number",
        align: "right"
    },
    {
        key: "weekendDays",
        name: "Wochenendtage",
        group: "absences",
        type: "number",
        align: "right"
    },
    {
        key: "globalPublicHolidays",
        name: "Globale Feiertage",
        group: "absences",
        type: "number",
        align: "right"
    },
    {
        key: "localPublicHolidays",
        name: "Lokale Feiertage",
        group: "absences",
        type: "number",
        align: "right"
    },
    {
        key: "preview",
        name: "Buchungen",
        group: "preview",
        showField: (row) => <BookingPreview favorites={row.favorites} booked={row.booked} points={row.points} pointsToReach={row.pointsToReach} />,
        notSortable: true
    },
    {
        key: "overallChance",
        name: "Gesamtchance",
        group: "preview",
        type: "number",
        showField: (row) => (row.overallChance === 0 ? "" : <ProgressBar value={row.overallChance} title={`${row.overallChance}%`} />)
    }
];

// {
//     key: "booked",
//     name: "Gebucht",
//     group: "bookings",
//     showField: (row) => <Booked booked={row.booked} points={row.points} pointsToReach={row.pointsToReach} />,
//     notSortable: true
// }

const AllocationTable = () => {
    const doctorInputs = useSelector((state) => state.roster.rosterPreview.doctorInputs);
    const persons = useSelector((state) => state.persons.entries);
    const rosterInfo = useSelector((state) => state.roster.info);
    const rosterState = useSelector((state) => state.roster.state);
    const [orderBy, setOrderBy] = useState(null);

    useEffect(() => {
        if (orderBy === null) {
            setOrderBy({
                key: fields[0].key,
                order: "asc"
            });
        }
    }, [orderBy]);

    if (!persons || !rosterInfo || !doctorInputs || !orderBy) {
        return null;
    }

    const changeOrderBy = (key) => {
        if (orderBy.key === key) {
            setOrderBy((prev) => ({ ...prev, order: prev.order === "asc" ? "desc" : "asc" }));
        } else {
            setOrderBy((prev) => ({ ...prev, key }));
        }
    };

    const getPersonName = (id) => {
        const person = persons.find((person) => person.id === id);
        if (person) {
            return person.lastname + " " + person.firstname;
        } else {
            return "???";
        }
    };

    const totalPoints = doctorInputs.reduce((sum, doctor) => (sum += doctor.points), 0);
    console.log("totalPoints", totalPoints);

    const tableFavorites = doctorInputs.map((entry) => ({ ...entry, person: getPersonName(entry.personId) }));

    return (
        <Panel title="Dienstplan | Zuteilung" size="xLarge" className={styles.panel} rosterPeriode categorieLevels={4} tabs={getRosterTabs(rosterState)} padding>
            <div className={styles.header}>
                <ViewChooser />
                <BookNow />
            </div>
            {tableFavorites.length > 0 && (
                <div className={styles.table}>
                    <NewSortableTable data={tableFavorites} fields={fields} groups={groups} changeOrder={changeOrderBy} orderBy={orderBy} />
                </div>
            )}
        </Panel>
    );
};

export default AllocationTable;
