import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SmallPanel from "../../../hoc/SmallPanel/SmallPanel";
import SearchFilter from "../../SearchFilter/SearchFilter";
import styles from "./ServiceCircleEditor.module.css";

const ServiceCircleEditor = ({ serviceCircleId, zips, locked, addZipsToServiceCircle, removeZipsFromServiceCircle }) => {
	const regions = useSelector((state) => state.regions);
	const [showAssigned, setShowAssigned] = useState(true);
	const [filterText, setFilterText] = useState("");
	const [toAssign, setToAssign] = useState([]);
	const [toRemove, setToRemove] = useState([]);

	useEffect(() => {
		setToAssign([]);
		setToRemove([]);
	}, [serviceCircleId]);

	const contains = (row) => {
		let searchStrings = [filterText];
		if (row.selected) {
			return true;
		}
		if (filterText.indexOf(" ") > -1) {
			searchStrings = filterText.split(" ");
		}

		const found = searchStrings.map((search) => {
			search = search.toLowerCase();
			if ((row.zip && row.zip.indexOf(search) > -1) || (row.city && row.city.toLowerCase().indexOf(search) > -1)) {
				return 1;
			} else {
				return 0;
			}
		});
		const foundCount = found.reduce((a, b) => a + b, 0);
		return foundCount === searchStrings.length;
	};

	const clickZip = (id) => {
		if (showAssigned) {
			if (toRemove.includes(id)) {
				setToRemove((prev) => prev.filter((entry) => entry !== id));
			} else {
				setToRemove((prev) => [...prev, id]);
			}
		} else {
			if (toAssign.includes(id)) {
				setToAssign((prev) => prev.filter((entry) => entry !== id));
			} else {
				setToAssign((prev) => [...prev, id]);
			}
		}
	};

	const sortList = (a, b) => {
		if (b.selected !== a.selected) {
			return b.selected - a.selected;
		} else {
			return a.zip - b.zip;
		}
	};

	const assignedRegionIds = zips.filter((entry) => entry.serviceCircleId === serviceCircleId).map((entry) => entry.regionId);
	const allAssignedRegionIds = zips.map((entry) => entry.regionId);
	const notAssignedRegionIds = regions.filter((entry) => !allAssignedRegionIds.includes(entry.id)).map((entry) => entry.id);

	const assignedRegions = regions
		.map((entry) => {
			return { ...entry, selected: toRemove.includes(entry.id) ? 1 : 0 };
		})
		.filter((entry) => assignedRegionIds.includes(entry.id) && contains(entry));
	const notAssignedRegions = regions
		.map((entry) => {
			return { ...entry, selected: toAssign.includes(entry.id) ? 1 : 0 };
		})
		.filter((entry) => notAssignedRegionIds.includes(entry.id) && contains(entry));

	const resultList = showAssigned ? assignedRegions.sort((a, b) => sortList(a, b)) : notAssignedRegions.sort((a, b) => sortList(a, b));

	let showMoveButton = false;

	const addZips = () => {
		addZipsToServiceCircle([...toAssign]);
		setToAssign([]);
	};

	const removeZips = () => {
		removeZipsFromServiceCircle([...toRemove]);
		setToRemove([]);
	};

	const getMoveButton = () => {
		return (
			<li className={styles.button}>
				{showAssigned ? (
					<button type="button" onClick={removeZips}>
						Entfernen
					</button>
				) : (
					<button type="button" onClick={addZips}>
						Zuordnen
					</button>
				)}
			</li>
		);
	};

	return (
		<SmallPanel title="Orte" classNames={[styles.panel]}>
			<div className={styles.content}>
				<SearchFilter filterText={filterText} setFilterText={setFilterText} />
				<div className="tabs">
					<div className={showAssigned ? "selected" : ""} onClick={() => setShowAssigned(true)}>
						Zugeordnet
						<div className="count">
							{filterText.length > 0 && assignedRegions.length + "/"}
							{assignedRegionIds.length}
						</div>
					</div>
					<div className={!showAssigned ? "selected" : ""} onClick={() => setShowAssigned(false)}>
						Unbenutzt
						<div className="count">
							{filterText.length > 0 && notAssignedRegions.length + "/"}
							{notAssignedRegionIds.length}
						</div>
					</div>
				</div>
				<ul className={styles.list + (locked ? " " + styles.locked : "")}>
					{resultList.length > 0 ? (
						resultList.map((entry, index) => {
							if (locked) {
								return (
									<li key={entry.id}>
										{entry.zip} {entry.city}
									</li>
								);
							} else {
								if (!index && entry.selected) {
									showMoveButton = true;
								}
								if (showMoveButton && (!entry.selected || resultList.length === index + 1)) {
									showMoveButton = false;
									if (!entry.selected) {
										return (
											<Fragment key={entry.id}>
												{getMoveButton()}
												<li onClick={() => clickZip(entry.id)}>
													{entry.zip} {entry.city}
												</li>
											</Fragment>
										);
									} else {
										return (
											<Fragment key={entry.id}>
												<li className="selected multi removeable" onClick={() => clickZip(entry.id)}>
													{entry.zip} {entry.city}
												</li>
												{getMoveButton()}
											</Fragment>
										);
									}
								}
								return (
									<li key={entry.id} className={entry.selected ? "selected multi removeable" : ""} onClick={() => clickZip(entry.id)}>
										{entry.zip} {entry.city}
									</li>
								);
							}
						})
					) : (
						<li className="none">Keine Orte</li>
					)}
				</ul>
			</div>
		</SmallPanel>
	);
};

export default ServiceCircleEditor;
