import { useState } from "react";
import { Resizable } from "react-resizable";
import { displayFloatTimeRange } from "../../../../../shared/functions";
import { ReactComponent as Trash } from "../../../../../assets/images/icon_trash.svg";
import styles from "./TimeBar.module.css";
import TimeBarHandle from "./TimeBarHandle/TimeBarHandle";

const TimeBar = ({ id, index, startTime, selectTime, selected, endTime, updateTime, deleteTime, maxHeight, min, max, setIsDragging, isDragging, locked }) => {
    const [start, setStart] = useState((maxHeight / 24) * startTime);
    const [end, setEnd] = useState((maxHeight / 24) * endTime);

    const onResize = (e, { handle, size }) => {
        if (handle === "n") {
            const newStart = end - size.height;
            const newStartTime = (newStart / maxHeight) * 24;
            if (newStart >= 0 && newStartTime >= min && newStart < end) {
                setStart(newStart);
            }
        } else {
            const newEnd = start + size.height;
            const newEndTime = (newEnd / maxHeight) * 24;
            if (newEndTime <= max && newEnd <= maxHeight && newEnd > start) {
                setEnd(newEnd);
            }
        }
    };

    const update = () => {
        updateTime(id, (start / maxHeight) * 24, (end / maxHeight) * 24);
        setIsDragging(false);
    };

    const classNames = [styles.resizable];
    if (locked) {
        classNames.push(styles.locked);
    }
    if (isDragging && selected) {
        classNames.push(styles.dragging);
    }
    if (selected) {
        classNames.push(styles.selected);
    }

    return (
        <Resizable
            width={26}
            height={end - start}
            style={{ top: start.toString() + "px" }}
            axis={locked ? "none" : "y"}
            handle={(handleAxis, ref) => <TimeBarHandle innerref={ref} axis={handleAxis} mainCssClass={styles.handle} topClass={styles.topHandle} bottomClass={styles.bottomHandle} />}
            resizeHandles={["s", "n"]}
            handleSize={[26, 10]}
            minConstraints={[26, 5]}
            draggableOpts={{ grid: [26, 5] }}
            onResize={onResize}
            onResizeStop={update}
            onResizeStart={() => setIsDragging(true)}
        >
            <div className={classNames.join(" ")} style={{ width: "26px", height: end - start + "px" }} title={displayFloatTimeRange((start / maxHeight) * 24, (end / maxHeight) * 24)} onClick={() => selectTime(id)}>
                {index > 0 && <div className={styles.topSpace}></div>}
                {!locked && <Trash onClick={() => deleteTime(id)} />}
            </div>
        </Resizable>
    );
};

export default TimeBar;
