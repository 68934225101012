import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import * as routes from "../../shared/routes";

//import WebAppMenu from "./WebAppMenu/WebAppMenu";

const uranusMenu = [
	{
		url: [routes.STRUCTURE],
		name: "Struktur"
	},
	{
		url: [routes.GEOGRAPHY],
		name: "Geographie"
	},
	{
		url: [routes.PUBLIC_HOLIDAYS],
		name: "Feiertage"
	},
	{
		url: [routes.ROSTER + "/" + routes.ROSTER_STATE, routes.ROSTER + "/" + routes.ROSTER_TIMES, routes.ROSTER + "/" + routes.ROSTER_DOCTORS, routes.ROSTER + "/" + routes.ROSTER_ALLOCATION],
		name: "Dienstplan"
	},
	{
		url: [routes.PERSONS, routes.MEDICAL_PRACTICES],
		name: "Personen & Praxen"
	}
];

const Menu = () => {
	const authenticated = useSelector((state) => state.auth.authenticated);
	const location = useLocation();
	//const webAppMenu = useSelector((state) => state.main.webAppMenu);

	if (!authenticated) {
		return null;
	}

	const isActive = (menu) => {
		return menu.url.reduce((active, url) => {
			return active || location.pathname.indexOf(url) === 0;
		}, false);
	};

	return (
		<ul id="menu">
			<li>{location.pathname === "/" ? <span className="active">Start</span> : <Link to="/">Start</Link>}</li>
			{uranusMenu.map((entry) => (
				<li key={entry.url[0]}>{isActive(entry) ? <span className="active">{entry.name}</span> : <Link to={entry.url[0]}>{entry.name}</Link>}</li>
			))}
			{/* <WebAppMenu menuItems={webAppMenu} /> */}
		</ul>
	);
};

export default Menu;
