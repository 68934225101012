//import styles from "./ServiceTemplateRow.module.css";

const ServiceTemplateRow = ({ icon, name, time, count }) => {
	return (
		<tr>
			<td>{icon ?? <br />}</td>
			<td>{name}</td>
			<td>{time}</td>
			<td>{count}</td>
		</tr>
	);
};

export default ServiceTemplateRow;
