import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	selectCategory,
	selectServiceCircle,
	selectServiceType,
	selectRosterPeriode,
	unSelectCategory,
	unSelectServiceType,
	unSelectServiceCircle,
	updateServiceCircleColor,
	createCategory,
	createServiceType,
	createServiceCircle,
	updateCategory,
	updateServiceType,
	updateServiceCircle
} from "../../features/category/categorySlice";
import * as routes from "../../shared/routes";

import CategoryColEdit from "./CategoryCol/CategoryColEdit";
import RosterPeriodeCol from "./RosterPeriodeCol/RosterPeriodeCol";

import styles from "./CategoryEditor.module.css";

const CategoryEditor = () => {
	const categories = useSelector((state) => state.category.categories);
	const serviceTypes = useSelector((state) => state.category.serviceTypes);
	const serviceCircles = useSelector((state) => state.category.serviceCircles);
	const rosterPeriodes = useSelector((state) => state.category.rosterPeriodes);

	const { categoryId, serviceTypeId, serviceCircleId, rosterPeriodId } = useSelector((state) => state.category.selected);
	const navigate = useNavigate();

	const editServiceCircle = () => {
		navigate(routes.STRUCTURE + "/" + serviceCircleId + routes.STRUCTURE_CONTINGENT);
	};

	return (
		<div className={styles.browser}>
			<CategoryColEdit title="Fachbereiche" items={categories} select={selectCategory} unselect={unSelectCategory} create={createCategory} update={updateCategory} selectedId={categoryId} addTitle="Neuer Fachbereich" messageTitle="Der Fachbereich" />
			{categoryId > 0 && (
				<CategoryColEdit
					title="Dienstarten"
					items={serviceTypes.filter((entry) => entry.categoryId === categoryId)}
					select={selectServiceType}
					unselect={unSelectServiceType}
					create={createServiceType}
					update={updateServiceType}
					selectedId={serviceTypeId}
					addTitle="Neue Dienstart"
					messageTitle="Die Dienstart"
					parentId={categoryId}
				/>
			)}
			{serviceTypeId > 0 && (
				<CategoryColEdit
					title="Dienstkreise"
					items={serviceCircles.filter((entry) => entry.serviceTypeId === serviceTypeId)}
					select={selectServiceCircle}
					unselect={unSelectServiceCircle}
					create={createServiceCircle}
					update={updateServiceCircle}
					selectedId={serviceCircleId}
					addTitle="Neuer Dienstkreis"
					messageTitle="Der Dienstkreis"
					parentId={serviceTypeId}
					updateColor={updateServiceCircleColor}
					editDetail={editServiceCircle}
				/>
			)}
			{serviceCircleId > 0 && <RosterPeriodeCol rosterPeriodes={rosterPeriodes.filter((entry) => entry.serviceCircleId === serviceCircleId)} selectedId={rosterPeriodId} select={selectRosterPeriode} serviceCircleId={serviceCircleId} />}
		</div>
	);
};

export default CategoryEditor;
