import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Address from "./Address/Address";
import styles from "./MedicalPracticeData.module.css";
import Persons from "./Persons/Persons";
import Phones from "./Phones/Phones";
import EMails from "./EMails/EMails";

import { deleteMedicalPractice } from "../../../../features/medicalPractice/medicalPracticeSlice";
import { ask, clear } from "../../../../features/ask/askSlice";

import * as askTypes from "../../../../shared/askTypes";
import * as routes from "../../../../shared/routes";
import Note from "../../../Note/Note";
import PanelError from "../../../../ui/PanelError/PanelError";

const MedicalPracticeData = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const medicalPractice = useSelector((state) => state.medicalPractice.data);
	const answer = useSelector((state) => state.ask.answer);

	useEffect(() => {
		if (answer) {
			if (answer.type === askTypes.DELETE_MEDICAL_PRACTICE && answer.value !== null) {
				dispatch(deleteMedicalPractice({ medicalPracticeId: id, redirect: routes.MEDICAL_PRACTICES }));
				dispatch(clear());
			}
		}
	}, [answer, dispatch, id]);

	if (!medicalPractice) {
		return null;
	}

	const askDeleteMedicalPractice = () => {
		dispatch(
			ask({
				type: askTypes.DELETE_MEDICAL_PRACTICE,
				title: "Praxis löschen",
				question: "Möchtest Du diese Praxis wirklich löschen?",
				buttons: [
					{ label: "Ja, löschen", answer: id },
					{ label: "Abbrechen", answer: null }
				]
			})
		);
	};

	return (
		<div className={styles.content}>
			<PanelError errors={medicalPractice.error} />
			<Address medicalPractice={medicalPractice} />
			<Persons medicalPracticeId={id} users={medicalPractice.users} />
			<Phones medicalPracticeId={id} phones={medicalPractice.phones} />
			<EMails medicalPracticeId={id} eMails={medicalPractice.eMails} />
			{medicalPractice.users.length > 0 ? (
				<Note>Die Praxis kann nicht gelöscht werden, da ihr Personen zugeteilt sind.</Note>
			) : (
				<div>
					<button type="button" onClick={askDeleteMedicalPractice}>
						Praxis löschen
					</button>
				</div>
			)}
		</div>
	);
};

export default MedicalPracticeData;
