import { useState } from "react";
import { useDispatch } from "react-redux";
import Panel from "../../../../hoc/Panel/Panel";
import Window from "../../../../hoc/Window/Window";
import DateRangeSelector from "../../../DateRangeSelector/DateRangeSelector";
import FooterButtons from "../../../../ui/FooterButtons/FooterButtons";
import Note from "../../../../components/Note/Note";
import { formatDate, getDateObjectFromMySqlDate, getMySqlDateTimeFromDateObject, isPast } from "../../../../shared/functions";
import { favoritesPeriod } from "../../../../features/roster/rosterSlice";

const EditFavoritesPeriod = ({ cancel, rosterPeriodId, favoriteStart, favoriteEnd }) => {
	const [period, setPeriode] = useState(null);
	const dispatch = useDispatch();

	const save = () => {
		const data = { favoriteStart: getMySqlDateTimeFromDateObject(period.start), favoriteEnd: getMySqlDateTimeFromDateObject(period.end) };
		dispatch(favoritesPeriod({ rosterPeriodId, data }));
		cancel();
	};

	return (
		<Window>
			<Panel title="Favoriten-Periode" size="small" padding>
				<DateRangeSelector setPeriode={setPeriode} startDateProtected={favoriteStart && isPast(getDateObjectFromMySqlDate(favoriteStart))} startDate={getDateObjectFromMySqlDate(favoriteStart)} endDate={getDateObjectFromMySqlDate(favoriteEnd)} includeHour inFuture />
				{period && period.start && (
					<Note marginBottom>
						Alle Ärzte des Dienstplanes werden am {formatDate(period.start)} um {period.start.getHours()}:00 Uhr per E-Mail benachrichtigt.
					</Note>
				)}
				<FooterButtons>
					<button type="button" onClick={cancel}>
						Abbrechen
					</button>
					<button type="button" onClick={save} disabled={!period || !period.start || !period.end}>
						OK
					</button>
				</FooterButtons>
			</Panel>
		</Window>
	);
};

export default EditFavoritesPeriod;
