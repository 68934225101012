import { useNavigate, useParams, useRoutes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Window from "../../../../hoc/Window/Window";
import Panel from "../../../../hoc/Panel/Panel";
import MedicalPracticeData from "../MedicalPracticeData/MedicalPracticeData";
import SettingsBrowser from "../../../../hoc/SettingsBrowser/SettingsBrowser";
import Log from "../Log/Log";
import Notes from "../../../Notes/Notes";

import * as routes from "../../../../shared/routes";
import { addMedicalPracticeNote, updateMedicalPracticeNote, clearMedicalPractice, getMedicalPractice } from "../../../../features/medicalPractice/medicalPracticeSlice";
import { useEffect } from "react";

const pages = [
	{
		path: routes.PRAXIS_DATA,
		element: <MedicalPracticeData />,
		name: "Praxis"
	},
	{
		path: routes.PRAXIS_LOG,
		element: <Log />,
		name: "Log"
	}
];

const MedicalPracticeDetailSelector = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const medicalPractice = useSelector((state) => state.medicalPractice.data);
	const baseUrl = routes.MEDICAL_PRACTICES + "/" + id;

	useEffect(() => {
		dispatch(getMedicalPractice(id));
		return () => {
			dispatch(clearMedicalPractice());
		};
	}, [dispatch, id]);

	const showRoutes = useRoutes([
		{
			path: "",
			element: <SettingsBrowser menus={pages} baseUrl={baseUrl} />,
			children: pages
		}
	]);

	if (!medicalPractice) {
		return null;
	}

	const close = () => {
		navigate(routes.MEDICAL_PRACTICES);
	};

	const addNote = (note) => {
		dispatch(addMedicalPracticeNote({ medicalPracticeId: id, data: { ...note } }));
	};

	const updateNote = (noteId, marked) => {
		dispatch(updateMedicalPracticeNote({ medicalPracticeId: id, id: noteId, data: { marked } }));
	};

	const notes = <Notes notes={medicalPractice.notes} addNote={addNote} updateNote={updateNote} />;

	return (
		<Window>
			<Panel title="Praxis bearbeiten" size="large" close={close} notes={notes}>
				{showRoutes}
			</Panel>
		</Window>
	);
};

export default MedicalPracticeDetailSelector;
