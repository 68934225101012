import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import MedicalPracticeFilter from "../../components/MedicalPractices/MedicalPracticeFilter/MedicalPracticeFilter";
import MedicalPracticeList from "../../components/MedicalPractices/MedicalPracticeList/MedicalPracticeList";

import { getPersons } from "../../features/persons/personsSlice";
import { getMedicalPractices } from "../../features/medicalPractices/medicalPracticesSlice";
import { Outlet } from "react-router-dom";
import DoctorsMedicalPractices from "../../components/DoctorsMedicalPractices/DoctorsMedicalPractices";

const MedicalPractices = () => {
	const dispatch = useDispatch();
	const persons = useSelector((state) => state.persons.entries);
	const medicalPractices = useSelector((state) => state.medicalPractices.entries);
	const reload = useSelector((state) => state.medicalPractices.reload);

	useEffect(() => {
		if (!persons) {
			dispatch(getPersons());
		}
	}, [dispatch, persons]);

	useEffect(() => {
		if (!medicalPractices || reload) {
			dispatch(getMedicalPractices());
		}
	}, [dispatch, medicalPractices, reload]);

	if (!persons || !medicalPractices) {
		return null;
	}

	return (
		<Fragment>
			<Outlet />
			<DoctorsMedicalPractices>
				<MedicalPracticeList medicalPractices={medicalPractices} />
				<MedicalPracticeFilter />
			</DoctorsMedicalPractices>
		</Fragment>
	);
};

export default MedicalPractices;
