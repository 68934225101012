import { useNavigate } from "react-router-dom";
import styles from "./Step.module.css";

import { ReactComponent as Edit } from "../../../../assets/images/icon_edit.svg";
import { ReactComponent as GoTo } from "../../../../assets/images/icon_goTo.svg";
import { ReactComponent as IconOk } from "../../../../assets/images/icon_ok_round_green.svg";
import { ReactComponent as IconWarning } from "../../../../assets/images/icon_warning_round_red.svg";

const Step = ({ title = "", ok = false, edit, goTo, children }) => {
	const classNames = [styles.step, ok ? styles.ok : styles.notOk];
	const navigate = useNavigate();

	const action = edit ? () => edit() : goTo ? () => navigate(goTo) : null;
	return (
		<div className={classNames.join(" ")} onClick={action}>
			<div className={styles.sign}>{ok ? <IconOk /> : <IconWarning />}</div>
			<div className={styles.content}>
				{title && <h4>{title}</h4>}
				{children}
			</div>
			<div className={styles.icon}>
				{edit && <Edit onClick={() => edit()} />}
				{goTo && <GoTo onClick={() => navigate(goTo)} />}
			</div>
		</div>
	);
};

export default Step;
