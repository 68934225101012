import styles from "./PlanName.module.css";

const PlanName = ({ name, setName }) => {
	return (
		<label className={styles.label}>
			Name <input type="text" value={name} maxLength={32} onChange={(e) => setName(e.target.value)} onBlur={() => setName(name)} />
		</label>
	);
};

export default PlanName;
