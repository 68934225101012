import { Fragment } from "react";
import RadioButton from "./RadioButton/RadioButton";
import styles from "./RadioSelect.module.css";

const RadioSelect = ({ data, label, keyName, valueName, selected, setSelected, switchToSelect = 5, horizontal = false, none = null, noneLabel = "Keine" }) => {
	if (data.length >= switchToSelect) {
		return (
			<select value={selected} size={1} onChange={(e) => setSelected(e.target.value)}>
				<option value={0}>{label} wählen...</option>
				{data.map((entry) => (
					<option key={entry[keyName]} value={entry[keyName]}>
						{entry[valueName]}
					</option>
				))}
			</select>
		);
	} else {
		const classNames = [styles.radio];
		if (horizontal) {
			classNames.push(styles.horizontal);
		}

		return (
			<Fragment>
				<label className={styles.label}>{label}</label>
				<div className={classNames.join(" ")}>
					{none !== null && (
						<i>
							<RadioButton value={none} name={noneLabel} selected={selected} set={setSelected} />
						</i>
					)}
					{data.map((entry) => (
						<RadioButton key={entry[keyName]} value={entry[keyName]} name={entry[valueName]} selected={selected} set={setSelected} />
					))}
				</div>
			</Fragment>
		);
	}
};

export default RadioSelect;
