import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { monthNames } from "../../../shared/globals";

import ButtonSelector from "../../../ui/ButtonSelector/ButtonSelector";

const MonthSelector = ({ start, end, monthCounts }) => {
    const [buttons, setButtons] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();

    const month = searchParams.get("monat");
    const year = searchParams.get("jahr");

    useEffect(() => {
        if (!buttons) {
            const startMonth = start.getMonth();
            const startYear = start.getFullYear();
            const endMonth = end.getMonth();
            const endYear = end.getFullYear();
            const items = [];
            let currentMonth = startMonth;
            let currentYear = startYear;
            let exit = false;
            do {
                exit = currentMonth === endMonth && currentYear === endYear;
                items.push({
                    name: monthNames[currentMonth] + " " + currentYear,
                    value: (currentMonth + 1).toString() + "-" + currentYear.toString(),
                    count: 0
                });
                currentMonth++;
                if (currentMonth > 11) {
                    currentMonth = 0;
                    currentYear++;
                }
            } while (!exit);
            setButtons(items);
            setSearchParams({ monat: start.getMonth() + 1, jahr: start.getFullYear() });
        }
    }, [start, end, buttons, setSearchParams]);

    if (buttons === null) {
        return null;
    }

    const selected = buttons.find((button) => button.value === month + "-" + year).value;

    const change = (monthYear) => {
        const month = parseInt(monthYear.slice(0, -4));
        const year = parseInt(monthYear.slice(-4));
        setSearchParams({ monat: month, jahr: year });
    };

    return <ButtonSelector buttons={buttons} selected={selected} setSelected={change} monthCounts={monthCounts} />;
};

export default MonthSelector;
