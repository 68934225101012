import styles from "./RadioButton.module.css";

const RadioButton = ({ value, name, selected, set }) => {
	return (
		<label key={value} className={styles.label}>
			<input type="radio" name={name} value={value} checked={value === selected} onChange={() => set(value)} />
			{name}
		</label>
	);
};

export default RadioButton;
