import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axios from "../../shared/axios";
import { logout } from "../auth/authSlice";
import { setTempSuccess } from "../message/messageSlice";
import { clearRosterState } from "../roster/rosterSlice";

const namespace = "category";

export const selectCategory = createAction(`${namespace}/selectCategory`);
export const selectServiceType = createAction(`${namespace}/selectServiceType`);
export const selectServiceCircle = createAction(`${namespace}/selectServiceCircle`);
export const selectRosterPeriode = createAction(`${namespace}/selectRosterPeriode`);
export const unSelectCategory = createAction(`${namespace}/unSelectCategory`);
export const unSelectServiceType = createAction(`${namespace}/unSelectServiceType`);
export const unSelectServiceCircle = createAction(`${namespace}/unSelectServiceCircle`);
export const setSelection = createAction(`${namespace}/setSelection`);

const defaultSelector = {
	show: false,
	requiredLevel: 0,
	maxRequiredLevel: 0,
	mode: "select",
	showOnlyCategories: null,
	showOnlyServiceTypes: null,
	showOnlyServiceCircles: null,
	hideRosterPeriodes: null
};

const initialState = {
	categories: null,
	serviceTypes: null,
	serviceCircles: null,
	rosterPeriodes: null,
	insert: null,
	selector: { ...defaultSelector },
	selected: {
		categoryId: "",
		serviceTypeId: "",
		serviceCircleId: "",
		rosterPeriodId: ""
	}
};

export const getCategories = createAsyncThunk(`${namespace}/getCategories`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.get("category");
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const getLastUsedDateOfRosterPeriod = createAsyncThunk(`${namespace}/getLastUsedDateOfRosterPeriod`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.get("category/rosterPeriode/" + payload + "/lastUsedDate");
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const createCategory = createAsyncThunk(`${namespace}/createCategory`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("category", payload.data);
		dispatch(setTempSuccess(payload.success));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const createServiceType = createAsyncThunk(`${namespace}/createServiceType`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("category/" + payload.parentId, payload.data);
		dispatch(setTempSuccess(payload.success));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const createServiceCircle = createAsyncThunk(`${namespace}/createServiceCircle`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("category/serviceType/" + payload.parentId, payload.data);
		dispatch(setTempSuccess(payload.success));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const createRosterPeriode = createAsyncThunk(`${namespace}/createRosterPeriode`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("category/serviceCircle/" + payload.serviceCircleId, payload);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const updateRosterPeriode = createAsyncThunk(`${namespace}/updateRosterPeriode`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.patch("category/rosterPeriode/" + payload.rosterPeriodId, payload.data);
		dispatch(clearRosterState());
		dispatch(setTempSuccess("Die Einstellungen zum Dienstplan wurden erfolgreich gespeichert."));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const updateCategory = createAsyncThunk(`${namespace}/updateCategory`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.patch("category/" + payload.id, payload.data);
		dispatch(setTempSuccess(payload.success));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const updateServiceType = createAsyncThunk(`${namespace}/updateServiceType`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.patch("category/serviceType/" + payload.id, payload.data);
		dispatch(setTempSuccess(payload.success));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const updateServiceCircle = createAsyncThunk(`${namespace}/updateServiceCircle`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.patch("category/serviceCircle/" + payload.id, payload.data);
		dispatch(setTempSuccess(payload.success));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const updateServiceCircleColor = createAsyncThunk(`${namespace}/updateServiceCircleColor`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.patch("category/serviceCircle/" + payload.id + "/color", payload.data);
		dispatch(setTempSuccess(payload.success));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

const categorySlice = createSlice({
	name: namespace,
	initialState,
	reducers: {
		showCategorySelector(state, { payload }) {
			state.selector = { ...defaultSelector, show: true, ...payload };
		},
		hideCategorySelector(state, { payload }) {
			state.selector = { ...state.selector, show: false };
		},
		clearInsert(state, { payload }) {
			state.insert = null;
		},
		setSelectorMode(state, { payload }) {
			state.selector = { ...state.selector, mode: payload };
		}
	},

	extraReducers: (builder) => {
		builder
			.addCase(getCategories.fulfilled, (state, { payload }) => {
				state.categories = payload.categories;
				state.serviceTypes = payload.serviceTypes;
				state.serviceCircles = payload.serviceCircles;
				state.rosterPeriodes = payload.rosterPeriodes;
			})
			.addCase(getLastUsedDateOfRosterPeriod.fulfilled, (state, { payload }) => {
				state.rosterPeriodes = state.rosterPeriodes.map((rosterPeriod) => {
					if (rosterPeriod.id === payload.rosterPeriodId) {
						return { ...rosterPeriod, lastUsedDate: payload.lastUsedDate };
					} else {
						return rosterPeriod;
					}
				});
			})
			.addCase(createCategory.fulfilled, (state, { payload }) => {
				state.categories.push(payload);
			})
			.addCase(createServiceType.fulfilled, (state, { payload }) => {
				state.serviceTypes.push(payload);
			})
			.addCase(createServiceCircle.fulfilled, (state, { payload }) => {
				state.serviceCircles.push(payload);
			})
			.addCase(createRosterPeriode.fulfilled, (state, { payload }) => {
				state.rosterPeriodes = payload;
			})
			.addCase(updateRosterPeriode.fulfilled, (state, { payload }) => {
				state.rosterPeriodes = state.rosterPeriodes.map((rosterPeriod) => {
					if (rosterPeriod.id === payload.id) {
						return { ...rosterPeriod, ...payload };
					} else {
						return rosterPeriod;
					}
				});
			})
			.addCase(updateCategory.fulfilled, (state, { payload }) => {
				state.categories = state.categories.map((entry) => {
					if (entry.id === payload.id) {
						entry.name = payload.name;
					}
					return entry;
				});
			})
			.addCase(updateServiceType.fulfilled, (state, { payload }) => {
				state.serviceTypes = state.serviceTypes.map((entry) => {
					if (entry.id === payload.id) {
						entry.name = payload.name;
					}
					return entry;
				});
			})
			.addCase(updateServiceCircle.fulfilled, (state, { payload }) => {
				state.serviceCircles = state.serviceCircles.map((entry) => {
					if (entry.id === payload.id) {
						entry.name = payload.name;
					}
					return entry;
				});
			})
			.addCase(updateServiceCircleColor.fulfilled, (state, { payload }) => {
				state.serviceCircles = state.serviceCircles.map((entry) => {
					if (entry.id === payload.id) {
						entry.colorId = payload.colorId;
					}
					return entry;
				});
			})
			.addCase(selectCategory, (state, { payload }) => {
				state.selected.categoryId = payload;
				state.selected.serviceTypeId = "";
				state.selected.serviceCircleId = "";
				state.selected.rosterPeriodId = "";
			})
			.addCase(selectServiceType, (state, { payload }) => {
				state.selected.serviceTypeId = payload;
				state.selected.serviceCircleId = "";
				state.selected.rosterPeriodId = "";
			})
			.addCase(selectServiceCircle, (state, { payload }) => {
				state.selected.serviceCircleId = payload;
				state.selected.rosterPeriodId = "";
			})
			.addCase(selectRosterPeriode, (state, { payload }) => {
				state.selected.rosterPeriodId = payload;
			})
			.addCase(unSelectCategory, (state) => {
				state.selected.categoryId = "";
				state.selected.serviceTypeId = "";
				state.selected.serviceCircleId = "";
				state.selected.rosterPeriodId = "";
			})
			.addCase(unSelectServiceType, (state) => {
				state.selected.serviceTypeId = "";
				state.selected.serviceCircleId = "";
				state.selected.rosterPeriodId = "";
			})
			.addCase(unSelectServiceCircle, (state) => {
				state.selected.serviceCircleId = "";
				state.selected.rosterPeriodId = "";
			})
			.addCase(setSelection, (state, { payload }) => {
				if (state.selector.mode === "insert") {
					state.insert = payload;
				} else {
					state.selected.categoryId = payload.categoryId;
					state.selected.serviceTypeId = payload.serviceTypeId;
					state.selected.serviceCircleId = payload.serviceCircleId;
					state.selected.rosterPeriodId = payload.rosterPeriodId;
				}
			})
			.addCase(logout.fulfilled, (state) => {
				return initialState;
			});
	}
});

export const { showCategorySelector, hideCategorySelector, clearInsert, setSelectorMode } = categorySlice.actions;

export default categorySlice.reducer;
