import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ask, clear } from "../../../features/ask/askSlice";
import { cancelEditGeographyPlan, deleteGeographyPlan, getGeographyPlan, newGeographyPlan, selectGeographyPlan, updateGeographyPlanColor } from "../../../features/geographyPlan/geographyPlanSlice";
import SmallPanel from "../../../hoc/SmallPanel/SmallPanel";
import ColorChooser from "../../ColorChooser/ColorChooser";
import EditableList from "../../EditableList/EditableList";

import * as askTypes from "../../../shared/askTypes";
import EditPlan from "../EditPlan/EditPlan";

const GeographyPlanSelector = ({ serviceTypeId }) => {
	const plans = useSelector((state) => state.geographyPlan.entries);
	const selectedPlanId = useSelector((state) => state.geographyPlan.selectedPlanId);
	const plan = useSelector((state) => state.geographyPlan.plan);
	const answer = useSelector((state) => state.ask.answer);
	const [chooseColor, setChooseColor] = useState(0);
	const dispatch = useDispatch();

	useEffect(() => {
		if (answer) {
			if (answer.type === askTypes.DELETE_DAY_PLAN && answer.value !== null) {
				const plan = plans.find((entry) => entry.id === answer.value);
				dispatch(deleteGeographyPlan({ id: plan.id, success: `Die zeitabhängige Karte «${plan.name}» wurde erfolgreich gelöscht.` }));
				dispatch(clear());
			}
		}
	}, [answer, dispatch, plans]);

	if (!plans || !serviceTypeId) {
		return null;
	}

	const onSelect = (id) => {
		dispatch(selectGeographyPlan(id));
	};

	const edit = () => {
		dispatch(getGeographyPlan({ serviceTypeId, geographyPlanId: selectedPlanId }));
	};

	const cancelEdit = () => {
		dispatch(cancelEditGeographyPlan());
	};

	const newPlan = () => {
		dispatch(newGeographyPlan());
	};

	const deleteItem = (id) => {
		const plan = plans.find((entry) => entry.id === id);
		dispatch(
			ask({
				type: askTypes.DELETE_DAY_PLAN,
				title: "Zeitabhängige Karte löschen",
				question: `Möchtest Du die zeitabhängige Karte «${plan.name}» wirklich löschen?`,
				buttons: [
					{ label: "Abbrechen", answer: null },
					{ label: "Ja, löschen", answer: id }
				]
			})
		);
	};

	const changeColor = (id) => {
		setChooseColor(id);
	};

	const selectedColor = chooseColor ? plans.find((entry) => entry.id === chooseColor).colorId : 0;

	const setColor = (colorId) => {
		if (selectedColor !== colorId) {
			dispatch(updateGeographyPlanColor({ id: chooseColor, data: { colorId }, success: "Die Farbe wurde erfolgreich gespeichert." }));
		}
		setChooseColor(0);
	};

	return (
		<div>
			<SmallPanel title="Zeitabhängige Karten" add={newPlan}>
				<EditableList entries={plans} selected={selectedPlanId} select={onSelect} edit={edit} deleteItem={deleteItem} changeColor={changeColor} />
			</SmallPanel>
			{chooseColor > 0 && plans && <ColorChooser usedColors={plans.map((entry) => entry.colorId)} selected={selectedColor} cancel={() => setChooseColor(0)} setColor={setColor} colorMustBeUnique={false} />}
			{plan && <EditPlan cancel={cancelEdit} />}
		</div>
	);
};

export default GeographyPlanSelector;
