import styles from "./Note.module.css";

const Note = ({ notes, children, marginTop = false, marginBottom = false }) => {
    const classNames = [styles.note];
    marginTop && classNames.push(styles.marginTop);
    marginBottom && classNames.push(styles.marginBottom);
    if (notes) {
        return (
            <div className={classNames.join(" ")}>
                <ul>
                    {notes.map((note, index) => (
                        <li key={index}>{note}</li>
                    ))}
                </ul>
            </div>
        );
    } else {
        return <div className={classNames.join(" ")}>{children}</div>;
    }
};

export default Note;
