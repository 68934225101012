import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../shared/axios";
import { logout } from "../auth/authSlice";
import { setSelection } from "../category/categorySlice";
import { setTempError, setTempSuccess } from "../message/messageSlice";
import { clearRosterState } from "../roster/rosterSlice";

const namespace = "geographyPlan";

const initialState = {
	serviceTypeId: null,
	entries: null,
	baseMaps: null,
	selectedBaseMapId: 0,
	selectedPlanId: 0,
	selectedTimeId: null,
	roster: null,
	year: null,
	plan: null,
	map: null,
	clipboard: null
};

export const getGeographyRoster = createAsyncThunk(`${namespace}/getGeographyRoster`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		dispatch(prepareYear(payload.year));
		const { data } = await axios.get("geographyRoster/" + payload.serviceTypeId + "/" + payload.year);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const setGeographyPlanOfDay = createAsyncThunk(`${namespace}/setGeographyPlanOfDay`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("geographyRoster/" + payload.serviceTypeId, payload.data);
		return data;
	} catch (error) {
		if (error.response?.status === 403) {
			dispatch(setTempError("Du kannst keine Änderungen am Kalender in der Vergangenheit vornehmen."));
		} else {
			dispatch(setTempError("Es ist ein Fehler aufgetreten."));
		}
		return rejectWithValue(error.response.status);
	}
});

export const deleteGeographyPlanOfDay = createAsyncThunk(`${namespace}/deleteGeographyPlanOfDay`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.delete("geographyRoster/" + payload);
		return data;
	} catch (error) {
		if (error.response?.status === 403) {
			dispatch(setTempError("Du kannst keine Änderungen am Kalender in der Vergangenheit vornehmen."));
		} else {
			dispatch(setTempError("Es ist ein Fehler aufgetreten."));
		}
		return rejectWithValue(error.response.status);
	}
});

export const setGeographyPlanRepetitive = createAsyncThunk(`${namespace}/setGeographyPlanRepetitive`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("geographyRoster/" + payload.serviceTypeId + "/repetitive", payload.data);
		return data;
	} catch (error) {
		if (error.response?.status === 403) {
			dispatch(setTempError("Du kannst keine Änderungen am Kalender in der Vergangenheit vornehmen."));
		} else {
			dispatch(setTempError("Es ist ein Fehler aufgetreten."));
		}
		return rejectWithValue(error.response.status);
	}
});

export const getGeographyPlans = createAsyncThunk(`${namespace}/getGeographyPlans`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		dispatch(prepareServiceType(payload.serviceTypeId));
		dispatch(prepareYear(null));
		const { data } = await axios.get("geographyPlan/" + payload.serviceTypeId);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const getGeographyPlan = createAsyncThunk(`${namespace}/getGeographyPlan`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.get("geographyPlan/" + payload.serviceTypeId + "/" + payload.geographyPlanId);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const getBaseMap = createAsyncThunk(`${namespace}/getBaseMap`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.get("geographyPlan/baseMap/" + payload.serviceTypeId + "/" + payload.baseMapId);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const createGeographyPlan = createAsyncThunk(`${namespace}/createGeographyPlan`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("geographyPlan/" + payload.serviceTypeId, payload.data);
		dispatch(setTempSuccess(payload.success));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const updateGeographyPlan = createAsyncThunk(`${namespace}/updateGeographyPlan`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.patch("geographyPlan/" + payload.id, payload.data);
		dispatch(setTempSuccess(payload.success));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const updateGeographyPlanName = createAsyncThunk(`${namespace}/updateGeographyPlanName`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.patch("geographyPlan/name/" + payload.id, payload.data);
		dispatch(setTempSuccess(payload.success));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const updateGeographyPlanColor = createAsyncThunk(`${namespace}/updateGeographyPlanColor`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.patch("geographyPlan/" + payload.id + "/color", payload.data);
		dispatch(setTempSuccess(payload.success));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const deleteGeographyPlan = createAsyncThunk(`${namespace}/deleteGeographyPlan`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.delete("geographyPlan/" + payload.id);
		dispatch(setTempSuccess(payload.success));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const updateBaseMapColor = createAsyncThunk(`${namespace}/updateBaseMapColor`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.patch("geographyPlan/baseMap/" + payload.id + "/color", payload.data);
		dispatch(setTempSuccess(payload.success));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const updateBaseMap = createAsyncThunk(`${namespace}/updateBaseMap`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.patch("geographyPlan/baseMap/" + payload.id, payload.data);
		dispatch(setTempSuccess(payload.success));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const updateBaseMapName = createAsyncThunk(`${namespace}/updateBaseMapName`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.patch("geographyPlan/baseMap/name/" + payload.id, payload.data);
		dispatch(setTempSuccess(payload.success));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const deleteBaseMap = createAsyncThunk(`${namespace}/deleteBaseMap`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.delete("geographyPlan/baseMap/" + payload.id);
		dispatch(setTempSuccess(payload.success));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const createBaseMap = createAsyncThunk(`${namespace}/createBaseMap`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("geographyPlan/baseMap/" + payload.serviceTypeId, payload.data);
		dispatch(setTempSuccess(payload.success));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const setBaseMapOfDay = createAsyncThunk(`${namespace}/setBaseMapOfDay`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("geographyRoster/baseMap/" + payload.serviceTypeId, payload.data);
		dispatch(clearRosterState());
		return data;
	} catch (error) {
		if (error.response?.status === 403) {
			dispatch(setTempError("Du kannst keine Änderungen am Kalender in der Vergangenheit vornehmen."));
		} else {
			dispatch(setTempError("Es ist ein Fehler aufgetreten."));
		}
		return rejectWithValue(error.response.status);
	}
});

export const setBaseMapRepetitive = createAsyncThunk(`${namespace}/setBaseMapRepetitive`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("geographyRoster/baseMap/" + payload.serviceTypeId + "/repetitive", payload.data);
		dispatch(clearRosterState());
		return data;
	} catch (error) {
		if (error.response?.status === 403) {
			dispatch(setTempError("Du kannst keine Änderungen am Kalender in der Vergangenheit vornehmen."));
		} else {
			dispatch(setTempError("Es ist ein Fehler aufgetreten."));
		}
		return rejectWithValue(error.response.status);
	}
});

const geographyPlanSlice = createSlice({
	name: namespace,
	initialState,
	reducers: {
		selectBaseMap: (state, action) => {
			state.selectedBaseMapId = action.payload;
			state.selectedPlanId = 0;
		},
		selectGeographyPlan: (state, action) => {
			state.selectedPlanId = action.payload;
			state.selectedBaseMapId = 0;
		},
		prepareServiceType: (state, action) => {
			state.serviceTypeId = action.payload;
			state.entries = null;
		},
		prepareYear: (state, action) => {
			state.year = action.payload;
			state.roster = null;
		},
		selectTime: (state, action) => {
			state.selectedTimeId = action.payload;
		},
		createGeographyPlanTime: (state, action) => {
			state.plan.times.push({ id: -new Date().getTime(), ...action.payload, zips: [] });
		},
		deleteGeographyPlanTime: (state, action) => {
			state.selectedTimeId = null;
			state.plan.times = state.plan.times.filter((entry) => entry.id !== action.payload);
		},
		updateGeographyPlanTime: (state, action) => {
			state.plan.times = state.plan.times.map((entry) => {
				if (entry.id === action.payload.id) {
					return { ...entry, start: action.payload.start, end: action.payload.end };
				} else {
					return entry;
				}
			});
		},
		addZipToServiceCircleTime: (state, action) => {
			const { serviceCircleId, timeId, regionId } = action.payload;
			state.plan.times = state.plan.times.map((entry) => {
				if (entry.id === timeId) {
					return { ...entry, zips: [...entry.zips, { id: -new Date().getTime(), serviceCircleId, regionId }] };
				} else {
					return entry;
				}
			});
		},
		removeZipFromServiceCircleTime: (state, action) => {
			const { timeId, id } = action.payload;
			state.plan.times = state.plan.times.map((entry) => {
				if (entry.id === timeId) {
					return { ...entry, zips: entry.zips.filter((zip) => zip.id !== id) };
				} else {
					return entry;
				}
			});
		},
		addZipsToServiceCircleTime: (state, action) => {
			const { serviceCircleId, timeId, regions } = action.payload;
			const newRegions = regions.map((regionId) => ({ id: -new Date().getTime(), serviceCircleId, regionId }));
			state.plan.times = state.plan.times.map((entry) => {
				if (entry.id === timeId) {
					return { ...entry, zips: [...entry.zips, ...newRegions] };
				} else {
					return entry;
				}
			});
		},
		removeZipsFromServiceCircleTime: (state, action) => {
			const { serviceCircleId, timeId, regions } = action.payload;
			state.plan.times = state.plan.times.map((entry) => {
				if (entry.id === timeId) {
					return { ...entry, zips: entry.zips.filter((zip) => !(zip.serviceCircleId === serviceCircleId && regions.includes(zip.regionId))) };
				} else {
					return entry;
				}
			});
		},
		changeGeographyPlanName: (state, action) => {
			state.plan.name = action.payload;
		},
		cancelEditGeographyPlan: (state, action) => {
			state.plan = null;
			state.selectedTimeId = null;
		},
		newGeographyPlan: (state, action) => {
			state.plan = {
				id: 0,
				name: "",
				times: []
			};
		},
		removeZipFromServiceCircle: (state, action) => {
			const { id } = action.payload;
			state.map.zips = state.map.zips.filter((zip) => zip.id !== id);
		},
		addZipToServiceCircle: (state, action) => {
			const { serviceCircleId, regionId } = action.payload;
			state.map.zips.push({ id: -new Date().getTime(), serviceCircleId, regionId });
		},
		addZipsToServiceCircle: (state, action) => {
			const { serviceCircleId, regions } = action.payload;
			const newRegions = regions.map((regionId) => ({ id: -new Date().getTime(), serviceCircleId, regionId }));
			state.map.zips.push(...newRegions);
		},
		removeZipsFromServiceCircle: (state, action) => {
			const { serviceCircleId, regions } = action.payload;
			state.map.zips = state.map.zips.filter((zip) => !(zip.serviceCircleId === serviceCircleId && regions.includes(zip.regionId)));
		},
		cancelEditBaseMap: (state, action) => {
			state.map = null;
		},
		newBaseMap: (state, action) => {
			state.map = {
				id: 0,
				name: "",
				zips: []
			};
		},
		copy: (state, action) => {
			state.clipboard = action.payload;
		},
		paste: (state, action) => {
			if (state.map) {
				state.map.zips = state.clipboard.zips;
			} else {
				state.plan.times = state.plan.times.map((time) => {
					if (time.id === state.selectedTimeId) {
						return { ...time, zips: state.clipboard.zips };
					}
					return time;
				});
			}
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getGeographyPlans.fulfilled, (state, { payload }) => {
				state.baseMaps = payload.baseMaps;
				state.entries = payload.plans;
			})
			.addCase(getGeographyPlan.fulfilled, (state, { payload }) => {
				state.plan = payload;
			})
			.addCase(getBaseMap.fulfilled, (state, { payload }) => {
				state.map = payload;
			})
			.addCase(createGeographyPlan.fulfilled, (state, { payload }) => {
				state.entries = payload;
				state.plan = null;
				state.selectedTimeId = null;
			})
			.addCase(updateGeographyPlan.fulfilled, (state, { payload }) => {
				state.entries = payload;
				state.plan = null;
				state.selectedTimeId = null;
			})
			.addCase(updateGeographyPlanName.fulfilled, (state, { payload }) => {
				state.entries = payload;
				state.plan = null;
				state.selectedTimeId = null;
			})
			.addCase(updateGeographyPlanColor.fulfilled, (state, { payload }) => {
				state.entries = state.entries.map((entry) => {
					if (entry.id === payload.id) {
						return { ...entry, colorId: payload.colorId };
					} else {
						return entry;
					}
				});
			})
			.addCase(updateBaseMapColor.fulfilled, (state, { payload }) => {
				state.baseMaps = state.baseMaps.map((entry) => {
					if (entry.id === payload.id) {
						return { ...entry, colorId: payload.colorId };
					} else {
						return entry;
					}
				});
			})
			.addCase(updateBaseMap.fulfilled, (state, { payload }) => {
				state.baseMaps = payload;
				state.map = null;
			})
			.addCase(updateBaseMapName.fulfilled, (state, { payload }) => {
				state.baseMaps = payload;
				state.map = null;
			})
			.addCase(deleteBaseMap.fulfilled, (state, { payload }) => {
				state.selectedBaseMapId = 0;
				state.baseMaps = state.baseMaps.filter((entry) => entry.id !== payload.id);
			})
			.addCase(deleteGeographyPlan.fulfilled, (state, { payload }) => {
				state.selectedPlanId = 0;
				state.selectedTimeId = null;
				state.entries = state.entries.filter((entry) => entry.id !== payload.id);
			})
			.addCase(getGeographyRoster.fulfilled, (state, { payload }) => {
				state.roster = payload;
			})
			.addCase(getGeographyRoster.rejected, (state, { payload }) => {
				state.roster = [];
			})
			.addCase(setGeographyPlanOfDay.fulfilled, (state, { payload }) => {
				const { day, usedCount } = payload;
				state.roster = [...state.roster.filter((entry) => entry.id !== day.id), day];
				state.entries = state.entries.map((entry) => {
					if (entry.id === day.geographyPlanId) {
						return { ...entry, usedCount };
					} else {
						return entry;
					}
				});
			})
			.addCase(createBaseMap.fulfilled, (state, { payload }) => {
				state.baseMaps = payload;
				state.map = null;
			})
			.addCase(setBaseMapOfDay.fulfilled, (state, { payload }) => {
				const { day, usedCount } = payload;
				state.roster = [...state.roster.filter((entry) => entry.id !== day.id), day];
				state.baseMaps = state.baseMaps.map((entry) => {
					if (entry.id === day.baseMapId) {
						return { ...entry, usedCount };
					} else {
						return entry;
					}
				});
			})
			.addCase(setSelection, (state, { payload }) => {
				return initialState;
			})
			.addCase(deleteGeographyPlanOfDay.fulfilled, (state, { payload }) => {
				const { id, usedCount, geographyPlanId } = payload;
				state.roster = state.roster.map((entry) => {
					if (entry.id === id) {
						return { ...entry, geographyPlanId: null };
					} else {
						return entry;
					}
				});
				state.entries = state.entries.map((entry) => {
					if (entry.id === geographyPlanId) {
						return { ...entry, usedCount };
					} else {
						return entry;
					}
				});
			})
			.addCase(setGeographyPlanRepetitive.fulfilled, (state, { payload }) => {
				const { roster, usedCount, geographyPlanId } = payload;
				state.roster = roster;
				state.entries = state.entries.map((entry) => {
					if (entry.id === geographyPlanId) {
						return { ...entry, usedCount };
					} else {
						return entry;
					}
				});
			})
			.addCase(setBaseMapRepetitive.fulfilled, (state, { payload }) => {
				const { roster, usedCount, baseMapId } = payload;
				state.roster = roster;
				state.baseMaps = state.baseMaps.map((entry) => {
					if (entry.id === baseMapId) {
						return { ...entry, usedCount };
					} else {
						return entry;
					}
				});
			})
			.addCase(logout.fulfilled, (state, { payload }) => {
				return initialState;
			});
	}
});

export const {
	selectBaseMap,
	selectGeographyPlan,
	prepareServiceType,
	prepareYear,
	selectTime,
	createGeographyPlanTime,
	deleteGeographyPlanTime,
	updateGeographyPlanTime,
	addZipToServiceCircleTime,
	removeZipFromServiceCircleTime,
	changeGeographyPlanName,
	addZipsToServiceCircleTime,
	removeZipsFromServiceCircleTime,
	cancelEditGeographyPlan,
	newGeographyPlan,
	removeZipFromServiceCircle,
	addZipToServiceCircle,
	addZipsToServiceCircle,
	removeZipsFromServiceCircle,
	cancelEditBaseMap,
	newBaseMap,
	copy,
	paste
} = geographyPlanSlice.actions;

export default geographyPlanSlice.reducer;
