import React, { useRef, useState } from "react";

import styles from "./InputDate.module.css";

const InputDate = ({ date = null, set, error = false, disabled = false }) => {
    const [year, month, day] = date ? date.split("-") : ["", "", ""];
    const [editDay, setEditDay] = useState(`${day}`);
    const [editMonth, setEditMonth] = useState(`${month}`);
    const [editYear, setEditYear] = useState(`${year}`);
    const monthRef = useRef();
    const yearRef = useRef();

    const classNames = [styles.date];
    error && classNames.push(styles.error);

    const changeDay = (e) => {
        const value = e.target.value;

        if (value === "" || (value.match(/^(\d){1,2}$/) && parseInt(value) < 32)) {
            setEditDay(value);
            set(editYear + "-" + editMonth + "-" + value);
            if (value.length === 2) {
                monthRef.current.focus();
            }
        }
    };

    const changeMonth = (e) => {
        const value = e.target.value;

        if (value === "" || (value.match(/^(\d){1,2}$/) && parseInt(value) < 13)) {
            setEditMonth(value);
            set(editYear + "-" + value + "-" + editDay);
            if (value.length === 2) {
                yearRef.current.focus();
            }
        }
    };

    const changeYear = (e) => {
        const value = e.target.value;

        if (value === "" || value.match(/^(\d){1,4}$/)) {
            setEditYear(value);
            set(value + "-" + editMonth + "-" + editDay);
        }
    };

    return (
        <div className={classNames.join(" ")}>
            <input type="text" value={editDay} maxLength={2} onChange={changeDay} placeholder="Tg" disabled={disabled} />.<input type="text" value={editMonth} ref={monthRef} maxLength={2} onChange={changeMonth} placeholder="Mt" disabled={disabled} />.
            <input type="text" value={editYear} ref={yearRef} maxLength={4} onChange={changeYear} placeholder="Jahr" disabled={disabled} />
        </div>
    );
};

export default InputDate;
