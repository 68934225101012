import { useNavigate } from "react-router-dom";
import ToolTip from "../../../../ui/ToolTip/ToolTip";
import styles from "./Day.module.css";

import * as routes from "../../../../shared/routes";
import { dateIsInFuture } from "../../../../shared/functions";

const Day = ({ data }) => {
	const navigate = useNavigate();
	const classNames = [styles.day];

	const [year, month, day] = data.date.split("-");
	const inFuture = dateIsInFuture(parseInt(day, 10), parseInt(month, 10), parseInt(year, 10));
	inFuture && classNames.push(styles.enabled);

	if (data.name !== null) {
		data.serviceCircleId !== "0" ? classNames.push(styles.regionalHoliday) : classNames.push(styles.publicHoliday);
	}

	const goToDay = () => {
		if (inFuture) {
			navigate(routes.PUBLIC_HOLIDAYS + "/" + data.date.replace(/-/g, "/"));
		}
	};

	return (
		<div className={classNames.join(" ")} onClick={goToDay}>
			{data.name !== null && (
				<ToolTip>
					<span>{data.name}</span>
				</ToolTip>
			)}
			{parseInt(day, 10)}
		</div>
	);
};

export default Day;
