import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setTempSuccess } from "../features/message/messageSlice";

const TempSuccess = () => {
	const success = useSelector((state) => state.message.tempSuccess);
	const dispatch = useDispatch();
	const clearTempSuccess = useCallback(() => dispatch(setTempSuccess(null)), [dispatch]);
	const [showSuccess, setShowSuccess] = useState(false);

	useEffect(() => {
		if (success) {
			setShowSuccess(true);
			window.setTimeout(() => setShowSuccess(false), 4500);
			window.setTimeout(clearTempSuccess, 5000);
		} else {
			setShowSuccess(false);
		}
	}, [success, clearTempSuccess]);

	return (
		<div className={"tempSuccess" + (showSuccess ? " show" : "")}>
			<div>{success}</div>
		</div>
	);
};

export default TempSuccess;
