import { useSelector } from "react-redux";

const ServiceCircle = () => {
	const serviceCircles = useSelector((state) => state.category.serviceCircles);
	const { serviceCircleId } = useSelector((state) => state.category.selected);
	const serviceCircle = serviceCircles.find((entry) => entry.id === serviceCircleId);

	if (!serviceCircle) return null;

	return serviceCircle.name;
};

export default ServiceCircle;
