import { ReactComponent as Trash } from "../../../../assets/images/icon_trash.svg";
import { ReactComponent as Edit } from "../../../../assets/images/icon_edit.svg";
import { ReactComponent as GoTo } from "../../../../assets/images/icon_goTo.svg";

import styles from "./ResourceListItem.module.css";

const ResourceListItem = ({ id, name, editItem, deleteItem, jumpToItem }) => {
	return (
		<li>
			<div className={styles.text}>{name}</div>
			<div className={styles.icons}>
				{jumpToItem && <GoTo className={styles.icon} onClick={() => jumpToItem(id)} title={"Gehe zu..."} />}
				{deleteItem && <Trash className={styles.icon} onClick={(e) => deleteItem(e, id)} title={"Löschen..."} />}
				{editItem && <Edit className={styles.icon} onClick={() => editItem(id)} title={"Bearbeiten..."} />}
			</div>
		</li>
	);
};

export default ResourceListItem;
