import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showCategorySelector } from "../../../features/category/categorySlice";

import { ReactComponent as Change } from "../../../assets/images/icon_change.svg";

import styles from "./CurrentCategory.module.css";
import DisplayDate from "../../Display/DisplayDate/DisplayDate";

const CurrentCategory = ({ levels, maxRequiredLevel }) => {
	const categories = useSelector((state) => state.category.categories);
	const serviceTypes = useSelector((state) => state.category.serviceTypes);
	const serviceCircles = useSelector((state) => state.category.serviceCircles);
	const rosterPeriodes = useSelector((state) => state.category.rosterPeriodes);
	const { categoryId, serviceTypeId, serviceCircleId, rosterPeriodId } = useSelector((state) => state.category.selected);
	const dispatch = useDispatch();

	const category = categoryId ? categories.find((entry) => entry.id === categoryId).name : "";
	const serviceType = serviceTypeId ? serviceTypes.find((entry) => entry.id === serviceTypeId).name : "";
	const serviceCircle = serviceCircleId ? serviceCircles.find((entry) => entry.id === serviceCircleId).name : "";
	const rosterPeriode = rosterPeriodId ? (
		<Fragment>
			<DisplayDate date={rosterPeriodes.find((entry) => entry.id === rosterPeriodId).start} short /> - <DisplayDate date={rosterPeriodes.find((entry) => entry.id === rosterPeriodId).end} short />
		</Fragment>
	) : (
		""
	);

	const displayLevels = maxRequiredLevel ? maxRequiredLevel : levels;

	return (
		<div className={styles.currentCategory} onClick={() => dispatch(showCategorySelector({ requiredLevel: levels, maxRequiredLevel }))}>
			<Change />
			<span>{category}</span>
			{serviceType && displayLevels > 1 && (
				<Fragment>
					<span>{serviceType}</span>
					{serviceCircle && displayLevels > 2 && (
						<Fragment>
							<span>{serviceCircle}</span>
							{rosterPeriode && displayLevels > 3 && <span>{rosterPeriode}</span>}
						</Fragment>
					)}
				</Fragment>
			)}
		</div>
	);
};

export default CurrentCategory;
