import styles from "./FooterButtons.module.css";

const FooterButtons = ({ children, padding = false, paddingTop = false }) => {
	const classNames = [styles.buttons];
	padding && classNames.push(styles.padding);
	paddingTop && classNames.push(styles.paddingTop);
	return <div className={classNames.join(" ")}>{children}</div>;
};

export default FooterButtons;
