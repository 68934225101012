import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { clearLog, getLog } from "../../../../features/medicalPractice/medicalPracticeSlice";

import ShowLog from "../../../ShowLog/ShowLog";

const Log = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const log = useSelector((state) => state.medicalPractice.log);

	useEffect(() => {
		dispatch(getLog(id));
	}, [dispatch, id]);

	useEffect(() => {
		return () => dispatch(clearLog());
	}, [dispatch]);

	if (!log) {
		return null;
	}

	return <ShowLog log={log} />;
};

export default Log;
