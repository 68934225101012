import { Fragment } from "react";
import { useSelector } from "react-redux";

import { ReactComponent as ArrowRight } from "../../../../assets/images/arrowRight2.svg";
import { ReactComponent as RollingEyes } from "../../../../assets/images/rollingEyes.svg";

import styles from "./BookingPreview.module.css";
import Points from "../../../Display/Points/Points";
import ShowBooking from "./ShowBooking/ShowBooking";

const BookingPreview = ({ favorites, booked, points, pointsToReach }) => {
    const serviceTemplates = useSelector((state) => state.serviceTemplate.entries);
    const roster = useSelector((state) => state.roster.rosterPreview.roster);

    if (!serviceTemplates) {
        return null;
    }

    const rosterEntries = roster.filter((entry) => favorites.includes(entry.id) || booked.includes(entry.id));

    const totalPointsFavorites = rosterEntries.reduce((points, entry) => (points += favorites.includes(entry.id) ? entry.points : 0), 0);

    return (
        <Fragment>
            <div className={styles.header}>
                <div>
                    <ArrowRight className={styles.arrow} /> <Points>{points}</Points>
                    {Math.abs(100 - (100 / pointsToReach) * points) > 5 && (
                        <span title="Prozentuale Abweichung > 5%">
                            <ArrowRight className={styles.arrow} /> <RollingEyes className={styles.rollingEyes} />
                        </span>
                    )}
                </div>
                <div>Dienstwünsche: {favorites.length > 0 ? <Points>{totalPointsFavorites}</Points> : <i>Keine</i>}</div>
            </div>
            <div className={styles.favorites}>
                {rosterEntries.map((entry) => {
                    const serviceTemplate = serviceTemplates.find((serviceTemplate) => serviceTemplate.id === entry.serviceTemplateId);
                    const isBooked = booked.includes(entry.id);
                    const isFavorite = favorites.includes(entry.id);
                    return <ShowBooking key={entry.id} isBooked={isBooked} isFavorite={isFavorite} startDay={entry.start} endDay={entry.end} serviceTemplate={serviceTemplate} points={entry.points} chance={entry.chance} includesPublicHolidays={entry.includesPublicHolidays} />;
                })}
            </div>
        </Fragment>
    );
};

export default BookingPreview;
