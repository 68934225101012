import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getPersonRosterPeriods } from "../../../../features/person/personSlice";
import SmallPanel from "../../../../hoc/SmallPanel/SmallPanel";

import styles from "./Rosters.module.css";
import Period from "./Period/Period";
import { nfdStates } from "../../../../shared/globals";

const Rosters = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const rosterPeriods = useSelector((state) => state.person.rosterPeriods);
	const person = useSelector((state) => state.person.person);
	const serviceTypes = person ? person.serviceTypes : null;

	useEffect(() => {
		dispatch(getPersonRosterPeriods(id));
	}, [dispatch, id]);

	if (!rosterPeriods || !serviceTypes) {
		return null;
	}

	const notes = [];
	serviceTypes.length === 0 && notes.push("Wähle unter «Konto» mindestens eine Dienstart");
	person.practices.find((entry) => entry.emergencyService) === undefined && notes.push("Lege fest, in welcher Praxis der Notfalldienst geleistet wird");
	person.nfdState !== "active_service" && notes.push("Setze den Notfalldienst-Status auf «" + nfdStates.find((entry) => entry.key === "active_service").name + "»");

	return (
		<SmallPanel title="Aktuelle Dienstpläne">
			<ul className={styles.list}>
				{rosterPeriods.length > 0 ? (
					rosterPeriods.map((entry) => {
						return (
							<li key={entry.id}>
								<Period period={entry} />
							</li>
						);
					})
				) : (
					<li>
						<i>Keine Einträge</i>
					</li>
				)}
			</ul>
		</SmallPanel>
	);
};

export default Rosters;
