import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import SmallPanel from "../../../../../hoc/SmallPanel/SmallPanel";
import ResourceList from "../../../../../ui/List/ResourceList/ResourceList";

import MedicalPracticeChooser from "../../../../MedicalPracticeChooser/MedicalPracticeChooser";
import EditPerson from "./EditPerson/EditPerson";

import * as askTypes from "../../../../../shared/askTypes";
import { ask, clear } from "../../../../../features/ask/askSlice";
import { removePersonFromMedicalPractice } from "../../../../../features/person/personSlice";
import * as routes from "../../../../../shared/routes";

const MedicalPractices = ({ personId, medicalPractices, personName }) => {
	const [addMedicalPractice, setAddMedicalPractice] = useState(false);
	const [medicalPracticeId, setMedicalPracticeId] = useState(0);
	const answer = useSelector((state) => state.ask.answer);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (answer) {
			if (answer.type === askTypes.REMOVE_PERSON_FROM_MEDICAL_PRACTICE && answer.value !== null) {
				dispatch(removePersonFromMedicalPractice({ personId, medicalPracticeId: answer.value }));
				dispatch(clear());
			}
		}
	}, [answer, dispatch, personId]);

	const deleteMedicalPractice = (e, medicalPracticeId) => {
		if (e.altKey) {
			dispatch(removePersonFromMedicalPractice({ medicalPracticeId, personId }));
		} else {
			dispatch(
				ask({
					type: askTypes.REMOVE_PERSON_FROM_MEDICAL_PRACTICE,
					title: `Person aus Praxis entfernen`,
					question: `Möchtest Du «${personName}» wirklich aus der Praxis entfernen?`,
					buttons: [
						{ label: "Ja, entfernen", answer: medicalPracticeId },
						{ label: "Abbrechen", answer: null }
					]
				})
			);
		}
	};

	const jumpToMedicalPractice = (id) => {
		navigate(routes.MEDICAL_PRACTICES + "/" + id + routes.PRAXIS_DATA);
	};

	const entries = medicalPractices.map((medicalPractice) => {
		const options = [];
		medicalPractice.admin && options.push("Administrator");
		medicalPractice.emergencyService && options.push("Notfalldienst in dieser Praxis");

		return {
			id: medicalPractice.id,
			name: `${medicalPractice.name} ${medicalPractice.city}${options.length > 0 ? " (" + options.join(", ") + ")" : ""}`
		};
	});

	const isAdmin = () => {
		return medicalPractices.find((entry) => entry.id === personId)?.admin || false;
	};

	const doesEmergencyService = () => {
		return medicalPractices.find((entry) => entry.id === personId)?.emergencyService || false;
	};

	const notDeletable = medicalPractices.filter((entry) => entry.emergencyService).map((entry) => entry.id);

	return (
		<Fragment>
			<SmallPanel title="Praxen" add={() => setAddMedicalPractice(true)}>
				<ResourceList entries={entries} editItem={setMedicalPracticeId} deleteItem={deleteMedicalPractice} notDeletable={notDeletable} jumpToItem={jumpToMedicalPractice} />
			</SmallPanel>
			{addMedicalPractice && <MedicalPracticeChooser medicalPracticeId={medicalPracticeId} setMedicalPracticeId={setMedicalPracticeId} exclude={medicalPractices.map((entry) => entry.id)} close={() => setAddMedicalPractice(false)} />}
			{medicalPracticeId > 0 && <EditPerson cancel={() => setMedicalPracticeId(0)} personId={personId} isAdmin={isAdmin()} doesEmergencyService={doesEmergencyService()} medicalPracticeId={medicalPracticeId} />}
		</Fragment>
	);
};

export default MedicalPractices;
