import { Fragment, useState } from "react";

import styles from "./SmallPanel.module.css";

import { ReactComponent as Add } from "../../assets/images/icon_plus.svg";
import { ReactComponent as GoTo } from "../../assets/images/icon_goTo.svg";
import { ReactComponent as ArrowDown } from "../../assets/images/icon_chevron_down.svg";
import { ReactComponent as ArrowRight } from "../../assets/images/icon_chevron_right.svg";
import { ReactComponent as Expand } from "../../assets/images/expand.svg";

// mode: fix, closable, shrink

const SmallPanel = ({ title, children, add = null, classNames = [], opened = null, hover = null, jump }) => {
	const [open, setOpen] = useState(opened);
	const classes = [styles.smallPanel, ...classNames];

	const toggle = () => {
		setOpen((prev) => !prev);
	};

	const mouseEnter = () => {
		if (hover) {
			hover(true);
		}
	};

	const mouseLeave = () => {
		if (hover) {
			hover(false);
		}
	};

	let headerTitle = title;
	if (hover) {
		classes.push(styles.expand);
		headerTitle = (
			<Fragment>
				<div>{title}</div>
				<Expand />
			</Fragment>
		);
	} else if (open !== null) {
		headerTitle = (
			<span className={styles.toggleHeader} onClick={toggle}>
				{open ? <ArrowDown /> : <ArrowRight />}
				{title}
			</span>
		);
	}

	return (
		<div className={classes.join(" ")} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
			<header>
				{headerTitle}
				{add && <Add className={styles.add} onClick={add} />}
				{jump && <GoTo className={styles.jump} onClick={jump} />}
			</header>
			{(open === null || open) && children}
		</div>
	);
};

export default SmallPanel;
