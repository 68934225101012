import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SmallPanel from "../../../../../hoc/SmallPanel/SmallPanel";
import EditEMailAddress from "../../../../ResourceEditors/EditEMailAddress/EditEMailAddress";
import EmailAddress from "../../../../../ui/EmailAddress/EmailAddress";
import ResourceRow from "../../../../../ui/ResourceRow/ResourceRow";
import ResourceList from "../../../../../ui/List/ResourceList/ResourceList";

import * as askTypes from "../../../../../shared/askTypes";
import { addEMail, deleteEMail, updateEMail } from "../../../../../features/person/personSlice";
import { ask, clear } from "../../../../../features/ask/askSlice";

const EMailAddresses = ({ personId }) => {
	const dispatch = useDispatch();
	const [editEmailId, setEditEmailId] = useState(null);
	const eMails = useSelector((state) => state.person.person.eMails);
	const answer = useSelector((state) => state.ask.answer);

	useEffect(() => {
		if (answer) {
			if (answer.type === askTypes.DELETE_EMAIL && answer.value !== null) {
				dispatch(deleteEMail({ personId, id: answer.value }));
				dispatch(clear());
			}
		}
	}, [answer, dispatch, personId]);

	const removeEMail = (e, id) => {
		if (e.altKey) {
			dispatch(deleteEMail({ personId, id }));
		} else {
			const eMail = eMails.find((entry) => entry.id === id)?.eMail || "?";
			dispatch(
				ask({
					type: askTypes.DELETE_EMAIL,
					title: `E-Mail Adresse löschen`,
					question: `Möchtest Du die E-Mail Adresse «${eMail}» wirklich löschen?`,
					buttons: [
						{ label: "Ja, löschen", answer: id },
						{ label: "Abbrechen", answer: null }
					]
				})
			);
		}
	};

	const save = (data) => {
		if (data.id) {
			dispatch(updateEMail({ id: personId, data }));
		} else {
			dispatch(addEMail({ id: personId, data }));
		}
	};

	const getData = () => {
		return eMails.find((entry) => entry.id === editEmailId);
	};

	const entries = eMails.map((eMail) => ({
		id: eMail.id,
		name: (
			<ResourceRow label={eMail.label || "Privat"} labelColor={eMail.labelColor} icon="eMail" auth={eMail.auth}>
				<EmailAddress eMail={eMail.eMail} />
			</ResourceRow>
		)
	}));

	const notDeletable = eMails.filter((entry) => entry.auth).map((entry) => entry.id);

	return (
		<Fragment>
			<SmallPanel title="E-Mail Adressen" add={() => setEditEmailId(0)}>
				<ResourceList entries={entries} editItem={setEditEmailId} deleteItem={removeEMail} notDeletable={notDeletable} />
			</SmallPanel>
			{editEmailId !== null && <EditEMailAddress labelPlaceholder="Privat" cancel={() => setEditEmailId(null)} id={editEmailId} data={getData()} save={save} />}
		</Fragment>
	);
};

export default EMailAddresses;
