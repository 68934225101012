import { Fragment, useMemo } from "react";
import { useSelector } from "react-redux";
import { longDayNames } from "../../../../shared/globals";
import { dateToInt, hasWhiteText, getDateObjectFromMySqlDate } from "../../../../shared/functions";
import Day from "./Day/Day";
import styles from "./Calendar.module.css";
import { useSearchParams } from "react-router-dom";

const createTimeEntry = (id, date, serviceTemplate, serviceTemplateColor) => {
    const serviceCoverCountDays = serviceTemplate.start < serviceTemplate.end ? 1 : 2; // Der Dienst geht über Mitternacht ---> 2 Tage
    const start = getDateObjectFromMySqlDate(date);
    const end = new Date(start.getTime());
    end.setDate(end.getDate() + serviceCoverCountDays - 1);
    const startDate = dateToInt(start);
    const endDate = dateToInt(end);

    start.setHours(Math.floor(serviceTemplate.start));
    start.setMinutes(Math.round((serviceTemplate.start % 1) * 60));
    end.setHours(Math.floor(serviceTemplate.end));
    end.setMinutes(Math.round((serviceTemplate.end % 1) * 60));

    return {
        id,
        startDate,
        endDate,
        serviceTemplateId: serviceTemplate.id,
        start: serviceTemplate.start,
        end: serviceTemplate.end,
        color: "#" + serviceTemplateColor,
        whiteText: hasWhiteText(serviceTemplateColor),
        startTimeStamp: start.getTime(),
        endTimeStamp: end.getTime()
    };
};

const getInsertPos = (multiDays, date) => {
    let pos = 0;
    if (multiDays.length) {
        const entries = multiDays.filter((entry) => entry.startDate <= date && entry.endDate >= date).sort((a, b) => a.pos - b.pos);
        if (entries.length > 0) {
            entries.every((theDay, index) => {
                if (theDay.pos !== index) {
                    pos = index;
                    return false;
                } else {
                    pos++;
                    return true;
                }
            });
        }
    }
    return pos;
};

//const now = new Date().setHours(0, 0, 0, 0).valueOf();
const Calendar = ({ rosterDays, rosterPeriodeStart, rosterPeriodeEnd, publicHolidays }) => {
    const serviceTemplates = useSelector((state) => state.serviceTemplate.entries);
    const colors = useSelector((state) => state.values.color);
    const header = [];
    const calendar = [];
    const [searchParams] = useSearchParams();

    const year = searchParams.get("jahr");
    const month = searchParams.get("monat") - 1;

    const multiDays = useMemo(() => {
        let logEntry = false;
        const result = [];
        const groups = [];
        rosterDays.forEach((rosterDay) => {
            rosterDay.services.forEach((service) => {
                const serviceTemplate = serviceTemplates.find((template) => template.id === service.serviceTemplateId);
                if (!serviceTemplate) {
                    debugger;
                }
                const serviceCoverCountDays = serviceTemplate.start < serviceTemplate.end ? 1 : 2; // Der Dienst geht über Mitternacht ---> 2 Tage
                const serviceTemplateColor = serviceTemplate ? colors.find((entry) => entry.id === serviceTemplate.colorId).color : "ffffff";

                const start = new Date(rosterDay.start);
                const end = new Date(start.getTime());
                end.setDate(end.getDate() + serviceCoverCountDays - 1);
                const startDate = dateToInt(start);
                const endDate = dateToInt(end);

                start.setHours(Math.floor(serviceTemplate.start));
                start.setMinutes(Math.round((serviceTemplate.start % 1) * 60));
                end.setHours(Math.floor(serviceTemplate.end));
                end.setMinutes(Math.round((serviceTemplate.end % 1) * 60));

                if (service.groupId !== null) {
                    // If groups already contains id of service, the service is already handled and nothing has to be done
                    if (!groups.includes(service.groupId)) {
                        logEntry = true;
                        groups.push(service.groupId);
                        const groupServices = rosterDays.reduce((services, day) => {
                            const addServices = day.services.filter((serviceEntries) => serviceEntries.groupId === service.groupId);
                            if (addServices.length > 0) {
                                services = [
                                    ...services,
                                    ...addServices.map((newService) => {
                                        const serviceTemplate = serviceTemplates.find((template) => template.id === newService.serviceTemplateId);
                                        const serviceTemplateColor = serviceTemplate ? colors.find((entry) => entry.id === serviceTemplate.colorId).color : "ffffff";
                                        return createTimeEntry(newService.id, day.date, serviceTemplate, serviceTemplateColor);
                                    })
                                ];
                            }
                            return services;
                        }, []);
                        const startEndDate = groupServices.reduce(
                            (startEndDate, service) => {
                                const values = { ...startEndDate };
                                if (!values.startDate || values.startDate > service.startDate) {
                                    values.startDate = service.startDate;
                                }
                                if (!values.endDate || values.endDate < service.endDate) {
                                    values.endDate = service.endDate;
                                }
                                return values;
                            },
                            { startDate: null, endDate: null }
                        );
                        const insertPos = getInsertPos(result, startEndDate.startDate);
                        result.push({
                            id: service.id,
                            bookedFrom: service.bookedFrom,
                            groupId: service.groupId,
                            startDate: startEndDate.startDate,
                            endDate: startEndDate.endDate,
                            type: service.type,
                            times: [...groupServices],
                            pos: insertPos
                        });
                    }
                } else {
                    logEntry = true;
                    const insertPos = getInsertPos(result, startDate);
                    //debugger;
                    result.push({
                        id: service.id,
                        bookedFrom: service.bookedFrom,
                        groupId: null,
                        startDate: startDate,
                        endDate: endDate,
                        type: service.type,
                        times: [createTimeEntry(service.id, rosterDay.date, serviceTemplate, serviceTemplateColor)],
                        pos: insertPos
                    });
                }
            });
            if (logEntry) {
                //console.log(result);
                logEntry = false;
            }
        });
        return result;
    }, [colors, rosterDays, serviceTemplates]);

    const displayStartDate = new Date(year, month, 1);
    let firstDay = displayStartDate.getDay();
    firstDay = firstDay ? firstDay - 1 : 6;
    displayStartDate.setDate(displayStartDate.getDate() - firstDay);

    longDayNames.forEach((day) => {
        header.push(<div key={day}>{day}</div>);
    });

    rosterDays.forEach((rosterDay) => {
        if (rosterDay.start >= displayStartDate.valueOf() && calendar.length < 42) {
            const date = new Date(rosterDay.start);
            const currentMonth = date.getMonth();
            const disabled = currentMonth !== month || rosterDay.start < rosterPeriodeStart.valueOf() || rosterDay.start > rosterPeriodeEnd.valueOf();
            //const locked = rosterDay.start <= now;
            const publicHoliday = publicHolidays ? publicHolidays.find((entry) => entry.date === rosterDay.date) : undefined;
            const dateInt = dateToInt(date);
            const entries = multiDays.filter((entry) => entry.startDate <= dateInt && entry.endDate >= dateInt);
            calendar.push(<Day key={rosterDay.start} date={date} dateInt={dateInt} entries={entries} disabled={disabled} publicHoliday={publicHoliday} />);
        }
    });

    return (
        <Fragment>
            <div className={styles.header}>{header}</div>
            <div className={styles.month}>{calendar}</div>
        </Fragment>
    );
};

export default Calendar;
