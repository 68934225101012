import SideMenuItem from "./SideMenuItem/SideMenuItem";
import styles from "./SideMenu.module.css";

const SideMenu = ({ menus, baseUrl }) => {
	return (
		<ul className={styles.menu}>
			{menus.map((menu) => (
				<SideMenuItem key={menu.path} name={menu.name} baseUrl={baseUrl} url={menu.path} />
			))}
		</ul>
	);
};

export default SideMenu;
