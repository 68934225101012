import { Fragment, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { longDayNames } from "../../../../shared/globals";
import { dateToInt, hasWhiteText, timeToFloat } from "../../../../shared/functions";
//getDateObjectFromMySqlDate
import Day from "./Day/Day";
import styles from "./Calendar.module.css";
import { useSearchParams } from "react-router-dom";

const createTimeEntry = (id, date, serviceTemplate, serviceTemplateColor) => {
    const startTimeFloat = timeToFloat(serviceTemplate.startTime);
    const endTimeFloat = timeToFloat(serviceTemplate.endTime);
    const [startHour, startMinute] = serviceTemplate.startTime.split(":");
    const [endHour, endMinute] = serviceTemplate.endTime.split(":");

    const start = new Date(date);
    const end = new Date(start.getTime());
    if (startTimeFloat > endTimeFloat) {
        end.setDate(end.getDate() + 1);
    }
    const startDate = dateToInt(start);
    const endDate = dateToInt(end);

    start.setHours(startHour);
    start.setMinutes(startMinute);
    end.setHours(endHour);
    end.setMinutes(endMinute);
    const startTimeStamp = start.getTime();
    const endTimeStamp = end.getTime();

    return {
        id,
        startDate,
        endDate,
        serviceTemplateId: serviceTemplate.id,
        start: startTimeFloat,
        end: endTimeFloat,
        color: "#" + serviceTemplateColor,
        whiteText: hasWhiteText(serviceTemplateColor),
        startTimeStamp,
        endTimeStamp
    };
};

const getInsertPos = (multiDays, date) => {
    let pos = 0;
    if (multiDays.length) {
        const entries = multiDays.filter((entry) => entry.startDate <= date && entry.endDate >= date).sort((a, b) => a.pos - b.pos);
        if (entries.length > 0) {
            entries.every((theDay, index) => {
                if (theDay.pos !== index) {
                    pos = index;
                    return false;
                } else {
                    pos++;
                    return true;
                }
            });
        }
    }
    return pos;
};

const now = new Date().setHours(0, 0, 0, 0).valueOf();

const Calendar = ({ rosterDays, rosterPeriodeStart, rosterPeriodeEnd, publicHolidays, group, actions }) => {
    const [hoverServiceId, setHoverServiceId] = useState(0);
    const [hoverTimeBar, setHoverTimeBar] = useState(0);
    const serviceTemplates = useSelector((state) => state.serviceTemplate.entries);
    const colors = useSelector((state) => state.values.color);
    const header = [];
    const calendar = [];
    const [searchParams] = useSearchParams();

    const year = searchParams.get("jahr");
    const month = searchParams.get("monat") - 1;

    useEffect(() => {
        setHoverTimeBar(0);
    }, [group]);

    const multiDays = useMemo(() => {
        let logEntry = false;
        const result = [];
        const groups = [];
        rosterDays.forEach((rosterDay) => {
            rosterDay.services.forEach((service) => {
                if (group && group.entries.find((entry) => entry.id === service.id)) {
                    // If groups already contains id of service, the service is already handled and nothing has to be done
                    if (!groups.includes(group.groupId)) {
                        groups.push(group.groupId);

                        const groupServices = rosterDays.reduce((services, day) => {
                            const serviceIds = group.entries.map((entry) => entry.id);
                            const addServices = day.services.filter((serviceEntries) => serviceIds.includes(serviceEntries.id));
                            if (addServices.length > 0) {
                                services = [
                                    ...services,
                                    ...addServices.map((newService) => {
                                        const serviceTemplate = serviceTemplates.find((template) => template.id === newService.serviceTemplateId);
                                        const serviceTemplateColor = serviceTemplate ? colors.find((entry) => entry.id === serviceTemplate.colorId).color : "ffffff";
                                        return createTimeEntry(newService.id, day.date, serviceTemplate, serviceTemplateColor);
                                    })
                                ];
                            }
                            return services;
                        }, []);
                        const startEndDate = groupServices.reduce(
                            (startEndDate, service) => {
                                const values = { ...startEndDate };
                                if (!values.startDate || values.startDate > service.startDate) {
                                    values.startDate = service.startDate;
                                }
                                if (!values.endDate || values.endDate < service.endDate) {
                                    values.endDate = service.endDate;
                                }
                                return values;
                            },
                            { startDate: null, endDate: null }
                        );
                        const insertPos = getInsertPos(result, startEndDate.startDate);

                        result.push({
                            id: service.id,
                            groupId: group.groupId,
                            startDate: startEndDate.startDate,
                            endDate: startEndDate.endDate,
                            type: service.type,
                            times: [...groupServices],
                            pos: insertPos
                        });
                    }
                } else if (service.groupId !== null && !(group && group.groupId === service.groupId)) {
                    // If groups already contains id of service, the service is already handled and nothing has to be done
                    if (!groups.includes(service.groupId)) {
                        logEntry = true;
                        groups.push(service.groupId);
                        const groupServices = rosterDays.reduce((services, day) => {
                            const addServices = day.services.filter((serviceEntries) => serviceEntries.groupId === service.groupId);
                            if (addServices.length > 0) {
                                services = [
                                    ...services,
                                    ...addServices.map((newService) => {
                                        const serviceTemplate = serviceTemplates.find((template) => template.id === newService.serviceTemplateId);
                                        const serviceTemplateColor = serviceTemplate ? colors.find((entry) => entry.id === serviceTemplate.colorId).color : "ffffff";
                                        return createTimeEntry(newService.id, day.date, serviceTemplate, serviceTemplateColor);
                                    })
                                ];
                            }
                            return services;
                        }, []);
                        const startEndDate = groupServices.reduce(
                            (startEndDate, service) => {
                                const values = { ...startEndDate };
                                if (!values.startDate || values.startDate > service.startDate) {
                                    values.startDate = service.startDate;
                                }
                                if (!values.endDate || values.endDate < service.endDate) {
                                    values.endDate = service.endDate;
                                }
                                return values;
                            },
                            { startDate: null, endDate: null }
                        );
                        const insertPos = getInsertPos(result, startEndDate.startDate);
                        result.push({
                            id: service.id,
                            groupId: service.groupId,
                            startDate: startEndDate.startDate,
                            endDate: startEndDate.endDate,
                            type: service.type,
                            times: [...groupServices],
                            pos: insertPos
                        });
                    }
                } else {
                    const serviceTemplate = serviceTemplates.find((template) => template.id === service.serviceTemplateId);
                    if (!serviceTemplate) {
                        debugger;
                    }
                    const serviceTemplateColor = serviceTemplate ? colors.find((entry) => entry.id === serviceTemplate.colorId).color : "ffffff";

                    const start = new Date(rosterDay.start);
                    const end = new Date(start.getTime());
                    if (timeToFloat(serviceTemplate.startTime) > timeToFloat(serviceTemplate.endTime)) {
                        end.setDate(end.getDate() + 1);
                    }
                    const startDate = dateToInt(start);
                    const endDate = dateToInt(end);

                    // start.setHours(Math.floor(serviceTemplate.start));
                    // start.setMinutes(Math.round((serviceTemplate.start % 1) * 60));
                    // end.setHours(Math.floor(serviceTemplate.end));
                    // end.setMinutes(Math.round((serviceTemplate.end % 1) * 60));

                    logEntry = true;
                    const insertPos = getInsertPos(result, startDate);
                    result.push({
                        id: service.id,
                        groupId: null,
                        startDate: startDate,
                        endDate: endDate,
                        type: service.type,
                        times: [createTimeEntry(service.id, rosterDay.date, serviceTemplate, serviceTemplateColor)],
                        pos: insertPos
                    });
                }
            });
            if (logEntry) {
                //console.log(result);
                logEntry = false;
            }
        });
        return result;
    }, [colors, group, rosterDays, serviceTemplates]);

    const displayStartDate = new Date(year, month, 1);
    let firstDay = displayStartDate.getDay();
    firstDay = firstDay ? firstDay - 1 : 6;
    displayStartDate.setDate(displayStartDate.getDate() - firstDay);

    longDayNames.forEach((day) => {
        header.push(<div key={day}>{day}</div>);
    });

    rosterDays.forEach((rosterDay) => {
        if (rosterDay.start >= displayStartDate.valueOf() && calendar.length < 42) {
            const date = new Date(rosterDay.start);
            const currentMonth = date.getMonth();
            const disabled = currentMonth !== month || date.valueOf() < rosterPeriodeStart.valueOf() || date.valueOf() > rosterPeriodeEnd.valueOf();
            const locked = rosterDay.start <= now;
            const publicHoliday = publicHolidays ? publicHolidays.find((entry) => entry.date === rosterDay.date) : undefined;
            const dateInt = dateToInt(date);

            const entries = multiDays.filter((entry) => entry.startDate <= dateInt && entry.endDate >= dateInt);
            calendar.push(
                <Day
                    key={date.valueOf()}
                    date={date}
                    dateInt={dateInt}
                    entries={entries}
                    disabled={disabled}
                    hoverServiceId={hoverServiceId}
                    setHoverServiceId={setHoverServiceId}
                    hoverTimeBar={hoverTimeBar}
                    setHoverTimeBar={setHoverTimeBar}
                    publicHoliday={publicHoliday}
                    group={group}
                    actions={actions}
                    locked={locked}
                />
            );
        }
    });

    return (
        <Fragment>
            <div className={styles.header}>{header}</div>
            <div className={styles.month}>{calendar}</div>
        </Fragment>
    );
};

export default Calendar;
