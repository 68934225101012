import CurrentCategory from "../../components/PanelHeadSelectors/CurrentCategory/CurrentCategory";
import Error from "../../ui/Error";

import { ReactComponent as Close } from "../../assets/images/icon_close.svg";

import styles from "./Panel.module.css";
import TabHeader from "./TabHeader/TabHeader";
import PanelHeadRosterState from "../../components/PanelHeadSelectors/PanelHeadRosterState/PanelHeadRosterState";

const Panel = ({ title, size = "", className = null, padding = false, tabs = null, categorieLevels = 0, maxRequiredLevel = null, errors = false, close = null, empty = false, notes = null, children }) => {
    const classNames = [styles.panel];
    size !== "" && classNames.push(styles[size]);
    className !== null && classNames.push(className);

    const contentClassNames = [];

    const currentCategory = categorieLevels > 0 && (
        <nav>
            <CurrentCategory levels={categorieLevels} maxRequiredLevel={maxRequiredLevel} />
            {(categorieLevels === 4 || maxRequiredLevel === 4) && <PanelHeadRosterState />}
        </nav>
    );

    if (!empty) {
        contentClassNames.push(styles.content);
        padding && contentClassNames.push(styles.padding);
    }
    return (
        <div className={classNames.join(" ")}>
            {tabs ? (
                <TabHeader tabs={tabs}>{currentCategory}</TabHeader>
            ) : (
                <header className={styles.header}>
                    {close && (
                        <div className={styles.close} onClick={() => close()}>
                            <Close />
                        </div>
                    )}
                    <div className={styles.title + (close ? " " + styles.moveRight : "")}>{title}</div>
                    {currentCategory}
                    {notes && <div className={styles.note}>{notes}</div>}
                </header>
            )}
            {errors && <Error />}
            {empty ? children : <div className={contentClassNames.join(" ")}>{children}</div>}
        </div>
    );
};

export default Panel;
