import { ReactComponent as Trash } from "../../../assets/images/icon_trash.svg";
import { ReactComponent as Edit } from "../../../assets/images/icon_edit.svg";
import { ReactComponent as Locked } from "../../../assets/images/icon_lock.svg";

import styles from "./EditableListItem.module.css";
import ColorIcon from "../../ColorIcon/ColorIcon";
import Count from "../../../ui/Count/Count";

const EditableListItem = ({ id, name, colorId, changeColor, selected, select, edit, deleteItem = null, disabled, usedCount = null, locked }) => {
	const showColor = colorId !== null && colorId !== undefined;
	return selected ? (
		<li className={styles.selected}>
			<div className={styles.text}>
				{showColor && <ColorIcon colorId={colorId} action={() => changeColor(id)} />}
				<span>
					{locked && <Locked />}
					{name}
					{usedCount !== null ? <Count>{usedCount}</Count> : ""}
				</span>
			</div>
			<div className={styles.icons}>
				{deleteItem && <Trash className={styles.icon} onClick={() => deleteItem(id)} title={name + " löschen..."} />}
				{edit && <Edit className={styles.icon} onClick={() => edit(id)} title={name + " bearbeiten..."} />}
			</div>
		</li>
	) : (
		<li onClick={disabled ? null : () => select(id)} className={disabled ? styles.disabled : styles.selectable}>
			<div className={styles.text}>
				{showColor && <ColorIcon colorId={colorId} />}
				<span>
					{locked && <Locked />}
					{name}
					{usedCount !== null ? <Count>{usedCount}</Count> : ""}
				</span>
			</div>
		</li>
	);
};

export default EditableListItem;
