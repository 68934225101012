import ListItem from "./ListItem/ListItem";

import styles from "./List.module.css";

const List = ({ items, select, selectedId, over = null }) => {
	return (
		<ul className={styles.list}>
			{items.map((entry) => (
				<ListItem key={entry.id} id={entry.id} value={entry.name} colorId={entry.colorId} select={select} selected={selectedId === entry.id} over={over} />
			))}
		</ul>
	);
};

export default List;
