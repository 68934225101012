import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addAreaOfExpertise } from "../../../../../../features/person/personSlice";
import Panel from "../../../../../../hoc/Panel/Panel";
import Window from "../../../../../../hoc/Window/Window";
import SearchFilter from "../../../../../SearchFilter/SearchFilter";

import styles from "./AddAreaOfExpertise.module.css";
import FooterButtons from "../../../../../../ui/FooterButtons/FooterButtons";

const AddAreaOfExpertise = ({ id, cancel, usedIds }) => {
	const [selected, setSelected] = useState(0);
	const [filterText, setFilterText] = useState("");
	const areasOfExpertise = useSelector((state) => state.values.areasOfExpertise);

	const dispatch = useDispatch();

	const addArea = () => {
		dispatch(addAreaOfExpertise({ id, data: { areasOfExpertiseId: selected } }));
		cancel();
	};

	return (
		<Window>
			<Panel title="Spezialgebiet hinzufügen" size="small">
				<div className={styles.search}>
					<SearchFilter filterText={filterText} setFilterText={setFilterText} focusOnLoad />
				</div>
				<ul className={styles.list}>
					{areasOfExpertise
						.filter((entry) => !usedIds.includes(entry.id) && (filterText.length < 1 || entry.name.toLowerCase().includes(filterText.toLowerCase())))
						.map((entry) => (
							<li key={entry.id} onClick={() => setSelected(entry.id)} onDoubleClick={() => addArea()} className={selected === entry.id ? styles.selected : ""}>
								{entry.name}
							</li>
						))}
				</ul>
				<FooterButtons padding>
					<button type="button" onClick={() => cancel()}>
						Abbrechen
					</button>
					<button type="button" onClick={() => addArea()} disabled={!selected}>
						OK
					</button>
				</FooterButtons>
			</Panel>
		</Window>
	);
};

export default AddAreaOfExpertise;
