import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ask, clear } from "../../../../../features/ask/askSlice";

import SmallPanel from "../../../../../hoc/SmallPanel/SmallPanel";
import Phone from "../../../../../ui/Phone/Phone";
import EditPhone from "../../../../ResourceEditors/EditPhone/EditPhone";
import ResourceRow from "../../../../../ui/ResourceRow/ResourceRow";
import ResourceList from "../../../../../ui/List/ResourceList/ResourceList";

import { addPhone, updatePhone, deletePhone } from "../../../../../features/person/personSlice";
import * as askTypes from "../../../../../shared/askTypes";
import { phoneTypes } from "../../../../../shared/globals";

const Phones = ({ personId }) => {
	const dispatch = useDispatch();
	const [editPhoneId, setEditPhoneId] = useState(null);
	const phones = useSelector((state) => state.person.person.phones);
	const answer = useSelector((state) => state.ask.answer);

	useEffect(() => {
		if (answer) {
			if (answer.type === askTypes.DELETE_PHONE && answer.value !== null) {
				dispatch(deletePhone({ personId, id: answer.value }));
				dispatch(clear());
			}
		}
	}, [answer, dispatch, personId]);

	const removePhone = (e, id) => {
		if (e.altKey) {
			dispatch(deletePhone({ personId, id }));
		} else {
			const thePhone = phones.find((entry) => entry.id === id);
			const type = thePhone.type === "mobile" ? "Mobilenummer" : thePhone.type === "fax" ? "Faxnummer" : "Telefonnummer";

			dispatch(
				ask({
					type: askTypes.DELETE_PHONE,
					title: `${type} löschen`,
					question: `Möchtest Du die ${type} «${thePhone.number}» wirklich löschen?`,
					buttons: [
						{ label: "Ja, löschen", answer: id },
						{ label: "Abbrechen", answer: null }
					]
				})
			);
		}
	};

	const getData = () => {
		return phones.find((entry) => entry.id === editPhoneId);
	};

	const save = (data) => {
		if (data.id) {
			dispatch(updatePhone({ id: personId, data }));
		} else {
			dispatch(addPhone({ id: personId, data }));
		}
	};

	const entries = phones.map((phone) => ({
		id: phone.id,
		name: (
			<ResourceRow label={phone.label || `${phoneTypes[phone.type]} Privat`} labelColor={phone.labelColor} icon={phone.type} secret={phone.secret}>
				<Phone phone={phone} />
			</ResourceRow>
		)
	}));

	return (
		<Fragment>
			<SmallPanel title="Telefon & Fax" add={() => setEditPhoneId(0)}>
				<ResourceList entries={entries} editItem={setEditPhoneId} deleteItem={removePhone} />
			</SmallPanel>
			{editPhoneId !== null && <EditPhone labelPlaceholder="Privat" cancel={() => setEditPhoneId(null)} id={editPhoneId} data={getData()} save={save} />}
		</Fragment>
	);
};

export default Phones;
