import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const RequireNoAuth = () => {
	const authenticated = useSelector((state) => state.auth.authenticated);
	const location = useLocation();
	return !authenticated ? <Outlet /> : <Navigate to="/" state={{ from: location }} replace />;
};

export default RequireNoAuth;
