import { Virtuoso } from "react-virtuoso";

import TableHeader from "./TableHeader/TableHeader";
import TableRow from "./TableRow/TableRow";

import styles from "./SortableTable.module.css";
import { useState } from "react";

const SortableTable = ({ fields, data, orderBy, changeOrder, rowClickAction = null, hideHeader = false, resultInfo, tableStyle = null, groups }) => {
	const [showGroup, setShowGroup] = useState(groups ? groups[0].key : null);

	const showErrorCol = data[0]?.error !== undefined;
	const classNames = [styles.content];
	tableStyle && classNames.push(tableStyle);

	const getSortValue = (value) => {
		return value === null ? "" : value.trim().toLowerCase();
	};

	const sortedData = data.sort((a, b) => {
		const field = fields.find((field) => field.key === orderBy.key);
		if (field && field.sort !== undefined && typeof field.sort === "function") {
			return field.sort(a, b, orderBy.order);
		} else if (orderBy.key === "error") {
			const field1 = a[orderBy.key];
			const field2 = b[orderBy.key];
			const fieldA = field1 ? "a" : "b";
			const fieldB = field2 ? "a" : "b";
			return orderBy.order === "asc" ? fieldA.localeCompare(fieldB) : fieldB.localeCompare(fieldA);
			//return orderBy.order === "asc" ? a[orderBy.key] - b[orderBy.key] : b[orderBy.key] - a[orderBy.key];
		} else {
			const type = field?.type;
			const field1 = a[orderBy.key];
			const field2 = b[orderBy.key];
			switch (type) {
				case "number":
					return orderBy.order === "asc" ? field1 - field2 : field2 - field1;

				case "array":
					return orderBy.order === "asc" ? field1.length - field2.length : field2.length - field1.length;

				case "boolean":
					const fieldA = field1 ? "a" : "b";
					const fieldB = field2 ? "a" : "b";
					return orderBy.order === "asc" ? fieldA.localeCompare(fieldB) : fieldB.localeCompare(fieldA);

				default:
					const fieldC = getSortValue(field1);
					const fieldD = getSortValue(field2);
					return orderBy.order === "asc" ? fieldC.localeCompare(fieldD) : fieldD.localeCompare(fieldC);
			}
		}
	});

	const showFields = groups ? fields.filter((field) => !field.group || field.group === showGroup) : fields;

	return (
		<div className={classNames.join(" ")}>
			{resultInfo && <div className={styles.resultInfo}>{resultInfo}</div>}
			{!hideHeader && <TableHeader fields={showFields} orderBy={orderBy} setOrderBy={changeOrder} showErrorCol={showErrorCol} groups={groups} setShowGroup={setShowGroup} showGroup={showGroup} />}
			<div className={styles.list}>
				<Virtuoso style={{ height: "100%" }} data={sortedData} itemContent={(index, row) => <TableRow index={index} row={row} fields={showFields} rowClickAction={rowClickAction} />} fixedItemHeight={34} increaseViewportBy={{ top: 500, bottom: 500 }} />
			</div>
		</div>
	);
};

export default SortableTable;
