import { useDispatch, useSelector } from "react-redux";
import styles from "./ShowPerson.module.css";

import { ReactComponent as DoctorIcon } from "../../../assets/images/icon_doctor.svg";
import { ReactComponent as Info } from "../../../assets/images/icon_info.svg";
import { ReactComponent as InfoDark } from "../../../assets/images/icon_infoDark.svg";

import { getPersonInformation, markPerson } from "../../../features/personInformation/personInformationSlice";

const ShowPerson = ({ id, hideName = false }) => {
    const persons = useSelector((state) => state.persons.entries);
    const marked = useSelector((state) => state.personInformation.marked);
    const dispatch = useDispatch();

    if (!persons) {
        return "...";
    }
    const user = persons.find((entry) => entry.id === id);

    if (!user) {
        return "???";
    }

    const classNames = [styles.user];
    user.id === marked && classNames.push(styles.marked);
    hideName && classNames.push(styles.noName);

    const showPersonInformation = (e) => {
        e.stopPropagation();
        dispatch(getPersonInformation(user.id));
    };

    const mark = (e) => {
        e.stopPropagation();
        dispatch(markPerson(user.id));
    };

    return (
        <div className={classNames.join(" ")}>
            <div className={styles.doctorIcon}>
                <DoctorIcon title={user.id} />
            </div>
            {!hideName && (
                <span>
                    {user.lastname}, {user.firstname}
                </span>
            )}
            <div className={styles.details}>
                <div className={styles.doctorIcon} onClick={mark}>
                    <DoctorIcon title={user.id} />
                </div>
                <span>
                    {user.lastname}, {user.firstname}
                </span>
                {user.id === marked ? <InfoDark className={styles.infoIcon} onClick={showPersonInformation} /> : <Info className={styles.infoIcon} onClick={showPersonInformation} />}
            </div>
        </div>
    );
};

export default ShowPerson;
