import React from "react";
import styles from "./TimeLegend.module.css";

const TimeLegend = () => {
	return (
		<div className={styles.timeLegend}>
			{Array(25)
				.fill(1)
				.map((x, y) => (
					<div key={y}>
						<span>{y}</span>
					</div>
				))}
		</div>
	);
};

export default TimeLegend;
