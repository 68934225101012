import { Fragment } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import TempSuccess from "../../ui/TempSuccess";
import TempError from "../../ui/TempError";
import Menu from "../../components/Menu/Menu";
import Spinner from "../../ui/Spinner";
import AerztefonLogo from "../../ui/AerztefonLogo";
import CategorySelector from "../../components/Category/CategorySelector";
import Ask from "../Ask/Ask";
import PersonInformation from "../../components/PersonInformation/PersonInformation";

const Layout = () => {
	const loading = useSelector((state) => state.app.loading);
	const showCategorySelector = useSelector((state) => state.category.selector.show);

	return (
		<Fragment>
			<TempSuccess />
			<TempError />
			<div id="topBar">
				{process.env.REACT_APP_ENV === "development" && <div className="development">DEV</div>}
				{process.env.REACT_APP_ENV === "test" && <div className="development">TEST</div>}
				<h1>Uranus-Administration</h1>
				<Menu />
				<AerztefonLogo />
			</div>
			<main>
				<Outlet />
			</main>
			{showCategorySelector && <CategorySelector />}
			<PersonInformation />
			<Ask />
			{loading && <Spinner />}
		</Fragment>
	);
};

export default Layout;
