import styles from "./FilterPanel.module.css";

const FilterPanel = ({ name, children }) => {
	return (
		<div className={styles.panel}>
			<header>{name}</header>
			<div>{children}</div>
		</div>
	);
};

export default FilterPanel;
