import styles from "./FlyingError.module.css";

const FlyingError = ({ error, children }) => {
	return (
		<div className={styles.flyingError}>
			{children}
			{error && <div className={styles.error}>{error}</div>}
		</div>
	);
};

export default FlyingError;
