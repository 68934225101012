import styles from "./CheckBox.module.css";

const CheckBox = ({ value, name, checked, set, disabled = false }) => {
	return (
		<label key={value} className={styles.label}>
			<input type="checkbox" value={value} checked={checked} onChange={(e) => set(e.target.checked, value)} disabled={disabled} />
			{name}
		</label>
	);
};

export default CheckBox;
