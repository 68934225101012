import { useDispatch, useSelector } from "react-redux";
import { answer } from "../../features/ask/askSlice";
import { ReactComponent as Warning } from "../../assets/images/icon_warning_red.svg";
import styles from "./Ask.module.css";

const Ask = () => {
	const ask = useSelector((state) => state.ask);
	const dispatch = useDispatch();

	if (!ask.title) {
		return null;
	}

	return (
		<div className={styles.ask}>
			<div>
				<div className={styles.content}>
					<Warning />
					<div>
						<h2>{ask.title}</h2>
						{ask.question}
					</div>
				</div>
				<div className={styles.buttons}>
					{ask.buttons.map((button) => (
						<button key={button.answer} onClick={() => dispatch(answer(button.answer))} type="button">
							{button.label}
						</button>
					))}
				</div>
			</div>
		</div>
	);
};

export default Ask;
