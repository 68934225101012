import React from "react";
import { useCallback, useRef } from "react";
import { GoogleMap } from "@react-google-maps/api";
import Areas from "./Areas/Areas";

const center = {
	lat: 47.41380881805016,
	lng: 8.540808717645255
};

const zoom = 11;

const options = {
	disableDefaultUI: true,
	clickableIcons: false,
	mapId: "4997ebcf2269de77"
};

const Map = ({ serviceCircles, mouseOverServiceCircle, locked }) => {
	const mapRef = useRef();
	const onLoad = useCallback((map) => (mapRef.current = map), []);

	return (
		<GoogleMap center={center} zoom={zoom} options={options} onLoad={onLoad} id="map">
			{/* <Marker position={center} icon="https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png" /> */}
			<Areas serviceCircles={serviceCircles} mouseOverServiceCircle={mouseOverServiceCircle} locked={locked} />
		</GoogleMap>
	);
};

export default React.memo(Map);
