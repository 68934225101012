import styles from "./TableHeader.module.css";

import { ReactComponent as Down } from "../../../assets/images/icon_chevron_down.svg";
import { ReactComponent as Up } from "../../../assets/images/icon_chevron_up.svg";
import { ReactComponent as ErrorIcon } from "../../../assets/images/icon_warning_red.svg";

const TableHeader = ({ orderBy, setOrderBy, fields, showErrorCol = true, groups, showGroup, setShowGroup }) => {
	const header = fields.map((field) => {
		if (field.notSortable) {
			return <th key={field.key}>{field.name}</th>;
		} else {
			const orderIcon = orderBy.order === "asc" ? <Up className={styles.sortIcon} /> : <Down className={styles.sortIcon} />;
			const classNames = [styles.sortable];
			orderBy.key === field.key && classNames.push(styles.activeSort);

			return (
				<th onClick={() => setOrderBy(field.key)} className={classNames.join(" ")} key={field.key}>
					<div>
						{field.name}
						{orderIcon}
					</div>
				</th>
			);
		}
	});
	if (showErrorCol) {
		const orderIcon = orderBy.order === "asc" ? <Up className={styles.sortIcon} /> : <Down className={styles.sortIcon} />;
		const classNames = [styles.error];
		orderBy.key === "error" && classNames.push(styles.activeSort);
		header.unshift(
			<th onClick={() => setOrderBy("error")} key="error" className={classNames.join(" ")}>
				<div>
					<ErrorIcon className={styles.errorIcon} />
					{orderIcon}
				</div>
			</th>
		);
	}

	const colspan = groups ? fields.filter((field) => field.group !== undefined).length : 0;

	return (
		<thead className={styles.header}>
			{groups && (
				<tr>
					{fields
						.filter((field) => !field.group)
						.map((field) => (
							<th key={field.key}></th>
						))}
					<th colSpan={colspan} className={styles.groupsCell}>
						<div className={styles.groups}>
							{groups.map((group) => (
								<div key={group.key} onClick={() => setShowGroup(group.key)} className={showGroup === group.key ? styles.selected : ""}>
									{group.name}
								</div>
							))}
						</div>
					</th>
				</tr>
			)}
			<tr className={styles.columnTitles}>{header}</tr>
		</thead>
	);
};

export default TableHeader;
