import SmallPanel from "../../../../../hoc/SmallPanel/SmallPanel";
import styles from "./Docbox.module.css";
import { useDispatch } from "react-redux";
import { docBoxChangesAccepted } from "../../../../../features/person/personSlice";

const Docbox = ({ docbox, personId }) => {
	const dispatch = useDispatch();

	if (!docbox) {
		return null;
	}

	const changesAccepted = () => {
		dispatch(docBoxChangesAccepted(personId));
	};

	return (
		<SmallPanel title="Docbox" opened={docbox.current !== null}>
			<ul className={styles.content}>
				<li className={styles.header}>
					<span>Feld</span>
					<div>Initialer Import</div>
					{docbox.current && <div>Geändert</div>}
				</li>
				{Object.keys(docbox.initial).map((key) => (
					<li key={key}>
						<span>{key === "id" ? "Docbox-Id" : key}</span>
						<div>{docbox.initial[key]}</div>
						{docbox.current && <div>{docbox.initial[key] !== docbox.current[key] ? <span className={styles.mark}>{docbox.current[key]}</span> : docbox.current[key]}</div>}
					</li>
				))}
				{docbox.current !== null && (
					<li className={styles.button}>
						<span></span>
						<div></div>
						<div>
							<button type="button" onClick={changesAccepted}>
								Ich habe die Änderungen übernommen
							</button>
						</div>
					</li>
				)}
			</ul>
		</SmallPanel>
	);
};

export default Docbox;
