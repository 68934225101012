import React from "react";
import styles from "./ButtonSelector.module.css";

// buttons: [{value: "", name: ""}]

const ButtonSelector = ({ buttons, selected, setSelected, monthCounts }) => {
    return (
        <div className={styles.buttons}>
            {buttons.map((button) => {
                const count = monthCounts && button.value in monthCounts ? monthCounts[button.value] : 0;
                return (
                    <div key={button.value} className={button.value === selected ? styles.selected : ""} onClick={() => setSelected(button.value)}>
                        <span>
                            {button.name}
                            {count > 0 && <div className={styles.count}>{count}</div>}
                        </span>
                    </div>
                );
            })}
        </div>
    );
};

export default ButtonSelector;
