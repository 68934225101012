import { useEffect } from "react";
import { useDispatch } from "react-redux";
import PublicHolidayCalendar from "../../components/PublicHolidays/PublicHolidayCalendar";
import YearSelector from "../../components/YearSelector/YearSelector";
import { getPublicHolidays } from "../../features/publicHoliday/publicHolidaySlice";
import Panel from "../../hoc/Panel/Panel";

import styles from "./PublicHolidays.module.css";
import { setSelectorMode } from "../../features/category/categorySlice";
import { useNavigate, useParams } from "react-router-dom";

import * as routes from "../../shared/routes";

const PublicHolidays = () => {
	const { year } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		if (year) {
			dispatch(getPublicHolidays(year));
		}
	}, [year, dispatch]);

	useEffect(() => {
		if (!year) {
			navigate(routes.PUBLIC_HOLIDAYS + "/" + new Date().getFullYear());
		}
	}, [year, navigate]);

	useEffect(() => {
		dispatch(setSelectorMode("choose"));
	}, [dispatch]);

	const setYear = (newYear) => {
		navigate(routes.PUBLIC_HOLIDAYS + "/" + newYear);
	};

	return year ? (
		<Panel size="maxWidth" title="Feiertage" categorieLevels={3} maxRequiredLevel={3} padding>
			<div className={styles.yearSelector}>
				<YearSelector year={parseInt(year, 10)} setYear={setYear} />
			</div>
			<PublicHolidayCalendar />
		</Panel>
	) : null;
};

export default PublicHolidays;
