import { useSelector } from "react-redux";
import styles from "./ColorIcon.module.css";

const ColorIcon = ({ colorId, action = null }) => {
	const colors = useSelector((state) => state.values.color);
	const color = colors.find((entry) => entry.id === colorId);
	const style = { backgroundColor: color ? "#" + color.color : "white" };
	const classNames = [styles.colorIcon];
	if (action) {
		classNames.push(styles.action);
	}
	return <div className={classNames.join(" ")} style={style} onClick={action ? () => action() : null}></div>;
};

export default ColorIcon;
