import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBillingPeriods } from "../../../../features/person/pointBalance/pointBalanceSlice";
import { useParams } from "react-router-dom";

//import styles from "./PointBalance.module.css";

const PointBalance = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const billingPeriods = useSelector((state) => state.pointBalance.billingPeriods);

    useEffect(() => {
        dispatch(getBillingPeriods(id));
    }, [dispatch, id]);

    if (billingPeriods === null) {
        return null;
    }

    return (
        <Fragment>
            <h2>Punktesaldo</h2>
            {billingPeriods.map((billingPeriod) => (
                <div id={billingPeriod.serviceCircleId}>
                    {billingPeriod.serviceCircle} {billingPeriod.name}
                </div>
            ))}
        </Fragment>
    );
};

export default PointBalance;
