import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../shared/axios";
import { logout } from "../auth/authSlice";
import { setCloseOnExit, setTempSuccess } from "../message/messageSlice";
import { clearRosterState } from "../roster/rosterSlice";

const namespace = "serviceCircle";

const initialState = {
	settings: null
};

export const getSettings = createAsyncThunk(`${namespace}/getSettings`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.get("serviceCircle/" + payload);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const addContingent = createAsyncThunk(`${namespace}/addContingent`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("serviceCircle/" + payload.id + "/contingents", payload.data);
		dispatch(setCloseOnExit());
		dispatch(clearRosterState());
		dispatch(setTempSuccess("Das Kontingent wurde erfolgreich gespeichert."));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const updateContingent = createAsyncThunk(`${namespace}/updateContingent`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.patch("serviceCircle/" + payload.serviceCircleId + "/contingents/" + payload.id, payload.data);
		dispatch(setCloseOnExit());
		dispatch(setTempSuccess("Das Kontingent wurde erfolgreich gespeichert."));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

const serviceCircleSlice = createSlice({
	name: namespace,
	initialState,
	reducers: {
		clearSettings: (state, action) => {
			return initialState;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getSettings.fulfilled, (state, { payload }) => {
				state.settings = payload;
			})
			.addCase(getSettings.rejected, (state, { payload }) => {
				state.settings = [];
			})
			.addCase(addContingent.fulfilled, (state, { payload }) => {
				state.settings.contingent.push(payload);
			})
			.addCase(updateContingent.fulfilled, (state, { payload }) => {
				state.settings.contingent = state.settings.contingent.map((row) => {
					if (row.id === payload.id) {
						return payload;
					} else {
						return row;
					}
				});
			})
			.addCase(logout.fulfilled, (state, { payload }) => {
				return initialState;
			});
	}
});

export const { clearSettings } = serviceCircleSlice.actions;

export default serviceCircleSlice.reducer;
