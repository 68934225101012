import { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import ContingentRow from "./ContingentRow/ContingentRow";
import EditContingentRow from "./EditContingentRow/EditContingentRow";

import styles from "./Contingent.module.css";
import FooterButtons from "../../../../ui/FooterButtons/FooterButtons";

const currentYear = new Date().getFullYear();

const newContingent = {
	id: 0,
	workDays: "",
	weekendDays: "",
	publicHoliDays: ""
};

const Contingent = () => {
	const { id } = useParams();
	const contingent = useSelector((state) => state.serviceCircle.settings.contingent);
	const [editId, setEditId] = useState(null);

	const nextYear = contingent.length > 0 ? Math.max(...contingent.map((entry) => entry.year)) + 1 : currentYear;

	const addContingent = () => {
		setEditId("0");
	};

	const cancelEdit = () => {
		setEditId(null);
	};

	return (
		<Fragment>
			<table className={styles.table}>
				<thead>
					<tr>
						<th>Jahr</th>
						<th>Werktage</th>
						<th>Wochenendtage</th>
						<th>Feiertage</th>
					</tr>
				</thead>
				<tbody>
					{contingent.map((row) => (editId === row.id ? <EditContingentRow key={row.id} serviceCircleId={id} row={row} cancel={cancelEdit} close={() => setEditId(null)} /> : <ContingentRow key={row.id} editable={currentYear <= row.year} row={row} editId={editId} setEditId={setEditId} />))}
					{editId === "0" && <EditContingentRow key="add" serviceCircleId={id} row={{ ...newContingent, year: nextYear }} cancel={cancelEdit} close={() => setEditId(null)} />}
				</tbody>
			</table>

			{editId === null && (
				<FooterButtons paddingTop>
					<button type="button" onClick={() => addContingent()}>
						Jahr hinzufügen
					</button>
				</FooterButtons>
			)}
		</Fragment>
	);
};

export default Contingent;
