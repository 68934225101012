import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axios from "../../shared/axios";
import { logout } from "../auth/authSlice";
import { createPerson, deletePerson, updatePerson, addAreaOfExpertise, deleteAreaOfExpertise, addPersonToServiceType, removePersonFromServiceType, docBoxChangesAccepted, updateEMail, deleteEMail, updatePhone, deletePhone, addPersonNote, updatePersonNote } from "../person/personSlice";

const namespace = "persons";

const initialState = {
	entries: null,
	filter: {
		search: "",
		errors: false,
		areaOfExpertise: 0,
		byServiceType: false,
		byServiceCircle: false,
		bySelector: null,
		nfdState: "",
		nfdStateDetails: ""
	},
	rosterPeriodDoctorIds: null,
	zipsOfServiceCircle: {
		serviceCircleId: null,
		zips: null
	},
	orderBy: null,
	reload: false
};

export const prepareZipsOfServiceCircle = createAction(`${namespace}/prepareZipsOfServiceCircle`);

export const getPersons = createAsyncThunk(`${namespace}/getPersons`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.get("persons");
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const getZipsOfServiceCircleFromRosterPeriod = createAsyncThunk(`${namespace}/getZipsOfServiceCircleFromRosterPeriod`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		dispatch(prepareZipsOfServiceCircle(payload.serviceCircleId));
		const { data } = await axios.get("geographyRoster/baseMap/" + payload.serviceCircleId + "/" + payload.rosterPeriodId + "/zips");
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const getPersonsOfRosterPeriod = createAsyncThunk(`${namespace}/getPersonsOfRosterPeriod`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.get("persons/rosterPeriod/" + payload);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

const personsSlice = createSlice({
	name: namespace,
	initialState,
	reducers: {
		setSearch: (state, { payload }) => {
			state.filter.search = payload;
		},
		filterBySelector: (state, { payload }) => {
			state.filter.bySelector = payload;
		},
		filterByNfdState: (state, { payload }) => {
			state.filter.nfdState = payload;
		},
		filterByNfdStateDetails: (state, { payload }) => {
			state.filter.nfdStateDetails = payload;
		},
		toggleFilter: (state, { payload }) => {
			state.filter[payload] = !state.filter[payload];
			if (payload === "byServiceType") {
				state.filter.bySelector = "category";
			}
			if (payload === "byServiceCircle") {
				if (state.filter[payload]) {
					state.filter.byServiceType = true;
				}
				state.filter.bySelector = "category";
			}
		},
		setAreaOfExpertise: (state, { payload }) => {
			state.filter.areaOfExpertise = payload;
		},
		clearPersons: (state) => {
			state.entries = null;
		},
		prepareZipsOfServiceCircle: (state, { payload }) => {
			state.zipsOfServiceCircle.serviceCircleId = payload;
			state.zipsOfServiceCircle.zips = null;
		},
		setOrderBy: (state, { payload }) => {
			state.orderBy = payload;
		},
		changeOrderBy: (state, { payload }) => {
			if (state.orderBy.key === payload) {
				state.orderBy.order = state.orderBy.order === "asc" ? "desc" : "asc";
			} else {
				state.orderBy.key = payload;
			}
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getPersons.fulfilled, (state, { payload }) => {
				state.entries = payload;
				state.reload = false;
			})
			.addCase(getZipsOfServiceCircleFromRosterPeriod.fulfilled, (state, { payload }) => {
				state.zipsOfServiceCircle.zips = payload.zips;
				if (payload.zips.length === 0) {
					state.filter.byServiceCircle = false;
				}
			})
			.addCase(getPersonsOfRosterPeriod.fulfilled, (state, { payload }) => {
				state.rosterPeriodDoctorIds = payload.personIds;
			})
			.addCase(deletePerson.fulfilled, (state, { payload }) => {
				state.entries = state.entries.filter((entry) => entry.id !== payload.id);
			})
			.addCase(updatePerson.fulfilled, (state) => {
				state.reload = true;
			})
			.addCase(createPerson.fulfilled, (state) => {
				state.reload = true;
			})
			.addCase(addAreaOfExpertise.fulfilled, (state) => {
				state.reload = true;
			})
			.addCase(deleteAreaOfExpertise.fulfilled, (state) => {
				state.reload = true;
			})
			.addCase(addPersonToServiceType.fulfilled, (state) => {
				state.reload = true;
			})
			.addCase(removePersonFromServiceType.fulfilled, (state) => {
				state.reload = true;
			})
			.addCase(docBoxChangesAccepted.fulfilled, (state) => {
				state.reload = true;
			})
			.addCase(updateEMail.fulfilled, (state) => {
				state.reload = true;
			})
			.addCase(deleteEMail.fulfilled, (state) => {
				state.reload = true;
			})
			.addCase(updatePhone.fulfilled, (state) => {
				state.reload = true;
			})
			.addCase(deletePhone.fulfilled, (state) => {
				state.reload = true;
			})
			.addCase(addPersonNote.fulfilled, (state) => {
				state.reload = true;
			})
			.addCase(updatePersonNote.fulfilled, (state) => {
				state.reload = true;
			})
			.addCase(logout.fulfilled, () => {
				return initialState;
			});
	}
});

export const { setSearch, filterBySelector, filterByNfdState, filterByNfdStateDetails, toggleFilter, setAreaOfExpertise, clearPersons, setOrderBy, changeOrderBy } = personsSlice.actions;

export default personsSlice.reducer;
