import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import SmallPanel from "../../../../../hoc/SmallPanel/SmallPanel";
import ResourceList from "../../../../../ui/List/ResourceList/ResourceList";

import PersonChooser from "../../../../PersonChooser/PersonChooser";
import EditPerson from "./EditPerson/EditPerson";

import { userTypes } from "../../../../../shared/globals";
import * as askTypes from "../../../../../shared/askTypes";
import { ask, clear } from "../../../../../features/ask/askSlice";
import { removePerson } from "../../../../../features/medicalPractice/medicalPracticeSlice";
import * as routes from "../../../../../shared/routes";

const Persons = ({ medicalPracticeId, users }) => {
	const [addPerson, setAddPerson] = useState(false);
	const [personId, setPersonId] = useState(0);
	const answer = useSelector((state) => state.ask.answer);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (answer) {
			if (answer.type === askTypes.REMOVE_PERSON_FROM_MEDICAL_PRACTICE && answer.value !== null) {
				dispatch(removePerson({ medicalPracticeId, personId: answer.value }));
				dispatch(clear());
			}
		}
	}, [answer, dispatch, medicalPracticeId]);

	const deletePerson = (e, personId) => {
		if (e.altKey) {
			dispatch(removePerson({ medicalPracticeId, personId }));
		} else {
			const user = users.find((entry) => entry.id === personId);
			dispatch(
				ask({
					type: askTypes.REMOVE_PERSON_FROM_MEDICAL_PRACTICE,
					title: `Person aus Praxis entfernen`,
					question: `Möchtest Du «${user.firstname} ${user.lastname}» wirklich aus der Praxis entfernen?`,
					buttons: [
						{ label: "Ja, entfernen", answer: personId },
						{ label: "Abbrechen", answer: null }
					]
				})
			);
		}
	};

	const jumpToPerson = (id) => {
		navigate(routes.PERSONS + "/" + id + routes.DOCTOR_ACCOUNT);
	};

	const entries = users.map((user) => ({
		id: user.id,
		name: `${user.firstname} ${user.lastname} (${userTypes[user.type]}${user.admin ? ", Administrator" : ""}${user.type === "doctor" && user.emergencyService ? ", Notfalldienst in dieser Praxis" : ""})`
	}));

	const isAdmin = () => {
		return users.find((entry) => entry.id === personId)?.admin || false;
	};

	const doesEmergencyService = () => {
		return users.find((entry) => entry.id === personId)?.emergencyService || false;
	};

	const notDeletable = users.filter((entry) => entry.emergencyService).map((entry) => entry.id);

	return (
		<Fragment>
			<SmallPanel title="Personen" add={() => setAddPerson(true)}>
				<ResourceList entries={entries} editItem={setPersonId} deleteItem={deletePerson} notDeletable={notDeletable} jumpToItem={jumpToPerson} />
			</SmallPanel>
			{addPerson && <PersonChooser personId={personId} setPersonId={setPersonId} exclude={users.map((entry) => entry.id)} close={() => setAddPerson(false)} />}
			{personId > 0 && <EditPerson cancel={() => setPersonId(0)} personId={personId} isAdmin={isAdmin()} doesEmergencyService={doesEmergencyService()} medicalPracticeId={medicalPracticeId} />}
		</Fragment>
	);
};

export default Persons;
