import { useState } from "react";
import { useDispatch } from "react-redux";
import { selectServiceCircle } from "../../features/category/categorySlice";
import SmallPanel from "../../hoc/SmallPanel/SmallPanel";
import List from "../List/List";

const ServiceCircleSelector = ({ serviceCircles, serviceCircleId, over = null }) => {
	const dispatch = useDispatch();
	const [expand, setExpand] = useState(false);

	const select = (id) => {
		dispatch(selectServiceCircle(id));
		setExpand(false);
	};

	const hover = (mouseOverPanel) => {
		setExpand(mouseOverPanel);
	};

	const showServiceCircles = expand || !serviceCircleId ? serviceCircles : serviceCircles.filter((entry) => entry.id === serviceCircleId);

	return (
		<SmallPanel title="Dienstkreise" hover={hover}>
			<List items={showServiceCircles} select={select} selectedId={serviceCircleId} over={over} />
		</SmallPanel>
	);
};

export default ServiceCircleSelector;
