import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import * as routes from "../../shared/routes";

const RequireAuth = () => {
	const authenticated = useSelector((state) => state.auth.authenticated);
	const location = useLocation();
	return authenticated ? <Outlet /> : <Navigate to={routes.LOGIN} state={{ from: location }} replace />;
};

export default RequireAuth;
