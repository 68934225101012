import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import styles from "./ServiceTemplatesEditor.module.css";
import Window from "../../../../../hoc/Window/Window";
import Panel from "../../../../../hoc/Panel/Panel";
import FlyingError from "../../../../FlyingError/FlyingError";
import SmallPanel from "../../../../../hoc/SmallPanel/SmallPanel";
import { createServiceTemplate, updateServiceTemplate } from "../../../../../features/serviceTemplate/serviceTemplateSlice";
import { clearError, setError, clearCloseOnExit } from "../../../../../features/message/messageSlice";
import InputTime from "../../../../../ui/InputTime/InputTime";
import { getDuration, timeIsValid } from "../../../../../shared/functions";
import Note from "../../../../Note/Note";
import { serviceTemplateTypes } from "../../../../../shared/globals";
import FooterButtons from "../../../../../ui/FooterButtons/FooterButtons";

const newServiceTemplate = {
    id: "",
    type: serviceTemplateTypes.fix,
    name: "",
    startTime: "",
    endTime: ""
};

const ServiceTemplatesEditor = ({ id, close, rosterPeriodId }) => {
    const serviceTemplates = useSelector((state) => state.serviceTemplate.entries);
    const fixServiceTemplates = serviceTemplates.filter((entry) => entry.type === serviceTemplateTypes.fix && entry.rosterPeriodId === rosterPeriodId);

    const closeOnExit = useSelector((state) => state.message.closeOnExit);
    const color = useSelector((state) => state.values.color);
    const editTemplate = fixServiceTemplates.find((entry) => entry.id === id) || newServiceTemplate;
    const [serviceTemplate, setServiceTemplate] = useState({ ...editTemplate });
    const [fieldErrors, setFieldErrors] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (closeOnExit) {
            dispatch(clearCloseOnExit());
            close();
        }
    }, [closeOnExit, dispatch, close]);

    const setName = (e) => {
        const value = e.target.value;
        setServiceTemplate((prev) => ({ ...prev, name: value }));
        if (
            fixServiceTemplates
                .filter((entry) => entry.id !== id)
                .map((entry) => entry.name)
                .includes(value)
        ) {
            setFieldErrors((prev) => ({ ...prev, name: "Dieser Name wird bereits verwendet" }));
        } else if (value.trim().length < 3) {
            setFieldErrors((prev) => ({ ...prev, name: "Der Name muss mindestens 3 Zeichen aufweisen" }));
        } else if (fieldErrors && fieldErrors.name) {
            delete fieldErrors.name;
        }
    };

    const save = () => {
        const errors = [];
        dispatch(clearError());
        if (!serviceTemplate.name.trim().length) {
            errors.push("Bitte gib dem Fix-Dienst einen Namen.");
        }
        if (!(timeIsValid(serviceTemplate.startTime) && timeIsValid(serviceTemplate.endTime))) {
            errors.push("Bitte gib eine gültige Dienstzeit ein.");
        }

        if (errors.length) {
            dispatch(setError(errors));
        } else {
            if (serviceTemplate.id === "") {
                const usedColors = fixServiceTemplates.map((entry) => entry.colorId);
                const possibleColors = color.filter((color) => !usedColors.includes(color.id));
                const colorId = possibleColors.length > 0 ? possibleColors[0].id : "0";
                dispatch(createServiceTemplate({ rosterPeriodId, data: { ...serviceTemplate, colorId } }));
            } else {
                dispatch(updateServiceTemplate({ id, data: { ...serviceTemplate } }));
            }
        }
    };

    const setStart = (startTime) => {
        setServiceTemplate((prev) => ({ ...prev, startTime }));
    };

    const setEnd = (endTime) => {
        setServiceTemplate((prev) => ({ ...prev, endTime }));
    };

    const duration = timeIsValid(serviceTemplate.startTime) && timeIsValid(serviceTemplate.endTime) ? getDuration(serviceTemplate.startTime, serviceTemplate.endTime) : "-";

    return (
        <Window>
            <Panel title={serviceTemplate.id === "" ? "Neuer Fix-Dienst" : "Fix-Dienst bearbeiten"} size="small" errors padding>
                <label className={styles.templateName}>
                    <span>Name</span>
                    <FlyingError error={fieldErrors ? fieldErrors.name : null}>
                        <input className={(fieldErrors && fieldErrors.name ? styles.fieldError : "") + " " + styles.name} type="text" maxLength={32} value={serviceTemplate.name} onChange={setName} />
                    </FlyingError>
                </label>
                {serviceTemplate.locked && <Note marginTop>Dieser Fix-Dienst kann nicht mehr verändert werden, da er in der Vergangenheit verwendet wurde.</Note>}
                <div className={styles.days}>
                    <SmallPanel title="Dienstzeit">
                        <div className={styles.smallPanelContent}>
                            <label className={styles.points}>
                                <span>Beginn</span>
                                <InputTime time={serviceTemplate.startTime} set={setStart} disabled={serviceTemplate.locked} />
                                Uhr
                            </label>
                            <label className={styles.points}>
                                <span>Ende</span>
                                <InputTime time={serviceTemplate.endTime} set={setEnd} disabled={serviceTemplate.locked} />
                                Uhr
                            </label>
                            <div className={styles.points}>
                                <span>Dauer</span>
                                <div>{duration}</div>
                            </div>
                        </div>
                    </SmallPanel>
                </div>
                <FooterButtons>
                    <button type="button" onClick={() => close()}>
                        Abbrechen
                    </button>
                    <button type="button" onClick={() => save()} disabled={fieldErrors && fieldErrors.name}>
                        Speichern
                    </button>
                </FooterButtons>
            </Panel>
        </Window>
    );
};

export default ServiceTemplatesEditor;
