import { useState } from "react";
import { useSelector } from "react-redux";
import nl2br from "react-nl2br";

import ColorPanel from "../../hoc/ColorPanel/ColorPanel";
import SearchFilter from "../SearchFilter/SearchFilter";

import styles from "./ShowLog.module.css";

const creaters = [
	{
		key: "admin",
		name: "Administrator",
		color: "green"
	},
	{
		key: "doctor",
		name: "Arzt",
		color: "gold"
	},
	{
		key: "system",
		name: "System",
		color: "purple"
	}
];

const ShowLog = ({ log }) => {
	const users = useSelector((state) => state.values.users);
	const persons = useSelector((state) => state.persons.entries);
	const [filterText, setFilterText] = useState("");
	const [showFrom, setShowFrom] = useState("all");

	const contains = (row, fields) => {
		if (filterText.trim().length < 3) {
			return true;
		}
		const searchStrings = filterText.split(" ");

		const found = searchStrings.map((search) => {
			search = search.toLowerCase();
			const found = fields.reduce((isFound, field) => {
				return isFound || (row[field] && row[field].toLowerCase().indexOf(search) > -1);
			}, false);
			if (found) {
				return 1;
			} else {
				return 0;
			}
		});
		const foundCount = found.reduce((a, b) => a + b, 0);
		return foundCount === searchStrings.length;
	};

	const changeShowFrom = (e) => {
		setShowFrom(e.target.value);
	};

	return (
		<div>
			<SearchFilter filterText={filterText} setFilterText={setFilterText} focusOnLoad />
			<div className={styles.showFrom}>
				Zeige:
				<label>
					<input type="radio" value="all" checked={showFrom === "all"} onChange={changeShowFrom} />
					Alle Einträge
				</label>
				{creaters.map((creater) => (
					<label key={creater.key}>
						<input type="radio" value={creater.key} checked={showFrom === creater.key} onChange={changeShowFrom} />
						Vom <span className={styles.color + " " + styles[creater.color]}>{creater.name}</span>
					</label>
				))}
			</div>

			<ul className={styles.list}>
				{log.length > 0 ? (
					log
						.filter((entry) => contains(entry, ["log"]) && (showFrom === "all" || entry.createdFromType === showFrom))
						.map((entry, index) => {
							const user = entry.createdFromType === "system" ? "System" : entry.createdFromType === "admin" ? users.find((user) => user.id === entry.createdFrom) : persons.find((user) => user.id === entry.createdFrom);
							const color = creaters.find((creater) => creater.key === entry.createdFromType).color;

							return (
								<li key={index}>
									<ColorPanel color={color} narrow>
										<h4>
											{entry.created}, {user.name}
										</h4>
										<div className={styles.log}>{nl2br(entry.log)}</div>
									</ColorPanel>
								</li>
							);
						})
				) : (
					<li>
						<i>Keine Einträge</i>
					</li>
				)}
			</ul>
		</div>
	);
};

export default ShowLog;
