import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../shared/axios";
import { logout } from "../auth/authSlice";
import { setTempSuccess } from "../message/messageSlice";
import { setRedirect } from "../app/appSlice";

const namespace = "publicHoliday";

const initialState = {
	roster: null
};

export const getPublicHolidays = createAsyncThunk(`${namespace}/getPublicHolidays`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.get("publicHoliday/" + payload);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const getPublicHolidaysOfRosterPeriod = createAsyncThunk(`${namespace}/getPublicHolidaysOfRosterPeriod`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.get("publicHoliday/rosterPeriod/" + payload);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const createDay = createAsyncThunk(`${namespace}/createDay`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("publicHoliday", payload.data);
		dispatch(setTempSuccess(payload.success));
		dispatch(setRedirect(payload.redirect));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const updateDay = createAsyncThunk(`${namespace}/updateDay`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.patch("publicHoliday/" + payload.id, payload.data);
		dispatch(setTempSuccess(payload.success));
		dispatch(setRedirect(payload.redirect));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const deleteDay = createAsyncThunk(`${namespace}/deleteDay`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.delete("publicHoliday/" + payload.id);
		dispatch(setTempSuccess(payload.success));
		dispatch(setRedirect(payload.redirect));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

const publicHolidaySlice = createSlice({
	name: namespace,
	initialState,
	extraReducers: (builder) => {
		builder
			.addCase(getPublicHolidays.fulfilled, (state, { payload }) => {
				state.roster = payload;
			})
			.addCase(getPublicHolidaysOfRosterPeriod.fulfilled, (state, { payload }) => {
				state.roster = payload;
			})
			.addCase(updateDay.fulfilled, (state, { payload }) => {
				state.roster = payload;
			})
			.addCase(createDay.fulfilled, (state, { payload }) => {
				state.roster = payload;
			})
			.addCase(deleteDay.fulfilled, (state, { payload }) => {
				state.roster = state.roster.filter((entry) => entry.id !== payload.id);
			})
			.addCase(logout.fulfilled, (state, { payload }) => {
				return initialState;
			});
	}
});

export default publicHolidaySlice.reducer;
