import { useState } from "react";
import styles from "./Fieldset.module.css";

import { ReactComponent as ArrowDown } from "../../assets/images/icon_chevron_down.svg";
import { ReactComponent as ArrowRight } from "../../assets/images/icon_chevron_right.svg";

const Fieldset = ({ legend, show, children }) => {
    const [display, setDisplay] = useState(show);
    const classNames = [styles.fieldset];
    display !== undefined && classNames.push(styles.foldable);
    display === false && classNames.push(styles.hide);

    const changeDisplay = () => {
        if (display !== undefined) {
            setDisplay((prev) => !prev);
        }
    };

    return (
        <fieldset className={classNames.join(" ")}>
            <legend onClick={changeDisplay}>
                {display === true ? <ArrowDown /> : display === false ? <ArrowRight /> : null}
                {legend}
            </legend>
            {display === undefined ? children : <div>{children}</div>}
        </fieldset>
    );
};

export default Fieldset;
