import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ReactComponent as IconOk } from "../../../assets/images/icon_ok_round_green.svg";

import styles from "./PanelHeadRosterState.module.css";

import * as routes from "../../../shared/routes";
import { rosterStates } from "../../../shared/globals";

const PanelHeadRosterState = () => {
    const rosterPeriodes = useSelector((state) => state.category.rosterPeriodes);
    const { rosterPeriodId } = useSelector((state) => state.category.selected);
    const rosterPeriode = rosterPeriodId ? rosterPeriodes.find((rosterPeriod) => rosterPeriod.id === rosterPeriodId) : null;
    const rosterState = useSelector((state) => state.roster.state);
    const navigate = useNavigate();

    if (!rosterPeriode || !rosterState) return null;

    let stateReached = true;

    const planningErrorCount = Object.keys(rosterState.planning).reduce((countErrors, key) => {
        return countErrors + (rosterState.planning[key] ? 0 : 1);
    }, 0);

    return (
        <div className={styles.servicePlanState} onClick={() => navigate(routes.ROSTER + "/" + routes.ROSTER_STATE)}>
            <span>{rosterStates.find((entry) => entry.state === rosterState.state).name}</span>
            <div className={styles.bar}>
                {rosterStates.map((entry, index) => {
                    const classNames = [];
                    stateReached && classNames.push(styles.active);
                    planningErrorCount > 0 && !index && classNames.push(styles.error);
                    if (entry.state === rosterPeriode.state) {
                        stateReached = false;
                    }
                    return <div key={entry.state} className={classNames.join(" ")}></div>;
                })}
            </div>
            {planningErrorCount > 0 ? <div className={styles.countErrors}>{planningErrorCount}</div> : <IconOk />}
        </div>
    );
};

export default PanelHeadRosterState;
