import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import PersonList from "../../components/Persons/PersonList/PersonList";
import PersonFilter from "../../components/Persons/PersonFilter/PersonFilter";

import { getPersons } from "../../features/persons/personsSlice";
import { getMedicalPractices } from "../../features/medicalPractices/medicalPracticesSlice";
import DoctorsMedicalPractices from "../../components/DoctorsMedicalPractices/DoctorsMedicalPractices";

const Persons = () => {
	const dispatch = useDispatch();
	const persons = useSelector((state) => state.persons.entries);
	const reload = useSelector((state) => state.persons.reload);
	const medicalPractices = useSelector((state) => state.medicalPractices.entries);

	useEffect(() => {
		if (!persons || reload) {
			dispatch(getPersons());
		}
	}, [dispatch, persons, reload]);

	useEffect(() => {
		if (!medicalPractices) {
			dispatch(getMedicalPractices());
		}
	}, [dispatch, medicalPractices]);

	if (!persons || !medicalPractices) {
		return null;
	}

	return (
		<Fragment>
			<Outlet />
			<DoctorsMedicalPractices>
				<PersonList persons={persons} />
				<PersonFilter />
			</DoctorsMedicalPractices>
		</Fragment>
	);
};

export default Persons;
