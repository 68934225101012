import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../shared/axios";

import { getCategories } from "../category/categorySlice";
import { setError, clearAllMessages, setTempSuccess } from "../message/messageSlice";
import { getUserData } from "../userData/userDataSlice";
import { getUsers, getColors, getPhoneCountries, getAreasOfExpertise } from "../values/valuesSlice";

const namespace = "auth";

const initialState = {
	authenticated: false,
	autoSignupTried: false
};

const isUnauthorized = (action) => {
	const unAuth = action.type.endsWith("rejected") && action.payload === 401;
	if (unAuth) {
		debugger;
	}
	return unAuth;
};

export const login = createAsyncThunk(`${namespace}/login`, async (authData, { dispatch, rejectWithValue }) => {
	dispatch(clearAllMessages());
	try {
		const { data } = await axios.post("auth/login", authData);
		dispatch(getUserData());
		dispatch(getUsers());
		dispatch(getColors());
		dispatch(getPhoneCountries());
		dispatch(getAreasOfExpertise());
		dispatch(getCategories());
		return data;
	} catch (error) {
		if (error.response.status === 401) {
			dispatch(setError("Die E-Mail Adresse oder das Passwort ist falsch."));
		}
		return rejectWithValue(error.response.status);
	}
});

export const tryAutoSignUp = createAsyncThunk(`${namespace}/autoSignup`, async (_, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.get("auth/autoSignup");
		dispatch(getUserData());
		dispatch(getUsers());
		dispatch(getColors());
		dispatch(getPhoneCountries());
		dispatch(getAreasOfExpertise());
		dispatch(getCategories());
		return data;
	} catch (error) {
		if (error.response.status === 401) {
			dispatch(logout());
		}
		return rejectWithValue(error.response.status);
	}
});

export const logout = createAsyncThunk(`${namespace}/logout`, async (_, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.get("auth/logout");
		dispatch(setTempSuccess("Du hast Dich erfolgreich abgemeldet"));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

const authSlice = createSlice({
	name: namespace,
	initialState,
	extraReducers: (builder) => {
		builder
			.addCase(logout.fulfilled, (state, { payload }) => {
				return initialState;
			})
			.addCase(login.fulfilled, (state, { payload }) => {
				state.autoSignupTried = true;
				state.authenticated = true;
			})
			.addCase(tryAutoSignUp.fulfilled, (state, { payload }) => {
				state.autoSignupTried = true;
				state.authenticated = true;
			})
			.addCase(tryAutoSignUp.rejected, (state, { payload }) => {
				state.autoSignupTried = true;
				state.authenticated = false;
			})
			.addMatcher(isUnauthorized, (state, { payload }) => {
				console.log("Logout now because of 401");
				state.authenticated = false;
			});
	}
});

export default authSlice.reducer;
