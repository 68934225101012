import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteGeographyPlanOfDay } from "../../../../features/geographyPlan/geographyPlanSlice";
import { dateIsInFuture, dateIsToday, hasWhiteText } from "../../../../shared/functions";

import styles from "./Day.module.css";

const Day = ({ day, map, plan, selectedPlan, selectedBaseMap, askInsertOptions }) => {
	const colors = useSelector((state) => state.values.color);
	const [hoverDay, setHoverDay] = useState(false);
	const [hoverTime, setHoverTime] = useState(false);
	const dispatch = useDispatch();

	let dayTitle = "";
	let timeTitle = "";
	let dayAction = null;
	let timeAction = null;
	const dayClassNames = [];
	const timeClassNames = [];

	const disabled = !dateIsInFuture(day.day, day.month, day.year);
	dateIsToday(day.day, day.month, day.year) && dayClassNames.push(styles.today);

	const clickDay = (e) => {
		askInsertOptions("map", day.day, day.month, day.year);
		e.stopPropagation();
	};

	const clickTime = (e) => {
		if (day.geographyPlanId) {
			dispatch(deleteGeographyPlanOfDay(day.id));
		} else {
			askInsertOptions("plan", day.day, day.month, day.year);
		}
		e.stopPropagation();
	};

	disabled && dayClassNames.push(styles.disabled);
	const dayColor = map ? colors.find((color) => color.id === map.colorId)?.color || null : null;
	const timeColor = plan ? colors.find((color) => color.id === plan.colorId)?.color || null : null;
	const dayStyle = dayColor ? { backgroundColor: `#${dayColor}` } : {};
	const timeStyle = timeColor ? { backgroundColor: `#${timeColor}` } : {};
	if (dayColor && hasWhiteText(dayColor)) {
		dayStyle.color = "#ffffff";
	}
	if (hoverTime) {
		if (disabled) {
			if (timeColor) {
				timeTitle = `${plan.name}`;
			}
		} else {
			timeAction = clickTime;
			if (timeColor) {
				timeTitle = `«${plan.name}» entfernen`;
				timeClassNames.push(styles.removeTime);
			} else if (selectedPlan) {
				timeTitle = `«${selectedPlan.name}» einfügen`;
				const hoverColor = colors.find((color) => color.id === selectedPlan.colorId)?.color || "ffffff";
				timeStyle.backgroundColor = `#${hoverColor}`;
				timeStyle.cursor = "pointer";
			}
		}
	} else if (hoverDay) {
		if (dayColor) {
			dayTitle = `Basiskarte «${map.name}»`;
		}
		if (!disabled && selectedBaseMap && (!map || map.id !== selectedBaseMap.id)) {
			dayAction = clickDay;
			if (map) {
				dayTitle = `Basiskarte «${map.name}» durch «${selectedBaseMap.name}» ersetzen`;
			} else {
				dayTitle = `Basiskarte «${selectedBaseMap.name}» einfügen`;
			}
			const hoverColor = colors.find((color) => color.id === selectedBaseMap.colorId)?.color || "ffffff";
			dayStyle.backgroundColor = `#${hoverColor}`;
			dayStyle.cursor = "pointer";
			if (hasWhiteText(hoverColor)) {
				dayStyle.color = "#ffffff";
			}
		}
	}

	return (
		<div className={dayClassNames.join(" ")} title={dayTitle} style={dayStyle} onMouseEnter={() => setHoverDay(true)} onMouseLeave={() => setHoverDay(false)} onClick={dayAction}>
			{day.day}
			{(timeColor || (hoverDay && selectedPlan && !disabled)) && <div className={timeClassNames.join(" ")} title={timeTitle} style={timeStyle} onMouseEnter={() => setHoverTime(true)} onMouseLeave={() => setHoverTime(false)} onClick={timeAction}></div>}
		</div>
	);
};

export default Day;
