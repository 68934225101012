import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios";
import { logout } from "../../auth/authSlice";
// import { setError, setTempError, setTempSuccess } from "../../message/messageSlice";
// import { setRedirect } from "../../app/appSlice";
// import { clearRosterState } from "../../roster/rosterSlice";

const namespace = "pointBalance";

const initialState = {
    billingPeriods: null
};

export const getBillingPeriods = createAsyncThunk(`${namespace}/getBillingPeriods`, async (payload, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.get("person/" + payload + "/pointBalance");
        return data;
    } catch (error) {
        return rejectWithValue(error.response.status);
    }
});

const pointBalanceSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getBillingPeriods.fulfilled, (state, { payload }) => {
                state.billingPeriods = payload;
            })
            .addCase(logout.fulfilled, (state, { payload }) => {
                return initialState;
            });
    }
});

//export const { clearPersonLog, clearPerson, startEdit, stopEdit } = personSlice.actions;

export default pointBalanceSlice.reducer;
