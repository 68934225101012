import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { changeOrderBy, setOrderBy } from "../../../features/persons/personsSlice";
import NoteIcon from "../../../ui/NoteIcon/NoteIcon";
import SortableTable from "../../SortableTable/SortableTable";

import styles from "./PersonList.module.css";

import * as routes from "../../../shared/routes";

const fields = [
	{
		key: "lastname",
		name: "Nachname",
		search: true,
		className: styles.lastname
	},
	{
		key: "firstname",
		name: "Vorname",
		search: true,
		className: styles.firstname
	},
	{
		key: "gln",
		name: "GLN",
		search: true,
		className: styles.gln
	},
	{
		key: "notes",
		name: <NoteIcon />,
		search: true,
		showField: (row) => (row.notes.length > 0 ? <NoteIcon marked={row.markedNotes} count={row.notes.length} /> : ""),
		className: styles.notes,
		sort: (a, b, order) => {
			const aCount = a.notes.length + (a.markedNotes ? a.markedNotes * 1000 : 0);
			const bCount = b.notes.length + (b.markedNotes ? b.markedNotes * 1000 : 0);
			return order === "asc" ? aCount - bCount : bCount - aCount;
		}
	}
];

const PersonList = ({ persons }) => {
	const filter = useSelector((state) => state.persons.filter);
	const orderBy = useSelector((state) => state.persons.orderBy);
	const zipsOfServiceCircle = useSelector((state) => state.persons.zipsOfServiceCircle.zips);
	const rosterPeriodDoctorIds = useSelector((state) => state.persons.rosterPeriodDoctorIds);
	const dispatch = useDispatch();
	const serviceTypes = useSelector((state) => state.category.serviceTypes);
	const { categoryId, serviceTypeId } = useSelector((state) => state.category.selected);

	const searchFields = fields.filter((entry) => entry.search).map((entry) => entry.key);
	const navigate = useNavigate();

	useEffect(() => {
		if (orderBy === null) {
			dispatch(
				setOrderBy({
					key: fields[0].key,
					order: "asc"
				})
			);
		}
	}, [orderBy, dispatch]);

	if (orderBy === null) {
		return null;
	}

	const contains = (row, fields) => {
		if (filter.search.trim().length < 3) {
			return true;
		}
		const searchStrings = filter.search.split(" ");

		const found = searchStrings.map((search) => {
			search = search.toLowerCase();
			const found = fields.reduce((isFound, field) => {
				if (isFound) return isFound;
				if (field === "notes") {
					return row[field].reduce((noteFound, value) => noteFound || value.toLowerCase().indexOf(search) > 1, false);
				} else {
					return row[field] && row[field].toLowerCase().indexOf(search) > -1;
				}
			}, false);
			if (found) {
				return 1;
			} else {
				return 0;
			}
		});
		const foundCount = found.reduce((a, b) => a + b, 0);
		return foundCount === searchStrings.length;
	};

	const changeOrder = (key) => {
		dispatch(changeOrderBy(key));
	};

	const filterPersons = (row) => {
		let include = true;

		include = include && (!filter.errors || row.error);
		if (filter.bySelector === "category") {
			if (!filter.byServiceType) {
				const includedServiceTypeIds = serviceTypes.filter((entry) => entry.categoryId === categoryId).map((entry) => entry.id);
				include =
					include &&
					row.serviceTypeIds.reduce((found, doctorServiceTypeId) => {
						return found || includedServiceTypeIds.includes(doctorServiceTypeId);
					}, false);
			} else {
				include = include && row.serviceTypeIds.includes(serviceTypeId);
				include = include && (!filter.byServiceCircle || (zipsOfServiceCircle && zipsOfServiceCircle.some((zip) => row.medicalPracticeZips.includes(zip))));
			}
		} else if (filter.bySelector === "period") {
			include = include && rosterPeriodDoctorIds.includes(row.id);
		}
		if (filter.nfdState !== "") {
			include = include && row.nfdState === filter.nfdState;
			if (filter.nfdState === "inactive" && filter.nfdStateDetails) {
				include = include && row.nfdStateDetails === filter.nfdStateDetails;
			}
		}

		include = include && (!filter.areaOfExpertise || row.areasOfExpertise.includes(filter.areaOfExpertise));
		return include;
	};

	const rowClickAction = (row) => {
		navigate(routes.PERSONS + "/" + row.id + routes.DOCTOR_ACCOUNT);
	};

	const filteredPersons = persons.filter((entry) => contains(entry, searchFields) && filterPersons(entry));
	const resultInfo = persons.length > filteredPersons.length ? `${filteredPersons.length} von ${persons.length} ${persons.length === 1 ? "Person" : "Personen"} gefunden` : `${persons.length === 1 ? "Eine Person" : persons.length + " Personen"}`;

	return (
		<div>
			<SortableTable data={filteredPersons} fields={fields} resultInfo={resultInfo} orderBy={orderBy} changeOrder={changeOrder} rowClickAction={rowClickAction} tableStyle={styles.table} />
		</div>
	);
};

export default PersonList;
