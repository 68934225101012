import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ask, clear } from "../../../../../features/ask/askSlice";

import { ReactComponent as Trash } from "../../../../../assets/images/icon_trash.svg";
import SmallPanel from "../../../../../hoc/SmallPanel/SmallPanel";
import AddAreaOfExpertise from "./AddAreaOfExpertise/AddAreaOfExpertise";
import * as askTypes from "../../../../../shared/askTypes";
import styles from "./AreasOfExpertise.module.css";
import { deleteAreaOfExpertise } from "../../../../../features/person/personSlice";

const AreasOfExpertise = ({ personId }) => {
	const dispatch = useDispatch();
	const areasOfExpertise = useSelector((state) => state.person.person.areasOfExpertise);
	const answer = useSelector((state) => state.ask.answer);
	const [addAreasOfExpertise, setAddAreasOfExpertise] = useState(false);

	const usedAreaIds = areasOfExpertise.map((area) => area.id);

	useEffect(() => {
		if (answer) {
			if (answer.type === askTypes.DELETE_AREA_OF_EXPERTISE && answer.value !== null) {
				dispatch(deleteAreaOfExpertise({ personId, id: answer.value }));
				dispatch(clear());
			}
		}
	}, [answer, dispatch, personId]);

	const removeAreaOfExpertise = (e, id) => {
		if (e.altKey) {
			dispatch(deleteAreaOfExpertise({ personId, id }));
		} else {
			const areaOfExpertiseName = areasOfExpertise.find((entry) => entry.id === id)?.name;
			dispatch(
				ask({
					type: askTypes.DELETE_AREA_OF_EXPERTISE,
					title: "Spezialgebiet löschen",
					question: "Möchtest Du das Spezialgebiet «" + areaOfExpertiseName + "» wirklich löschen?",
					buttons: [
						{ label: "Ja, löschen", answer: id },
						{ label: "Abbrechen", answer: null }
					]
				})
			);
		}
	};

	return (
		<Fragment>
			<SmallPanel title="Spezialgebiete" add={() => setAddAreasOfExpertise(true)}>
				<ul className={styles.content}>
					{areasOfExpertise.length > 0 ? (
						areasOfExpertise.map((area) => (
							<li key={area.id}>
								<div>{area.name}</div>
								<div className={styles.icons}>
									<Trash className={styles.icon} onClick={(e) => removeAreaOfExpertise(e, area.id)} />
								</div>
							</li>
						))
					) : (
						<li>
							<i>Noch keine Spezialgebiete definiert</i>
						</li>
					)}
				</ul>
			</SmallPanel>
			{addAreasOfExpertise && <AddAreaOfExpertise id={personId} areas={areasOfExpertise} usedIds={usedAreaIds} cancel={() => setAddAreasOfExpertise(false)} />}
		</Fragment>
	);
};

export default AreasOfExpertise;
