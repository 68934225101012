import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { dateExists, validateGLN } from "../../../../../../shared/functions";
import InputDate from "../../../../../../ui/InputDate/InputDate";
import RadioSelect from "../../../../../../ui/RadioSelect/RadioSelect";
import CheckBox from "../../../../../../ui/CheckBox/CheckBox";
import FooterButtons from "../../../../../../ui/FooterButtons/FooterButtons";
import { stopEdit, updatePerson } from "../../../../../../features/person/personSlice";

import styles from "../PersonalInformations.module.css";
import Error from "../../../../../../ui/Error";
import InputWithPredefinedValues from "../../../../../../ui/InputWithPredefinedValues/InputWithPredefinedValues";

const genders = {
	w: "Weiblich",
	m: "Männlich"
};

const EditPersonalInformations = ({ person, loginEmailId, isDoctor, nfdStates }) => {
	const [dateError, setDateError] = useState(false);
	const { id, nfdState, nfdStateDetails, type, gender, title, firstname, lastname, birthdate, gln, eMails, nfdStateDetailList, personTitles, workload } = person;
	const initialData = {
		id,
		loginEmailId,
		nfdState,
		nfdStateDetails,
		type,
		gender,
		title,
		firstname,
		lastname,
		birthdate,
		gln: gln || "",
		resetPassword: false,
		workload
	};

	const [user, setUser] = useState({ ...initialData });
	const dispatch = useDispatch();
	const disabled = user.firstname.trim().length < 2 || user.lastname.trim().length < 2 || (user.gln !== "" && !validateGLN(user.gln)) || dateError || (user.nfdState === "inactive" && user.nfdStateDetails.trim().length < 2);

	const cancelEdit = () => {
		dispatch(stopEdit());
	};

	const change = (e) => {
		const fieldname = e.target.name;
		const value = e.target.value;

		if (fieldname === "gln" && !value.match(/^\d*$/)) {
			return;
		}
		setUser((prev) => ({ ...prev, [fieldname]: value }));
	};

	const saveUser = () => {
		const data = { ...user };
		if (data.nfdState !== "inactive") {
			data.nfdStateDetails = "";
		}
		dispatch(updatePerson({ id, data }));
	};

	const setBirthDate = (value) => {
		setUser((prev) => ({ ...prev, birthdate: value }));
		setDateError(value !== "--" && !dateExists(value));
	};

	const setLoginEmailId = (id) => {
		setUser((prev) => ({ ...prev, loginEmailId: id }));
	};

	const setResetPassword = (checked) => {
		setUser((prev) => ({ ...prev, resetPassword: checked }));
	};

	const setNfdStateDetails = (value) => {
		setUser((prev) => ({ ...prev, nfdStateDetails: value }));
	};

	const setTitle = (value) => {
		setUser((prev) => ({ ...prev, title: value }));
	};

	return (
		<div className={styles.content}>
			<Error />
			<div className={styles.twoCols}>
				<label className={styles.label}>
					<span>Titel</span>
					<InputWithPredefinedValues value={user.title} name="title" setValue={setTitle} maxLength={32} predefinedValues={personTitles} />
				</label>
				<label className={styles.label}>
					<span>Geschlecht</span>
					<div className={styles.radios}>
						<label className={styles.radioLabel}>
							<input type="radio" name="gender" value="w" checked={user.gender === "w"} onChange={change} />
							{genders.w}
						</label>
						<label className={styles.radioLabel}>
							<input type="radio" name="gender" value="m" checked={user.gender === "m"} onChange={change} />
							{genders.m}
						</label>
						<label className={styles.radioLabel}>
							<input type="radio" name="gender" value="" checked={user.gender === ""} onChange={change} />
							Unbestimmt
						</label>
					</div>
				</label>
			</div>
			<div className={styles.twoCols}>
				<label className={styles.label}>
					<span>Vorname</span>
					<input type="text" name="firstname" value={user.firstname} onChange={change} maxLength={64} />
				</label>
				<label className={styles.label}>
					<span>Nachname</span>
					<input type="text" name="lastname" value={user.lastname} onChange={change} maxLength={64} />
				</label>
			</div>
			{isDoctor && (
				<div className={styles.twoCols}>
					<label className={styles.label}>
						<span>Geburtsdatum</span>
						<InputDate date={user.birthdate ? user.birthdate : ""} set={setBirthDate} error={dateError} />
					</label>

					<label className={styles.label}>
						<span>GLN</span>
						<input type="text" name="gln" value={user.gln} onChange={change} maxLength={13} className={user.gln === "" || validateGLN(user.gln) ? "" : styles.error} />
					</label>
				</div>
			)}

			<div className={styles.twoCols}>
				<div>
					<RadioSelect data={eMails} label="Login E-Mail Adresse" keyName="id" valueName="eMail" selected={user.loginEmailId} setSelected={setLoginEmailId} none={0} />
				</div>
				{user.loginEmailId > 0 && (
					<label className={styles.label}>
						<span>Zugang</span>
						<div>
							<CheckBox checked={user.resetPassword} set={setResetPassword} name={`Passwort zurücksetzen und ${user.firstname} ${user.lastname} Zugangsdaten senden`} />
						</div>
					</label>
				)}
			</div>
			{isDoctor && (
				<div className={styles.twoCols}>
					<label className={styles.label}>
						<span>Notfalldienst-Status</span>
						<div>
							{nfdStates.map((nfdState) => (
								<label key={nfdState.key} className={styles.radioLabel}>
									<input type="radio" name="nfdState" value={nfdState.key} checked={user.nfdState === nfdState.key} onChange={change} />
									{nfdState.name}
								</label>
							))}
							{user.nfdState === "inactive" && (
								<div className={styles.indent}>
									<label className={styles.label}>
										<span>Grund</span>
										<InputWithPredefinedValues value={user.nfdStateDetails} name="title" setValue={setNfdStateDetails} maxLength={32} predefinedValues={nfdStateDetailList} placeholder="Grund?" />
									</label>
								</div>
							)}
						</div>
					</label>
					<label className={styles.label}>
						<span>Arbeitspensum</span>
						<select size={1} name="workload" value={user.workload} onChange={change}>
							{new Array(11).fill(null).map((entry, index) => (
								<option key={index} value={index * 10}>
									{index * 10}%
								</option>
							))}
						</select>
					</label>
				</div>
			)}
			<FooterButtons>
				<button type="button" onClick={cancelEdit}>
					Abbrechen
				</button>
				<button type="button" onClick={saveUser} disabled={disabled}>
					Speichern
				</button>
			</FooterButtons>
		</div>
	);
};

export default EditPersonalInformations;
