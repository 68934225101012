import ColorIcon from "../../ColorIcon/ColorIcon";
import styles from "./ListItem.module.css";

const ListItem = ({ id, value, colorId, select, selected, over = null }) => {
	const colorIcon = typeof colorId === "number" ? <ColorIcon colorId={colorId} /> : null;

	const mouseOver = () => {
		over && over(id);
	};

	const mouseLeave = () => {
		over && over(id, false);
	};

	return (
		<li className={selected ? styles.selected : ""} onClick={selected ? null : () => select(id)} onMouseEnter={mouseOver} onMouseLeave={mouseLeave}>
			{colorIcon}
			{value}
		</li>
	);
};

export default ListItem;
