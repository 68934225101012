import ColorIcon from "../../../../ColorIcon/ColorIcon";
import Points from "../../../../Display/Points/Points";
import ProgressBar from "../../../../Display/ProgressBar/ProgressBar";
import { ReactComponent as Favorite } from "../../../../../assets/images/icon_favorite.svg";
import styles from "./ShowBooking.module.css";

const ShowBooking = ({ isBooked, isFavorite, startDay, endDay, serviceTemplate, chance, points, includesPublicHolidays }) => {
    const classNames = [styles.service];
    endDay !== null && classNames.push(styles.grouped);
    includesPublicHolidays && classNames.push(styles.publicHoliday);
    !isBooked && classNames.push(styles.notBooked);

    const getDayClass = (day) => {
        return day.weekend ? styles.weekend : "";
    };

    const isDifferentDay = () => {
        return endDay && (endDay.dayNr !== startDay.dayNr || endDay.monthName !== startDay.monthName);
    };

    return (
        <div className={classNames.join(" ")}>
            <div className={styles.favorite}>{isFavorite && <Favorite />}</div>
            <div className={styles.date}>
                <div>
                    <span className={getDayClass(startDay)}>{startDay.dayName}</span>
                    {isDifferentDay() && (
                        <>
                            <br /> - <span className={getDayClass(endDay)}>{endDay.dayName}</span>
                        </>
                    )}
                </div>
                <div>
                    <span className={getDayClass(startDay)}>
                        {startDay.dayNr}. {startDay.monthName}
                    </span>
                    {isDifferentDay() && (
                        <>
                            <br /> -{" "}
                            <span className={getDayClass(endDay)}>
                                {endDay.dayNr}. {endDay.monthName}
                            </span>
                        </>
                    )}
                </div>
            </div>
            <div className={styles.serviceTemplate}>
                {endDay ? (
                    <i>Gruppierter Dienst</i>
                ) : (
                    <>
                        <ColorIcon colorId={serviceTemplate ? serviceTemplate.colorId : "0"} /> {serviceTemplate ? serviceTemplate.name : "???"}
                    </>
                )}
            </div>
            <div className={styles.progress}>{isFavorite ? <ProgressBar value={chance} width="60px" title={`${Math.round(chance)}%`} /> : " "}</div>
            <div className={styles.points}>
                <Points>{points}</Points>
            </div>
        </div>
    );
};

export default ShowBooking;
