import { Outlet } from "react-router-dom";
import SideMenu from "../../ui/SideMenu/SideMenu";
import styles from "./SettingsBrowser.module.css";

const SettingsBrowser = ({ menus, baseUrl }) => {
	return (
		<div className={styles.content}>
			<SideMenu menus={menus} baseUrl={baseUrl} />
			<div className={styles.rightPanel}>
				<Outlet />
			</div>
		</div>
	);
};

export default SettingsBrowser;
