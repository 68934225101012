import ColHeader from "../Col/ColHeader/ColHeader";
import Col from "../Col/Col";
import CategoryColItem from "./CategoryColItem/CategoryColItem";

import ColContent from "../Col/ColContent/ColContent";

const CategoryColView = ({ title, items, select, selectedId = null, end = false, colCount }) => {
	const selectMenu = (id) => {
		select(id);
	};

	return (
		<Col colCount={colCount}>
			<ColHeader title={title} />
			<ColContent>
				{items.map((entry) => (
					<CategoryColItem key={entry.id} selected={selectedId === entry.id} showArrow={!end} action={() => selectMenu(entry.id)}>
						<span>{entry.name}</span>
					</CategoryColItem>
				))}
			</ColContent>
		</Col>
	);
};

export default CategoryColView;
