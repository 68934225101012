import { useState } from "react";
import { useDispatch } from "react-redux";

import Panel from "../../../../../../hoc/Panel/Panel";
import Window from "../../../../../../hoc/Window/Window";
import CheckBox from "../../../../../../ui/CheckBox/CheckBox";
import FooterButtons from "../../../../../../ui/FooterButtons/FooterButtons";

import { addOrUpdatePersonFromMedicalPractice } from "../../../../../../features/person/personSlice";

const EditPerson = ({ personId, medicalPracticeId, isAdmin, doesEmergencyService, cancel }) => {
	const [admin, setAdmin] = useState(isAdmin);
	const [emergencyService, setEmergencyService] = useState(doesEmergencyService);
	const dispatch = useDispatch();

	const save = () => {
		dispatch(addOrUpdatePersonFromMedicalPractice({ medicalPracticeId, personId, data: { admin, emergencyService } }));
		cancel();
	};

	return (
		<Window>
			<Panel title="Rechte" size="small" padding>
				<CheckBox value="yes" checked={admin} name="Administrator" set={setAdmin} />
				<CheckBox value="yes" checked={emergencyService} name="Leistet Notfalldienst in dieser Praxis" set={setEmergencyService} />
				<FooterButtons paddingTop>
					<button type="button" onClick={cancel}>
						Abbrechen
					</button>
					<button type="button" onClick={save}>
						Ok
					</button>
				</FooterButtons>
			</Panel>
		</Window>
	);
};

export default EditPerson;
