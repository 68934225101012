import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SmallPanel from "../../../../hoc/SmallPanel/SmallPanel";

import { ask, clear } from "../../../../features/ask/askSlice";
import { updateServiceTemplateColor, deleteServiceTemplate, getServiceTemplates } from "../../../../features/serviceTemplate/serviceTemplateSlice";
import EditableList from "../../../EditableList/EditableList";
import ServiceTemplatesEditor from "./ServiceTemplatesEditor/ServiceTemplatesEditor";
import ColorChooser from "../../../ColorChooser/ColorChooser";

import * as askTypes from "../../../../shared/askTypes";
import { serviceTemplateTypes } from "../../../../shared/globals";

const ServiceTemplates = ({ serviceCircleId, rosterPeriodId, select, selectedId }) => {
	const [editTemplate, setEditTemplate] = useState(false);
	const [addTemplate, setAddTemplate] = useState(false);
	const [chooseColor, setChooseColor] = useState(0);
	const dispatch = useDispatch();
	const allServiceTemplates = useSelector((state) => state.serviceTemplate.entries);
	const serviceTemplates = allServiceTemplates ? allServiceTemplates.filter((entry) => entry.rosterPeriodId === rosterPeriodId && entry.type === serviceTemplateTypes.bookable) : [];
	const rosterDays = useSelector((state) => state.roster.days);
	const answer = useSelector((state) => state.ask.answer);

	const serviceTemplatesWithCount = serviceTemplates.map((serviceTemplate) => {
		const usedCount = rosterDays ? rosterDays.reduce((count, day) => count + day.services.filter((service) => service.serviceTemplateId === serviceTemplate.id).length, 0) : 0;
		return { ...serviceTemplate, usedCount };
	});

	useEffect(() => {
		if (serviceCircleId) {
			dispatch(getServiceTemplates({ serviceCircleId, rosterPeriodId }));
		}
	}, [dispatch, serviceCircleId, rosterPeriodId]);

	useEffect(() => {
		if (answer) {
			if (answer.type === askTypes.DELETE_SERVICE_TEMPLATE && answer.value !== null) {
				dispatch(deleteServiceTemplate(answer.value));
				dispatch(clear());
			}
		}
	}, [answer, dispatch]);

	const selectTemplate = (id) => {
		select(id);
	};

	const edit = (id) => {
		setEditTemplate(true);
	};

	const deleteItem = (id) => {
		const serviceTemplate = serviceTemplates.find((entry) => entry.id === id);
		dispatch(
			ask({
				type: askTypes.DELETE_SERVICE_TEMPLATE,
				title: "Dienst löschen",
				question: "Möchtest Du den Dienst «" + serviceTemplate.name + "» wirklich löschen?",
				buttons: [
					{ label: "Ja, löschen", answer: id },
					{ label: "Abbrechen", answer: null }
				]
			})
		);
	};

	const close = () => {
		setEditTemplate(false);
		setAddTemplate(false);
	};

	const changeColor = (id) => {
		setChooseColor(id);
	};

	const setColor = (colorId) => {
		dispatch(updateServiceTemplateColor({ id: chooseColor, data: { colorId } }));
		setChooseColor(0);
	};

	return (
		<Fragment>
			<SmallPanel title="Dienste" add={() => setAddTemplate(true)}>
				<EditableList entries={serviceTemplatesWithCount} selected={selectedId} select={selectTemplate} edit={edit} deleteItem={deleteItem} changeColor={changeColor} />
			</SmallPanel>
			{(editTemplate || addTemplate) && <ServiceTemplatesEditor close={close} id={editTemplate ? selectedId : ""} rosterPeriodId={rosterPeriodId} />}
			{chooseColor > 0 && <ColorChooser usedColors={serviceTemplates.map((entry) => entry.colorId)} selected={serviceTemplates.find((entry) => entry.id === chooseColor).colorId} cancel={() => setChooseColor(0)} setColor={setColor} />}
		</Fragment>
	);
};

export default ServiceTemplates;
