import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { allocate, getRosterInfo, getRosterState } from "../../../features/roster/rosterSlice";
import { getPersons } from "../../../features/persons/personsSlice";
import { getServiceTemplates } from "../../../features/serviceTemplate/serviceTemplateSlice";

import * as routes from "../../../shared/routes";
import { getPublicHolidaysOfRosterPeriod } from "../../../features/publicHoliday/publicHolidaySlice";

const Allocation = () => {
    const { rosterPeriodId, serviceCircleId } = useSelector((state) => state.category.selected);
    const rosterInfo = useSelector((state) => state.roster.info);
    const rosterState = useSelector((state) => state.roster.state);
    const doctorInputs = useSelector((state) => state.roster.rosterPreview.doctorInputs);
    const persons = useSelector((state) => state.persons.entries);
    const dispatch = useDispatch();
    const showFavorites = rosterState && rosterState.state !== "planning";
    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (pathname === routes.ROSTER + "/" + routes.ROSTER_ALLOCATION) {
            navigate(routes.ROSTER + "/" + routes.ROSTER_ALLOCATION + "/" + routes.ROSTER_ALLOCATION_TABLE);
        }
    }, [pathname, navigate]);

    useEffect(() => {
        dispatch(getRosterState(rosterPeriodId));
    }, [rosterPeriodId, dispatch]);

    useEffect(() => {
        if (showFavorites) {
            dispatch(allocate(rosterPeriodId));
            dispatch(getPersons());
            dispatch(getServiceTemplates({ serviceCircleId, rosterPeriodId }));
            dispatch(getPublicHolidaysOfRosterPeriod(rosterPeriodId));
            dispatch(getRosterInfo(rosterPeriodId));
        }
    }, [dispatch, showFavorites, rosterPeriodId, serviceCircleId]);

    if (!rosterState || !persons || !rosterInfo || !doctorInputs) {
        return null;
    }

    return <Outlet />;
};

export default Allocation;
