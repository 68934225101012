import { useSelector } from "react-redux";
import styles from "./ShowUser.module.css";

const ShowUser = ({ id }) => {
	const users = useSelector((state) => state.values.users);
	const user = users.find((entry) => entry.id === id);

	return <span className={styles.user}>{user ? user.name : "?"}</span>;
};

export default ShowUser;
