import Fieldset from "../../../../../hoc/Fieldset/Fieldset";
import FooterButtons from "../../../../../ui/FooterButtons/FooterButtons";
import { formatDate, getDateObjectFromMySqlDate, getNfdLoadFromWorkLoad } from "../../../../../shared/functions";

import styles from "./PersonalInformations.module.css";

import EditPersonalInformations from "./EditPersonalInformations/EditPersonalInformations";
import { useDispatch, useSelector } from "react-redux";
import { startEdit, stopEdit } from "../../../../../features/person/personSlice";
import { useEffect } from "react";
import { nfdStates } from "../../../../../shared/globals";

const PersonalInformations = ({ person }) => {
	const edit = useSelector((state) => state.person.edit);
	const dispatch = useDispatch();
	const loginEmailId = person.eMails.find((entry) => entry.auth)?.id || 0;

	const isDoctor = person.type === "doctor";

	const editPersonalInformations = () => {
		dispatch(startEdit("PersonalInformations"));
	};

	useEffect(() => {
		return () => dispatch(stopEdit());
	}, [dispatch]);

	return (
		<Fieldset legend="Persönliche Informationen">
			{edit === "PersonalInformations" ? (
				<EditPersonalInformations person={person} loginEmailId={loginEmailId} isDoctor={isDoctor} nfdStates={nfdStates} />
			) : (
				<div className={styles.view}>
					{person.gender ? (person.gender === "w" ? "Frau" : "Herr") : "???"} {person.title} {person.firstname} {person.lastname}
					{isDoctor && (
						<div className={styles.twoCols}>
							<label>
								<span>Geburtsdatum</span>
								{person.birthdate ? formatDate(getDateObjectFromMySqlDate(person.birthdate)) : "-"}
							</label>
							<label>
								<span>GLN</span>
								{person.gln ? person.gln : "-"}
							</label>
						</div>
					)}
					<label>
						<span>Login E-Mail Adresse</span>
						{loginEmailId ? person.eMails.find((entry) => entry.id === loginEmailId)?.eMail : <i>Keine</i>}
					</label>
					{isDoctor && (
						<div className={styles.twoCols}>
							<label>
								<span>Notfalldienst-Status</span>
								<div>
									{person.nfdState ? nfdStates.find((entry) => entry.key === person.nfdState).name : "-"}
									{person.nfdState === "inactive" && `, ${person.nfdStateDetails}`}
								</div>
							</label>
							<label>
								<span>Arbeitspensum</span>
								{person.workload}% {person.nfdState === "active_service" && <>(Notfalldienstpensum: {getNfdLoadFromWorkLoad(person.workload)}%)</>}
							</label>
						</div>
					)}
					<FooterButtons>
						<button type="button" onClick={editPersonalInformations}>
							Bearbeiten
						</button>
					</FooterButtons>
				</div>
			)}
		</Fieldset>
	);
};

export default PersonalInformations;
