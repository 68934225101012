import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../shared/axios";
import { logout } from "../auth/authSlice";
import { setTempError, setTempSuccess } from "../message/messageSlice";
import { setRedirect } from "../app/appSlice";

const namespace = "medicalPractice";

const initialState = {
	data: null,
	log: null
};

export const getMedicalPractice = createAsyncThunk(`${namespace}/getMedicalPractice`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.get("medicalPractice/" + payload);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const updateMedicalPractice = createAsyncThunk(`${namespace}/updateMedicalPractice`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.patch("medicalPractice/" + payload.id, payload.data);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const createMedicalPractice = createAsyncThunk(`${namespace}/createMedicalPractice`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("medicalPractice", payload.data);
		dispatch(setTempSuccess("Die Praxis wurde erfolgreich erstellt."));
		dispatch(setRedirect(payload.redirect.replace("{id}", data.id)));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const deleteMedicalPractice = createAsyncThunk(`${namespace}/deleteMedicalPractice`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.delete("medicalPractice/" + payload.medicalPracticeId);
		dispatch(setRedirect(payload.redirect));
		dispatch(setTempSuccess("Die Praxis wurde erfolgreich gelöscht."));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const addEMail = createAsyncThunk(`${namespace}/addEMail`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("medicalPractice/" + payload.id + "/eMail", payload.data);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const updateEMail = createAsyncThunk(`${namespace}/updateEMail`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.patch("medicalPractice/" + payload.id + "/eMail/" + payload.data.id, payload.data);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const deleteEMail = createAsyncThunk(`${namespace}/deleteEMail`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.delete("medicalPractice/" + payload.medicalPracticeId + "/eMail/" + payload.id);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const addPhone = createAsyncThunk(`${namespace}/addPhone`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("medicalPractice/" + payload.id + "/phone", payload.data);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const updatePhone = createAsyncThunk(`${namespace}/updatePhone`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.patch("medicalPractice/" + payload.id + "/phone/" + payload.data.id, payload.data);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const deletePhone = createAsyncThunk(`${namespace}/deletePhone`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.delete("medicalPractice/" + payload.medicalPracticeId + "/phone/" + payload.id);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const updatePerson = createAsyncThunk(`${namespace}/updatePerson`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.patch("medicalPractice/" + payload.medicalPracticeId + "/person/" + payload.personId, payload.data);
		dispatch(setTempSuccess("Die Person wurde erfolgreich gespeichert."));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const removePerson = createAsyncThunk(`${namespace}/removePerson`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.delete("medicalPractice/" + payload.medicalPracticeId + "/person/" + payload.personId);
		dispatch(setTempSuccess("Die Person wurde erfolgreich aus der Praxis entfernt."));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const getLog = createAsyncThunk(`${namespace}/getLog`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.get("medicalPractice/" + payload + "/log");
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const addMedicalPracticeNote = createAsyncThunk(`${namespace}/addMedicalPracticeNote`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("medicalPractice/" + payload.medicalPracticeId + "/note", payload.data);
		return data;
	} catch (error) {
		dispatch(setTempError("Die Notiz konnte nicht erstellt werden."));
		return rejectWithValue(error.response.status);
	}
});

export const updateMedicalPracticeNote = createAsyncThunk(`${namespace}/updateMedicalPracticeNote`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.patch("medicalPractice/" + payload.medicalPracticeId + "/note/" + payload.id, payload.data);
		return data;
	} catch (error) {
		dispatch(setTempError("Die Notiz konnte nicht aktualisiert werden."));
		return rejectWithValue(error.response.status);
	}
});

const medicalPracticeSlice = createSlice({
	name: namespace,
	initialState,
	reducers: {
		clearLog: (state) => {
			state.log = null;
		},
		clearMedicalPractice: (state) => {
			state.data = null;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getMedicalPractice.fulfilled, (state, { payload }) => {
				state.data = payload;
			})
			.addCase(updateMedicalPractice.fulfilled, (state, { payload }) => {
				state.data = payload;
			})
			.addCase(addEMail.fulfilled, (state, { payload }) => {
				state.data.eMails.push(payload);
			})
			.addCase(updateEMail.fulfilled, (state, { payload }) => {
				state.data.eMails = state.data.eMails.map((entry) => {
					if (entry.id === payload.eMail.id) {
						return { ...entry, ...payload.eMail };
					} else {
						return entry;
					}
				});
				state.data.error = payload.error;
			})
			.addCase(deleteEMail.fulfilled, (state, { payload }) => {
				state.data.eMails = state.data.eMails.filter((entry) => entry.id !== payload.id);
				state.data.error = payload.error;
			})
			.addCase(addPhone.fulfilled, (state, { payload }) => {
				state.data.phones.push(payload);
			})
			.addCase(updatePhone.fulfilled, (state, { payload }) => {
				state.data.phones = state.data.phones.map((entry) => {
					if (entry.id === payload.phone.id) {
						return payload.phone;
					} else {
						return entry;
					}
				});
				state.data.error = payload.error;
			})
			.addCase(deletePhone.fulfilled, (state, { payload }) => {
				state.data.phones = state.data.phones.filter((entry) => entry.id !== payload.id);
				state.data.error = payload.error;
			})
			.addCase(updatePerson.fulfilled, (state, { payload }) => {
				state.data.users = payload;
			})
			.addCase(removePerson.fulfilled, (state, { payload }) => {
				state.data.users = payload;
			})
			.addCase(getLog.fulfilled, (state, { payload }) => {
				state.log = payload;
			})
			.addCase(getLog.rejected, (state, { payload }) => {
				state.log = [];
			})
			.addCase(deleteMedicalPractice.fulfilled, (state, { payload }) => {
				return initialState;
			})
			.addCase(addMedicalPracticeNote.fulfilled, (state, { payload }) => {
				state.data.notes = payload;
			})
			.addCase(updateMedicalPracticeNote.fulfilled, (state, { payload }) => {
				state.data.notes = payload;
			})
			.addCase(logout.fulfilled, (state, { payload }) => {
				return initialState;
			});
	}
});

export const { clearMedicalPractice, clearLog } = medicalPracticeSlice.actions;

export default medicalPracticeSlice.reducer;
