import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Phones from "./Phones/Phones";
import EMailAddresses from "./EMailAddresses/EMailAddresses";
import AreasOfExpertise from "./AreasOfExpertise/AreasOfExpertise";
import Docbox from "./Docbox/Docbox";
import PanelError from "../../../../ui/PanelError/PanelError";
import ServiceTypes from "./ServiceTypes/ServiceTypes";
import PersonalInformations from "./PersonalInformations/PersonalInformations";
import MedicalPractices from "./MedicalPractices/MedicalPractices";

import styles from "./Account.module.css";
import Note from "../../../Note/Note";

import { ask, clear } from "../../../../features/ask/askSlice";
import { deletePerson } from "../../../../features/person/personSlice";

import * as askTypes from "../../../../shared/askTypes";
import * as routes from "../../../../shared/routes";

const Account = () => {
	const person = useSelector((state) => state.person.person);
	const answer = useSelector((state) => state.ask.answer);
	const dispatch = useDispatch();
	const isDoctor = person.type === "doctor";

	useEffect(() => {
		if (answer) {
			if (answer.type === askTypes.DELETE_PERSON && answer.value !== null) {
				dispatch(deletePerson({ personId: answer.value, redirect: routes.PERSONS }));
				dispatch(clear());
			}
		}
	}, [answer, dispatch]);

	const askDeletePerson = () => {
		dispatch(
			ask({
				type: askTypes.DELETE_PERSON,
				title: "Person löschen",
				question: "Möchtest Du diese Person wirklich löschen?",
				buttons: [
					{ label: "Ja, löschen", answer: person.id },
					{ label: "Abbrechen", answer: null }
				]
			})
		);
	};

	return (
		<div className={styles.account}>
			<PanelError errors={person.error} />
			<Docbox docbox={person.docbox} personId={person.id} />
			<PersonalInformations person={person} />
			<MedicalPractices personId={person.id} medicalPractices={person.practices} personName={person.firstname + " " + person.lastname} />
			<Phones personId={person.id} />
			<EMailAddresses personId={person.id} />
			{isDoctor && <AreasOfExpertise personId={person.id} />}
			{isDoctor && <ServiceTypes serviceTypeIds={person.serviceTypes} personId={person.id} />}
			{person.practices.length > 0 ? (
				<Note>Die Person kann nur gelöscht werden, wenn sie keiner Praxis zugeteilt ist.</Note>
			) : (
				<div>
					<button type="button" onClick={askDeletePerson}>
						Person löschen
					</button>
				</div>
			)}
		</div>
	);
};

export default Account;
