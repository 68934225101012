import styles from "./TableRow.module.css";
import { ReactComponent as ErrorIcon } from "../../../assets/images/icon_warning_red.svg";

const TableRow = ({ row, fields, rowClickAction }) => {
    const result = fields.map((field) => (
        <td key={field.key} className={field.align ? styles[field.align] : ""}>
            {field.showField ? field.showField(row) : row[field.key]}
        </td>
    ));

    if (row.error !== undefined) {
        result.unshift(<td key="error">{row.error ? <ErrorIcon className={styles.errorIcon} /> : ""}</td>);
    }

    return <tr onClick={rowClickAction ? () => rowClickAction(row) : null}>{result}</tr>;
};

export default TableRow;
