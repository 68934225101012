import { Polygon, InfoWindow } from "@react-google-maps/api";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addZipToServiceCircleTime, removeZipFromServiceCircleTime } from "../../../../../features/geographyPlan/geographyPlanSlice";

import { combineColors } from "../../../../../shared/functions";

const Areas = ({ serviceCircles, mouseOverServiceCircle, locked }) => {
	const dispatch = useDispatch();
	const regions = useSelector((state) => state.regions);
	const selectedTimeId = useSelector((state) => state.geographyPlan.selectedTimeId);
	const colors = useSelector((state) => state.values.color);
	const { serviceCircleId } = useSelector((state) => state.category.selected);

	const zips = useSelector((state) => state.geographyPlan.plan.times).find((entry) => entry.id === selectedTimeId)?.zips || [];

	const [infoWindow, setInfoWindow] = useState(null);

	const zipClick = (regionId, assignedServiceCircleTimeZips) => {
		if (!serviceCircleId) {
			if (assignedServiceCircleTimeZips.length > 0) {
				dispatch(removeZipFromServiceCircleTime({ timeId: selectedTimeId, id: assignedServiceCircleTimeZips[0].id }));
			}
		} else {
			if (assignedServiceCircleTimeZips.length > 0 && assignedServiceCircleTimeZips.find((entry) => entry.serviceCircleId === serviceCircleId)) {
				const { id } = assignedServiceCircleTimeZips.find((entry) => entry.serviceCircleId === serviceCircleId);
				dispatch(removeZipFromServiceCircleTime({ timeId: selectedTimeId, id }));
			} else {
				dispatch(addZipToServiceCircleTime({ serviceCircleId, timeId: selectedTimeId, regionId }));
			}
		}
	};

	const polygons = regions
		? regions.map((region) => {
				const coordinates = region.coordinates.map((point) => ({ lat: point[1], lng: point[0] }));
				const assignedServiceCircleTimeZips = zips.filter((entry) => entry.regionId === region.id);
				return {
					regionId: region.id,
					assignedServiceCircleTimeZips: assignedServiceCircleTimeZips,
					coordinates
				};
		  })
		: [];

	const hover = (e, id) => {
		const region = regions.find((entry) => entry.id === id);

		const topPoint = {
			lat: 0,
			lng: 0
		};

		region.coordinates.forEach((entry) => {
			if (entry[1] > topPoint.lat) {
				topPoint.lat = entry[1];
				topPoint.lng = entry[0];
			}
		});

		const serviceCircleIds = zips.filter((entry) => entry.regionId === id).map((entry) => entry.serviceCircleId);
		const assignedServiceCircles = serviceCircles.filter((entry) => serviceCircleIds.includes(entry.id)).map((entry) => entry.name);
		const content = (
			<div>
				<div>
					<strong>
						{region.zip} {region.city}
					</strong>
				</div>
				{assignedServiceCircles.map((name, index) => (
					<div key={index}>{name}</div>
				))}
			</div>
		);
		setInfoWindow({ position: new window.google.maps.LatLng(topPoint.lat, topPoint.lng), content, regionId: id });
	};

	const leave = () => {
		setInfoWindow(null);
	};

	const resultPolygons = polygons.map((polygon) => {
		const includedServiceCircleIds = polygon.assignedServiceCircleTimeZips.map((entry) => entry.serviceCircleId);
		const colorIds = serviceCircles.filter((entry) => includedServiceCircleIds.includes(entry.id)).map((entry) => entry.colorId);
		const usedColors = colors.filter((color) => colorIds.includes(color.id)).map((color) => color.color);
		const fillColor = usedColors.length === 0 ? "#ffffff" : usedColors.length === 1 ? "#" + usedColors[0] : "#" + combineColors(usedColors);
		const fillOpacity = includedServiceCircleIds.length > 0 && (mouseOverServiceCircle === "" || includedServiceCircleIds.includes(mouseOverServiceCircle)) ? 0.8 : 0.4;

		let strokeColor = "#666";
		let zIndex = 0;
		let strokeWeight = 1;

		if (infoWindow && infoWindow.regionId === polygon.regionId) {
			strokeColor = "#d8011e";
			zIndex = 1;
			strokeWeight = 3;
		}

		const options = {
			strokeColor: strokeColor,
			zIndex: zIndex,
			strokeWeight: strokeWeight,
			fillOpacity: fillOpacity,
			fillColor: fillColor,
			clickable: serviceCircleId > 0 || includedServiceCircleIds.length > 0
		};
		if (locked) {
			return <Polygon key={polygon.regionId} paths={polygon.coordinates} options={options} onMouseOver={(e) => hover(e, polygon.regionId)} onMouseOut={leave} />;
		} else {
			return <Polygon key={polygon.regionId} paths={polygon.coordinates} options={options} onMouseOver={(e) => hover(e, polygon.regionId)} onMouseOut={leave} onClick={() => zipClick(polygon.regionId, polygon.assignedServiceCircleTimeZips)} />;
		}
	});

	const infoWindowLoad = (infoWindow) => {
		//console.log("infoWindow: ", infoWindow);
		//infoWindow.open();
	};

	return (
		<Fragment>
			{infoWindow && (
				<InfoWindow
					position={infoWindow.position}
					onLoad={infoWindowLoad}
					onCloseClick={(e) => {
						setInfoWindow(null);
					}}
				>
					{infoWindow.content}
				</InfoWindow>
			)}
			{resultPolygons}
		</Fragment>
	);
};

export default Areas;
