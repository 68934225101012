import { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Panel from "../../../../hoc/Panel/Panel";
import Window from "../../../../hoc/Window/Window";
import DateRangeSelector from "../../../DateRangeSelector/DateRangeSelector";
import FooterButtons from "../../../../ui/FooterButtons/FooterButtons";
import RadioSelect from "../../../../ui/RadioSelect/RadioSelect";
import MedicalPracticeChooser from "../../../MedicalPracticeChooser/MedicalPracticeChooser";

import { getDateObjectFromMySqlDate, getMySqlDateFromDateObject } from "../../../../shared/functions";
import { createRosterPeriode, getLastUsedDateOfRosterPeriod, updateRosterPeriode } from "../../../../features/category/categorySlice";

import styles from "./EditRosterPeriod.module.css";
import { getMedicalPractices } from "../../../../features/medicalPractices/medicalPracticesSlice";
import { SERVICE_LOCATION_EMERGENCYPRACTICE, SERVICE_LOCATION_MEDICALPRACTICE, SERVICE_LOCATION_MOBILE } from "../../../../shared/globals";
import FieldLabel from "../../../../ui/FieldLabel/FieldLabel";
import RadioButton from "../../../../ui/RadioSelect/RadioButton/RadioButton";

const service = [
    { id: SERVICE_LOCATION_MEDICALPRACTICE, value: "Beim ausführenden Arzt" },
    { id: SERVICE_LOCATION_MOBILE, value: "Mobil (MAV)" },
    { id: SERVICE_LOCATION_EMERGENCYPRACTICE, value: "In folgender Praxis:" }
];

const getLastBillingPeriod = (rosterPeriodes, serviceCircleId) => {
    const rosterPeriod = rosterPeriodes.find((entry) => entry.serviceCircleId === serviceCircleId);
    if (rosterPeriod) {
        return {
            id: rosterPeriod.billingPeriodId,
            name: rosterPeriod.billingPeriodName
        };
    }
    return null;
};

const getStartDate = (rosterPeriod, rosterPeriodes, id) => {
    const startDate = rosterPeriod ? getDateObjectFromMySqlDate(rosterPeriod.start) : rosterPeriodes && rosterPeriodes.length > 0 ? getDateObjectFromMySqlDate(rosterPeriodes[0].end) : null;
    if (startDate && !id) {
        startDate.setDate(startDate.getDate() + 1);
    }
    return startDate;
};

const EditRosterPeriod = ({ id, cancel, rosterPeriodes, serviceCircleId }) => {
    const dispatch = useDispatch();
    const rosterPeriod = id ? rosterPeriodes.find((rosterPeriod) => rosterPeriod.id === id) : null;
    const lastUsedDate = rosterPeriod ? (rosterPeriod.lastUsedDate ? getDateObjectFromMySqlDate(rosterPeriod.lastUsedDate) : null) : null;
    const [chooseMedicalPractice, setChooseMedicalPractice] = useState(false);
    const medicalPractices = useSelector((state) => state.medicalPractices.entries);

    const lastBillingPeriod = getLastBillingPeriod(rosterPeriodes, serviceCircleId);

    const [period, setPeriode] = useState({
        start: getStartDate(rosterPeriod, rosterPeriodes, id),
        end: rosterPeriod ? (rosterPeriod.end ? getDateObjectFromMySqlDate(rosterPeriod.end) : null) : null,
        serviceLocation: rosterPeriod ? rosterPeriod.serviceLocation : SERVICE_LOCATION_MEDICALPRACTICE,
        medicalPracticeId: rosterPeriod && rosterPeriod.serviceLocation === SERVICE_LOCATION_EMERGENCYPRACTICE ? rosterPeriod.serviceMedicalPracticeId : null,
        billingPeriodId: lastBillingPeriod ? lastBillingPeriod.id : null,
        billingPeriodName: ""
    });

    const medicalPractice = period.medicalPracticeId > 0 && medicalPractices ? medicalPractices.find((entry) => entry.id === period.medicalPracticeId) : null;

    useEffect(() => {
        if (id) {
            dispatch(getLastUsedDateOfRosterPeriod(id));
        }
    }, [id, dispatch]);

    useEffect(() => {
        if (!medicalPractices && period.medicalPracticeId > 0) {
            dispatch(getMedicalPractices());
        }
    }, [dispatch, medicalPractices, period.medicalPracticeId]);

    const save = () => {
        const data = { ...period };
        data.start = getMySqlDateFromDateObject(data.start);
        data.end = getMySqlDateFromDateObject(data.end);
        if (id) {
            dispatch(updateRosterPeriode({ rosterPeriodId: id, data }));
        } else {
            data.serviceCircleId = serviceCircleId;
            dispatch(createRosterPeriode(data));
        }
        cancel();
    };

    const changeServiceLocation = (value) => {
        setPeriode((prev) => ({ ...prev, serviceLocation: value }));
        if (value === SERVICE_LOCATION_EMERGENCYPRACTICE && !medicalPractice) {
            setChooseMedicalPractice(true);
        } else {
            setMedicalPracticeId(null);
        }
    };

    const setDates = useCallback((dates) => {
        setPeriode((prev) => ({ ...prev, start: dates.start, end: dates.end }));
    }, []);

    const setMedicalPracticeId = (medicalPracticeId) => {
        setPeriode((prev) => ({ ...prev, medicalPracticeId }));
    };

    const setBillingPeriodId = (billingPeriodId) => {
        setPeriode((prev) => ({ ...prev, billingPeriodId: billingPeriodId || null }));
    };

    const disabled = !period.start || !period.end || (period.serviceLocation === SERVICE_LOCATION_EMERGENCYPRACTICE && !period.medicalPracticeId) || (period.billingPeriodId === null && period.billingPeriodName.trim().length < 3);

    return (
        <Fragment>
            <Window>
                <Panel title={id ? "Dienstplan Enddatum ändern" : "Neuer Dienstplan"} size="small" padding>
                    <div className={styles.content}>
                        <DateRangeSelector startDate={period.start} endDate={period.end} minEndDate={lastUsedDate} setPeriode={setDates} startDateProtected={period.start !== null} />
                        <div>
                            <RadioSelect data={service} label="Durchführung Notfalldienst" keyName="id" valueName="value" selected={period.serviceLocation} setSelected={changeServiceLocation} />
                            {period.serviceLocation === SERVICE_LOCATION_EMERGENCYPRACTICE && (
                                <div className={styles.medicalPractice}>
                                    {medicalPractice ? (
                                        <Fragment>
                                            {medicalPractice.name}, {medicalPractice.city}{" "}
                                            <button type="button" onClick={() => setChooseMedicalPractice(true)}>
                                                Ändern
                                            </button>
                                        </Fragment>
                                    ) : (
                                        <button type="button" onClick={() => setChooseMedicalPractice(true)}>
                                            Wählen
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>
                        <FieldLabel label="Abrechnungsperiode">
                            {lastBillingPeriod && <RadioButton name={"Abrechnungsperiode " + lastBillingPeriod.name} value={lastBillingPeriod.id} selected={period.billingPeriodId} set={setBillingPeriodId} />}
                            <RadioButton name="Neu:" value={0} selected={period.billingPeriodId || 0} set={setBillingPeriodId} />
                            {period.billingPeriodId === null && (
                                <div className={styles.billingPeriodName}>
                                    Abrechnungsperiode
                                    <input type="text" value={period.billingPeriodName} onChange={(e) => setPeriode((prev) => ({ ...prev, billingPeriodName: e.target.value }))} maxLength={32} />
                                </div>
                            )}
                        </FieldLabel>
                    </div>
                    <FooterButtons paddingTop>
                        <button type="button" onClick={cancel}>
                            Abbrechen
                        </button>
                        <button type="button" onClick={save} disabled={disabled}>
                            OK
                        </button>
                    </FooterButtons>
                </Panel>
            </Window>
            {chooseMedicalPractice && <MedicalPracticeChooser medicalPracticeId={period.medicalPracticeId} setMedicalPracticeId={setMedicalPracticeId} close={() => setChooseMedicalPractice(false)} />}
        </Fragment>
    );
};

export default EditRosterPeriod;
