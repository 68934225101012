import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GeographyPlanSelector from "../../components/Geography/GeographyPlanSelector/GeographyPlanSelector";
import BaseMapSelector from "../../components/Geography/BaseMapSelector/BaseMapSelector";
import YearCalendar from "../../components/Geography/YearCalendar/YearCalendar";
import YearSelector from "../../components/YearSelector/YearSelector";
import { showCategorySelector, hideCategorySelector } from "../../features/category/categorySlice";
import { getGeographyPlans, getGeographyRoster } from "../../features/geographyPlan/geographyPlanSlice";
import Panel from "../../hoc/Panel/Panel";

import styles from "./Geography.module.css";

const Geography = () => {
	const rosterPeriodes = useSelector((state) => state.category.rosterPeriodes);
	const { serviceTypeId, rosterPeriodId } = useSelector((state) => state.category.selected);

	const rosterPeriodStartYear = rosterPeriodId ? rosterPeriodes.find((entry) => entry.id === rosterPeriodId).start.split("-")[0] : new Date().getFullYear();
	const [year, setYear] = useState(parseInt(rosterPeriodStartYear, 10));

	const planServiceTypeId = useSelector((state) => state.geographyPlan.serviceTypeId);
	const loadedYear = useSelector((state) => state.geographyPlan.year);

	const plans = useSelector((state) => state.geographyPlan.entries);
	const baseMaps = useSelector((state) => state.geographyPlan.baseMaps);
	const selectedBaseMapId = useSelector((state) => state.geographyPlan.selectedBaseMapId);
	const selectedBaseMap = baseMaps && selectedBaseMapId ? baseMaps.find((entry) => entry.id === selectedBaseMapId) : null;
	const selectedPlanId = useSelector((state) => state.geographyPlan.selectedPlanId);
	const selectedPlan = plans && selectedPlanId ? plans.find((entry) => entry.id === selectedPlanId) : null;

	const dispatch = useDispatch();

	useEffect(() => {
		if (serviceTypeId === "") {
			dispatch(showCategorySelector({ requiredLevel: 2 }));
		}
	}, [dispatch, serviceTypeId]);

	useEffect(() => {
		return () => {
			dispatch(hideCategorySelector());
		};
	}, [dispatch]);

	useEffect(() => {
		if (serviceTypeId && planServiceTypeId !== serviceTypeId) {
			dispatch(getGeographyPlans({ serviceTypeId }));
		}
	}, [dispatch, planServiceTypeId, serviceTypeId]);

	useEffect(() => {
		if (year !== loadedYear && serviceTypeId) {
			dispatch(getGeographyRoster({ serviceTypeId, year }));
		}
	}, [dispatch, year, loadedYear, serviceTypeId]);

	if (planServiceTypeId !== serviceTypeId || !plans) {
		return null;
	}

	return (
		<Panel size="maxWidth" title="Geographie" rosterPeriode categorieLevels={2} padding>
			{serviceTypeId && (
				<Fragment>
					<div className={styles.yearSelector}>
						<YearSelector year={year} setYear={setYear} />
					</div>
					<div className={styles.content}>
						<div className={styles.mainPanel}>
							<YearCalendar baseMaps={baseMaps} plans={plans} serviceTypeId={serviceTypeId} selectedPlan={selectedPlan} selectedBaseMap={selectedBaseMap} year={year} />
						</div>
						<div className={styles.sidePanel}>
							<BaseMapSelector serviceTypeId={serviceTypeId} />
							<GeographyPlanSelector serviceTypeId={serviceTypeId} />
						</div>
					</div>
				</Fragment>
			)}
		</Panel>
	);
};

export default Geography;
