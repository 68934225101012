import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelection, hideCategorySelector } from "../../features/category/categorySlice";
import Window from "../../hoc/Window/Window";
import CategoryColView from "./CategoryCol/CategoryColView";
import Panel from "../../hoc/Panel/Panel";
import DisplayDate from "../Display/DisplayDate/DisplayDate";

import styles from "./CategorySelector.module.css";
import FooterButtons from "../../ui/FooterButtons/FooterButtons";

const CategorySelector = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const selector = useSelector((state) => state.category.selector);
	const categories = useSelector((state) => state.category.categories);
	const serviceTypes = useSelector((state) => state.category.serviceTypes);
	const serviceCircles = useSelector((state) => state.category.serviceCircles);
	const rosterPeriodes = useSelector((state) => state.category.rosterPeriodes);

	const { categoryId, serviceTypeId, serviceCircleId, rosterPeriodId } = useSelector((state) => state.category.selected);

	const [newSelection, setNewSelection] = useState({
		categoryId,
		serviceTypeId,
		serviceCircleId,
		rosterPeriodId
	});

	const [previusSelection] = useState({
		categoryId,
		serviceTypeId,
		serviceCircleId,
		rosterPeriodId
	});

	const requiredLevelReached =
		(selector.requiredLevel === 1 && newSelection.categoryId > 0) || (selector.requiredLevel === 2 && newSelection.serviceTypeId > 0) || (selector.requiredLevel === 3 && newSelection.serviceCircleId > 0) || (selector.requiredLevel === 4 && newSelection.rosterPeriodId > 0);

	if (!categories) {
		return null;
	}

	const selectCategory = (id) => {
		setNewSelection({ categoryId: id, serviceTypeId: 0, serviceCircleId: 0, rosterPeriodId: 0 });
	};

	const selectServiceType = (id) => {
		setNewSelection((prev) => ({ ...prev, serviceTypeId: id, serviceCircleId: 0, rosterPeriodId: 0 }));
	};

	const selectServiceCircle = (id) => {
		setNewSelection((prev) => ({ ...prev, serviceCircleId: id, rosterPeriodId: 0 }));
	};

	const selectRosterPeriode = (id) => {
		setNewSelection((prev) => ({ ...prev, rosterPeriodId: id }));
	};

	const cancel = () => {
		if (
			selector.mode === "select" &&
			!((selector.requiredLevel === 1 && previusSelection.categoryId > 0) || (selector.requiredLevel === 2 && previusSelection.serviceTypeId > 0) || (selector.requiredLevel === 3 && previusSelection.serviceCircleId > 0) || (selector.requiredLevel === 4 && previusSelection.rosterPeriodId > 0))
		) {
			navigate("/");
		}
		dispatch(hideCategorySelector());
	};

	const choose = () => {
		if (selector.mode === "insert" || JSON.stringify(previusSelection) !== JSON.stringify(newSelection)) {
			dispatch(setSelection(newSelection));
		}
		dispatch(hideCategorySelector());
	};

	// showOnlyCategories: null,
	// showOnlyServiceTypes: null,
	// showOnlyServiceCircles: null,
	// hideRosterPeriodes: null

	let displayCategories = [...categories];
	let displayServiceTypes = [...serviceTypes];
	let displayRosterPeriodes = [...rosterPeriodes];
	if (selector.showOnlyServiceCircles) {
		const serviceTypeIds = serviceCircles.filter((entry) => selector.showOnlyServiceCircles.includes(entry.id)).map((entry) => entry.serviceTypeId);
		displayServiceTypes = serviceTypes.filter((entry) => serviceTypeIds.includes(entry.id));
		const categoryIds = displayServiceTypes.reduce((categoryIds, entry) => {
			return categoryIds.includes(entry.categoryId) ? categoryIds : [...categoryIds, entry.categoryId];
		}, []);
		displayCategories = displayCategories.filter((entry) => categoryIds.includes(entry.id));
	} else if (selector.showOnlyServiceTypes) {
		displayServiceTypes = serviceTypes.filter((entry) => selector.showOnlyServiceTypes.includes(entry.id));
		const categoryIds = serviceTypes.filter((entry) => selector.showOnlyServiceTypes.includes(entry.id)).map((entry) => entry.categoryId);
		displayCategories = displayCategories.filter((entry) => categoryIds.includes(entry.id));
	}
	if (selector.hideRosterPeriodes) {
		displayRosterPeriodes = displayRosterPeriodes.filter((entry) => !selector.hideRosterPeriodes.includes(entry.id));
	}

	return (
		<Window classes={[styles.window]}>
			<Panel title="Selektor" size={selector.requiredLevel < 3 && selector.maxRequiredLevel < 3 ? "small" : "large"}>
				<div className={styles.browser}>
					<CategoryColView title="Fachbereiche" items={displayCategories} selectedId={newSelection.categoryId} select={selectCategory} colCount={selector.requiredLevel} />
					{newSelection.categoryId > 0 && (selector.requiredLevel > 1 || selector.maxRequiredLevel > 1) && (
						<CategoryColView title="Dienstarten" items={displayServiceTypes.filter((entry) => entry.categoryId === newSelection.categoryId)} selectedId={newSelection.serviceTypeId} select={selectServiceType} end={selector.requiredLevel < 3} colCount={selector.requiredLevel} />
					)}
					{newSelection.serviceTypeId > 0 && (selector.requiredLevel > 2 || selector.maxRequiredLevel > 2) && (
						<CategoryColView title="Dienstkreis" items={serviceCircles.filter((entry) => entry.serviceTypeId === newSelection.serviceTypeId)} selectedId={newSelection.serviceCircleId} select={selectServiceCircle} end={selector.requiredLevel < 4} colCount={selector.requiredLevel} />
					)}
					{newSelection.serviceCircleId > 0 && (selector.requiredLevel > 3 || selector.maxRequiredLevel > 3) && (
						<CategoryColView
							title="Dienstpläne"
							items={displayRosterPeriodes
								.filter((entry) => entry.serviceCircleId === newSelection.serviceCircleId)
								.map((entry) => ({
									...entry,
									name: (
										<Fragment>
											<DisplayDate date={entry.start} short /> - <DisplayDate date={entry.end} short />
										</Fragment>
									)
								}))}
							selectedId={newSelection.rosterPeriodId}
							select={selectRosterPeriode}
							colCount={selector.requiredLevel}
							end
						/>
					)}
				</div>
				<FooterButtons padding>
					<button type="button" onClick={cancel}>
						Abbrechen
					</button>
					<button type="button" onClick={choose} disabled={!requiredLevelReached}>
						OK
					</button>
				</FooterButtons>
			</Panel>
		</Window>
	);
};

export default CategorySelector;
