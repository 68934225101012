import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPersons } from "../../features/persons/personsSlice";

import styles from "./PersonChooser.module.css";
import Window from "../../hoc/Window/Window";
import Panel from "../../hoc/Panel/Panel";
import SearchFilter from "../SearchFilter/SearchFilter";
import FooterButtons from "../../ui/FooterButtons/FooterButtons";

const PersonChooser = ({ personId, setPersonId, close, title = "Person wählen", exclude = [] }) => {
	const [filterText, setFilterText] = useState("");
	const [newPersonId, setNewPersonId] = useState(personId);
	const persons = useSelector((state) => state.persons.entries);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!persons) {
			dispatch(getPersons());
		}
	}, [dispatch, persons]);

	useEffect(() => {
		document.getElementById(`person${personId}`)?.scrollIntoView({ block: "center" });
	}, [personId]);

	const contains = (row) => {
		let searchStrings = [filterText];
		if (row.selected) {
			return true;
		}
		if (filterText.indexOf(" ") > -1) {
			searchStrings = filterText.split(" ");
		}

		const found = searchStrings.map((search) => {
			search = search.toLowerCase();
			if (row.firstname.toLowerCase().includes(search) || row.lastname.toLowerCase().includes(search)) {
				return 1;
			} else {
				return 0;
			}
		});
		const foundCount = found.reduce((a, b) => a + b, 0);
		return foundCount === searchStrings.length;
	};

	if (!persons) {
		return null;
	}

	const select = (id) => {
		setNewPersonId(id);
	};

	const ok = () => {
		setPersonId(newPersonId);
		close();
	};

	const setAndClose = (id) => {
		setPersonId(id);
		close();
	};

	return (
		<Window>
			<Panel title={title} size="medium" className={styles.panel}>
				<div className={styles.content}>
					<div className={styles.filter}>
						<SearchFilter filterText={filterText} setFilterText={setFilterText} focusOnLoad />
					</div>
					<header className={styles.row + " " + styles.header}>
						<div>Vorname</div>
						<div>Nachname</div>
					</header>
					<div className={styles.list}>
						{persons
							.filter((person) => contains(person) && !exclude.includes(person.id))
							.map((person) => (
								<div id={`person${person.id}`} className={styles.row + (newPersonId === person.id ? ` ${styles.selected}` : "")} key={person.id} onClick={() => select(person.id)} onDoubleClick={() => setAndClose(person.id)}>
									<div>{person.firstname}</div>
									<div>{person.lastname}</div>
								</div>
							))}
					</div>
					<FooterButtons padding>
						<button type="button" onClick={close}>
							Abbrechen
						</button>
						<button type="button" onClick={ok} disabled={!newPersonId}>
							OK
						</button>
					</FooterButtons>
				</div>
			</Panel>
		</Window>
	);
};

export default PersonChooser;
