import styles from "./ViewChooser.module.css";

import { ReactComponent as Table } from "../../../../assets/images/icon_listtabel.svg";
import { ReactComponent as Calendar } from "../../../../assets/images/icon_calendar_neutral.svg";
import * as routes from "../../../../shared/routes";
import { useLocation, useNavigate } from "react-router-dom";

const ViewChooser = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const table = routes.ROSTER + "/" + routes.ROSTER_ALLOCATION + "/" + routes.ROSTER_ALLOCATION_TABLE;
    const calendar = routes.ROSTER + "/" + routes.ROSTER_ALLOCATION + "/" + routes.ROSTER_ALLOCATION_CALENDAR;

    return (
        <div className={styles.viewChooser}>
            <div className={pathname === table ? styles.selected : ""} onClick={() => navigate(table)}>
                <Table />
            </div>
            <div className={pathname === calendar ? styles.selected : ""} onClick={() => navigate(calendar)}>
                <Calendar />
            </div>
        </div>
    );
};

export default ViewChooser;
