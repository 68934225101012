import TeaserButton from "../../components/TeaserButton/TeaserButton";
import Panel from "../../hoc/Panel/Panel";
import * as routes from "../../shared/routes";

import { ReactComponent as StructureIcon } from "../../assets/images/structure.svg";
import { ReactComponent as RosterIcon } from "../../assets/images/roster.svg";
import { ReactComponent as PublicHolidayIcon } from "../../assets/images/publicHoliday.svg";
import { ReactComponent as GeographyIcon } from "../../assets/images/geography.svg";
import { ReactComponent as DoctorIcon } from "../../assets/images/doctor.svg";

import styles from "./Home.module.css";

const Home = () => {
	return (
		<Panel title="Start" size="large" padding>
			<div className={styles.teaserButtons}>
				<TeaserButton name="Struktur" url={routes.STRUCTURE} icon={<StructureIcon />} />
				<TeaserButton name="Geographie" url={routes.GEOGRAPHY} icon={<GeographyIcon />} />
				<TeaserButton name="Feiertage" url={routes.PUBLIC_HOLIDAYS} icon={<PublicHolidayIcon />} />
				<TeaserButton name="Dienstplan" url={routes.ROSTER + "/" + routes.ROSTER_STATE} icon={<RosterIcon />} />
				<TeaserButton name="Personen & Praxen" url={routes.PERSONS} icon={<DoctorIcon />} />
			</div>
		</Panel>
	);
};

export default Home;
