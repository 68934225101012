import styles from "./ColorPanel.module.css";

const ColorPanel = ({ color = null, narrow = false, children }) => {
	const colorClass = [styles.colorBar];
	color && colorClass.push(styles[color]);
	const contentClass = [styles.content];
	narrow && contentClass.push(styles.narrow);
	return (
		<div className={styles.colorPanel}>
			<div className={colorClass.join(" ")}></div>
			<div className={contentClass.join(" ")}>{children}</div>
		</div>
	);
};

export default ColorPanel;
