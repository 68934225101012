import { monthNames } from "../../../shared/globals";

const DisplayDate = ({ date, monthName = null, short = false }) => {
	let theDate = null;

	if (typeof date === "string") {
		if (date.match(/^\d{4}-\d{1,2}-\d{1,2}/)) {
			const [year, month, day] = date.split("-");
			theDate = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
		}
	} else if (typeof date.getMonth === "function") {
		theDate = date;
	}
	if (!theDate) {
		return null;
	}

	const prefixZero = (value, chars = 2) => {
		value = value.toString();
		while (value.toString().length < chars) {
			value = "0" + value;
		}
		return value;
	};

	if (short) {
		return prefixZero(theDate.getDate()) + "." + prefixZero(theDate.getMonth() + 1) + "." + theDate.getFullYear();
	}
	return theDate.getDate() + "." + (monthName ? " " + monthNames[theDate.getMonth()] : theDate.getMonth() + 1).toString() + (monthName ? " " : ".") + theDate.getFullYear();
};

export default DisplayDate;
