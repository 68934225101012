import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setTempError } from "../features/message/messageSlice";

const TempError = () => {
	const error = useSelector((state) => state.message.tempError);
	const dispatch = useDispatch();
	const clearTempError = useCallback(() => dispatch(setTempError(null)), [dispatch]);
	const [showError, setShowError] = useState(false);

	useEffect(() => {
		if (error) {
			setShowError(true);
			window.setTimeout(() => setShowError(false), 4500);
			window.setTimeout(clearTempError, 5000);
		}
	}, [error, clearTempError]);

	return (
		<div className={"tempError" + (showError ? " show" : "")}>
			<div>{error}</div>
		</div>
	);
};

export default TempError;
