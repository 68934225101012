import { Link, useLocation } from "react-router-dom";
import styles from "./TabHeader.module.css";

const TabHeader = ({ tabs, children }) => {
    const { pathname } = useLocation();

    return (
        <header className={styles.tabHeader}>
            {tabs.map((tab) => {
                const isActive = pathname.indexOf(tab.location) === 0;
                return (
                    <div className={styles.tab + (isActive ? " " + styles.active : "")} key={tab.location}>
                        <Link to={tab.location}>{tab.name}</Link>
                    </div>
                );
            })}
            <div>{children}</div>
        </header>
    );
};

export default TabHeader;
