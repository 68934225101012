import { useState } from "react";
import { useDispatch } from "react-redux";
import MutableString from "../../MutableString/MutableString";

import ColHeader from "../Col/ColHeader/ColHeader";
import Col from "../Col/Col";
import CategoryColItem from "./CategoryColItem/CategoryColItem";

import ColContent from "../Col/ColContent/ColContent";
import ColorIcon from "../../ColorIcon/ColorIcon";
import ColorChooser from "../../ColorChooser/ColorChooser";

const CategoryColEdit = ({ title, items, select, unselect, create, update, updateColor = null, selectedId = null, addTitle = null, messageTitle, parentId = 0, end = false, editDetail }) => {
    const [editing, setEditing] = useState(false);
    const [chooseColor, setChooseColor] = useState(0);
    const dispatch = useDispatch();

    const updateValue = (id, value) => {
        if (id) {
            dispatch(update({ id, data: { name: value }, success: messageTitle + " wurde erfolgreich in «" + value + "» umbenannt." }));
        } else {
            dispatch(create({ parentId, data: { name: value }, success: messageTitle + " «" + value + "» wurde erfolgreich erstellt." }));
        }
    };

    const selectMenu = (id) => {
        setEditing(false);
        dispatch(select(id));
    };

    const addItem = () => {
        if (selectedId) {
            dispatch(unselect());
        }
        setEditing(true);
    };

    const changeColor = (id) => {
        setChooseColor(id);
    };

    const setColor = (colorId) => {
        dispatch(updateColor({ id: chooseColor, data: { colorId }, success: "Die Farbe wurde erfolgreich gespeichert." }));
        setChooseColor(0);
    };

    return (
        <Col colCount={4}>
            <ColHeader title={title} add={addTitle ? () => addItem() : null} />
            <ColContent>
                {editing && !selectedId && (
                    <CategoryColItem key={0} selected>
                        <div>
                            <MutableString value="" directEdit={true} editing={setEditing} existingValues={items.map((item) => item.name)} id={0} update={updateValue} />
                        </div>
                    </CategoryColItem>
                )}
                {items.map((entry) => {
                    if (selectedId === entry.id) {
                        return (
                            <CategoryColItem key={entry.id} selected showArrow={!end && !editing} edit={editDetail}>
                                <div>
                                    {entry.colorId !== undefined && !editing && <ColorIcon colorId={entry.colorId} action={() => changeColor(entry.id)} />}
                                    <MutableString value={entry.name} existingValues={items.filter((item) => item.id !== entry.id).map((item) => item.name)} id={entry.id} update={updateValue} editing={setEditing} />
                                </div>
                            </CategoryColItem>
                        );
                    } else {
                        return (
                            <CategoryColItem key={entry.id} showArrow={!end} action={() => selectMenu(entry.id)}>
                                <div>
                                    {entry.colorId !== undefined && <ColorIcon colorId={entry.colorId} />}
                                    {entry.name}
                                </div>
                            </CategoryColItem>
                        );
                    }
                })}
            </ColContent>
            {chooseColor > 0 && <ColorChooser usedColors={items.map((entry) => entry.colorId)} selected={items.find((entry) => entry.id === chooseColor).colorId} cancel={() => setChooseColor(0)} setColor={setColor} />}
        </Col>
    );
};

export default CategoryColEdit;
