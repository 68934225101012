import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../shared/axios";
import { logout } from "../auth/authSlice";
import { selectCategory, selectServiceCircle, selectRosterPeriode, setSelection } from "../category/categorySlice";
import { setCloseOnExit, setError, setTempError, setTempSuccess } from "../message/messageSlice";
const namespace = "serviceTemplate";

const initialState = {
    rosterPeriodId: null,
    entries: null,
    selected: 0
};

export const getServiceTemplates = createAsyncThunk(`${namespace}/getServiceTemplates`, async (payload, { dispatch, rejectWithValue }) => {
    try {
        dispatch(prepare(payload.rosterPeriodId));
        const { data } = await axios.get("serviceTemplate/" + payload.serviceCircleId);
        return data;
    } catch (error) {
        return rejectWithValue(error.response.status);
    }
});

export const createServiceTemplate = createAsyncThunk(`${namespace}/createServiceTemplate`, async (payload, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.post("serviceTemplate/" + payload.rosterPeriodId, payload.data);
        dispatch(setTempSuccess(`Der Dienst «${payload.data.name}» wurde erfolgreich gespeichert.`));
        dispatch(setCloseOnExit());
        return data;
    } catch (error) {
        dispatch(setError(`Der Dienst «${payload.data.name}» konnte nicht erstellt werden.`));
        return rejectWithValue(error.response.status);
    }
});

export const updateServiceTemplate = createAsyncThunk(`${namespace}/updateServiceTemplate`, async (payload, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.patch("serviceTemplate/" + payload.id, payload.data);
        dispatch(setTempSuccess(`Der Dienst «${payload.data.name}» wurde erfolgreich gespeichert.`));
        dispatch(setCloseOnExit());
        return data;
    } catch (error) {
        dispatch(setError(`Der Dienst «${payload.data.name}» konnte nicht gespeichert werden.`));
        return rejectWithValue(error.response.status);
    }
});

export const updateServiceTemplateColor = createAsyncThunk(`${namespace}/updateServiceTemplateColor`, async (payload, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.patch("serviceTemplate/" + payload.id + "/color", payload.data);
        dispatch(setTempSuccess(`Die Farbe wurde erfolgreich gespeichert.`));
        return data;
    } catch (error) {
        dispatch(setError(`Der Farbe konnte nicht gespeichert werden.`));
        return rejectWithValue(error.response.status);
    }
});

export const deleteServiceTemplate = createAsyncThunk(`${namespace}/deleteServiceTemplate`, async (payload, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.delete("serviceTemplate/" + payload);
        dispatch(setTempSuccess(`Der Dienst wurde erfolgreich gelöscht.`));
        return data;
    } catch (error) {
        dispatch(setTempError(`Der Dienst konnte nicht gelöscht werden, da er in Verwendung ist.`));
        return rejectWithValue(error.response.status);
    }
});

const serviceTemplateSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        prepare: (state, action) => {
            state.rosterPeriodId = action.payload;
            state.entries = null;
        },
        selectServiceTemplate: (state, action) => {
            state.selected = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getServiceTemplates.fulfilled, (state, { payload }) => {
                state.entries = payload;
            })
            .addCase(createServiceTemplate.fulfilled, (state, { payload }) => {
                state.entries.push(payload);
            })
            .addCase(updateServiceTemplate.fulfilled, (state, { payload }) => {
                state.entries = state.entries.map((entry) => {
                    if (entry.id === payload.id) {
                        return { ...payload, colorId: entry.colorId };
                    } else {
                        return entry;
                    }
                });
            })
            .addCase(updateServiceTemplateColor.fulfilled, (state, { payload }) => {
                state.entries = state.entries.map((entry) => {
                    if (entry.id === payload.id) {
                        return { ...entry, colorId: payload.colorId };
                    } else {
                        return entry;
                    }
                });
            })
            .addCase(deleteServiceTemplate.fulfilled, (state, { payload }) => {
                state.entries = state.entries.filter((entry) => entry.id !== payload.id);
                state.selected = 0;
            })
            .addCase(selectCategory, (state, { payload }) => {
                return initialState;
            })
            .addCase(selectServiceCircle, (state, { payload }) => {
                return initialState;
            })
            .addCase(selectRosterPeriode, (state, { payload }) => {
                return initialState;
            })
            .addCase(setSelection, (state, { payload }) => {
                return initialState;
            })
            .addCase(logout.fulfilled, (state, { payload }) => {
                return initialState;
            });
    }
});

export const { prepare, selectServiceTemplate } = serviceTemplateSlice.actions;

export default serviceTemplateSlice.reducer;
