import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../shared/axios";
import { logout } from "../auth/authSlice";

const namespace = "personInformation";

const initialState = {
	person: null,
	medicalPractices: [],
	marked: null
};

export const getPersonInformation = createAsyncThunk(`${namespace}/getPersonInformation`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.get("person/" + payload + "/information");
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

const personInformationSlice = createSlice({
	name: namespace,
	initialState,
	reducers: {
		markPerson: (state, { payload }) => {
			state.marked = state.marked === payload ? null : payload;
		},
		clearPerson: (state) => {
			return initialState;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getPersonInformation.fulfilled, (state, { payload }) => {
				state.person = payload.person;
				state.medicalPractices = payload.medicalPractices;
			})

			.addCase(logout.fulfilled, (state, { payload }) => {
				return initialState;
			});
	}
});

export const { clearPerson, markPerson } = personInformationSlice.actions;

export default personInformationSlice.reducer;
