import { useState } from "react";
import { useSelector } from "react-redux";

import { ReactComponent as IconPhone } from "../../../assets/images/icon_phone.svg";
import { ReactComponent as IconMobile } from "../../../assets/images/icon_mobile.svg";
import { ReactComponent as IconFax } from "../../../assets/images/icon_fax.svg";

import Panel from "../../../hoc/Panel/Panel";
import Window from "../../../hoc/Window/Window";
import EditLabel from "../EditLabel/EditLabel";
import styles from "./EditPhone.module.css";
import FooterButtons from "../../../ui/FooterButtons/FooterButtons";
import CheckBox from "../../../ui/CheckBox/CheckBox";

const phoneDataTemplate = {
	id: 0,
	type: "",
	label: "",
	labelColor: "green",
	phoneCountryId: 41,
	number: "",
	secret: false
};

const phoneTypes = {
	mobile: {
		icon: <IconMobile />,
		name: "Mobile"
	},
	phone: {
		icon: <IconPhone />,
		name: "Festnetz"
	},
	fax: {
		icon: <IconFax />,
		name: "Fax"
	}
};

const EditPhone = ({ cancel, data = null, save, labelPlaceholder }) => {
	const [phoneData, setPhoneData] = useState(data ? { ...data } : { ...phoneDataTemplate });
	const phoneCountry = useSelector((state) => state.values.phoneCountry);

	const change = (fieldname, fieldvalue) => {
		setPhoneData((prev) => ({ ...prev, [fieldname]: fieldvalue }));
	};

	const setSecret = (value) => {
		setPhoneData((prev) => ({ ...prev, secret: value }));
	};

	const disabled = !phoneData.type || !phoneData.number;

	const savePhone = () => {
		save(phoneData);
		cancel();
	};

	const title = data ? "Telefon & Fax bearbeiten" : "Neue Telefon- oder Faxnummer";
	const maxNumberLength = phoneData.phoneCountryId === 41 ? 10 : 16;
	const detailLabelPlaceholder = (phoneData.type ? phoneTypes[phoneData.type].name + " " : "") + labelPlaceholder;

	return (
		<Window>
			<Panel title={title} size="medium" padding>
				<div className={styles.twoCols}>
					<div className={styles.radios}>
						<label className={styles.label}>Typ</label>
						{Object.keys(phoneTypes).map((type) => (
							<label key={type} className={styles.radioLabel}>
								<input type="radio" name="type" value={type} checked={phoneData.type === type} onChange={(e) => change(e.target.name, e.target.value)} />
								{phoneTypes[type].icon} {phoneTypes[type].name}
							</label>
						))}
					</div>
					<EditLabel label={phoneData.label} labelColor={phoneData.labelColor} labelPlaceholder={detailLabelPlaceholder} change={change} />
					<CheckBox value="yes" checked={phoneData.secret} name="Geheim" set={setSecret} />
				</div>
				<br />
				<div className={styles.twoCols + " " + styles.half}>
					<label className={styles.label}>
						Landesvorwahl
						<select name="phoneCountryId" size={1} value={phoneData.phoneCountryId} onChange={(e) => change(e.target.name, e.target.value)}>
							{phoneCountry.map((entry) => (
								<option key={entry.id} value={entry.id}>
									{entry.country}
								</option>
							))}
						</select>
					</label>
					<label className={styles.label}>
						Nummer
						<input type="text" name="number" value={phoneData.number} onChange={(e) => change(e.target.name, e.target.value)} maxLength={maxNumberLength} />
					</label>
				</div>
				<FooterButtons paddingTop>
					<button type="button" onClick={() => cancel()}>
						Abbrechen
					</button>
					<button type="button" onClick={savePhone} disabled={disabled}>
						OK
					</button>
				</FooterButtons>
			</Panel>
		</Window>
	);
};

export default EditPhone;
