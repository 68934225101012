import { useSelector } from "react-redux";

import EditPublicHoliday from "../../../components/PublicHolidays/EditPublicHoliday/EditPublicHoliday";

const PublicHoliday = () => {
	const loaded = useSelector((state) => state.publicHoliday.roster) !== null;
	return loaded ? <EditPublicHoliday /> : null;
};

export default PublicHoliday;
