import { createSlice } from "@reduxjs/toolkit";

const namespace = "ask";

const initialState = {
	type: null,
	title: null,
	question: null,
	buttons: null,
	answer: null
};

const askSlice = createSlice({
	name: namespace,
	initialState,
	reducers: {
		ask: (state, { payload }) => {
			state.type = payload.type;
			state.title = payload.title;
			state.question = payload.question;
			state.buttons = payload.buttons;
			state.answer = null;
		},
		clear: (state, action) => {
			return initialState;
		},
		answer: (state, { payload }) => {
			return {
				...initialState,
				answer: {
					value: payload,
					type: state.type
				}
			};
		}
	}
});

export const { ask, clear, answer } = askSlice.actions;

export default askSlice.reducer;
