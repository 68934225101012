import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Panel from "../../../hoc/Panel/Panel";
import Window from "../../../hoc/Window/Window";
import styles from "./EditPublicHoliday.module.css";
import FooterButtons from "../../../ui/FooterButtons/FooterButtons";
import Fieldset from "../../../hoc/Fieldset/Fieldset";
import Note from "../../Note/Note";
import CheckBox from "../../../ui/CheckBox/CheckBox";

import { createDay, updateDay, deleteDay } from "../../../features/publicHoliday/publicHolidaySlice";

import * as routes from "../../../shared/routes";

const newData = {
    id: null,
    name: "",
    serviceCircleId: "0",
    delete: false
};

const EditPublicHoliday = () => {
    const { year, month, day } = useParams();
    const navigate = useNavigate();
    const date = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    const dispatch = useDispatch();

    const { categoryId, serviceTypeId, serviceCircleId } = useSelector((state) => state.category.selected);
    const dayData = useSelector((state) => state.publicHoliday.roster).filter((entry) => entry.date === date);
    const globalDayData = dayData.find((entry) => entry.serviceCircleId === "0");
    const localDayData = dayData.find((entry) => entry.serviceCircleId === serviceCircleId);
    const otherLocalDayData = dayData.filter((entry) => entry.serviceCircleId !== "0" && entry.serviceCircleId !== serviceCircleId);
    const [data, setData] = useState(globalDayData ? { ...globalDayData, delete: false } : localDayData ? { ...localDayData, delete: false } : { ...newData, day, month, year });
    const [overWriteLocalPublicHolidays, setOverWriteLocalPublicHolidays] = useState(false);
    const ref = useRef();
    const categories = useSelector((state) => state.category.categories);
    const serviceTypes = useSelector((state) => state.category.serviceTypes);
    const serviceCircles = useSelector((state) => state.category.serviceCircles);

    const category = categoryId ? categories.find((entry) => entry.id === categoryId).name : "";
    const serviceType = serviceTypeId ? serviceTypes.find((entry) => entry.id === serviceTypeId).name : "";
    const serviceCircle = serviceCircleId ? serviceCircles.find((entry) => entry.id === serviceCircleId).name : "";

    const currentServiceCircle = serviceCircleId !== "0" ? category + " | " + serviceType + " | " + serviceCircle : "";
    const isGlobal = data.serviceCircleId === "0";
    const disabled = !data.delete && (data.name.trim().length < 2 || (isGlobal && otherLocalDayData.length > 0 && !overWriteLocalPublicHolidays));

    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, []);

    const cancel = () => {
        navigate(routes.PUBLIC_HOLIDAYS + "/" + year);
    };

    const change = (e) => {
        const fieldname = e.target.name;
        const value = e.target.value;
        setData((prev) => ({ ...prev, [fieldname]: value }));
    };

    const changeType = (e) => {
        const value = e.target.value;
        setData((prev) => ({ ...prev, serviceCircleId: value }));
    };

    const changeDelete = (e) => {
        const checked = e.target.checked;
        setData((prev) => ({ ...prev, delete: checked }));
    };

    const saveDay = () => {
        const redirect = routes.PUBLIC_HOLIDAYS + "/" + year;
        if (data.id) {
            if (data.delete) {
                dispatch(deleteDay({ id: data.id, success: `Der Feiertag «${data.name}» wurde erfolgreich entfernt`, redirect }));
            } else {
                dispatch(updateDay({ id: data.id, data: { ...data }, success: `Der Feiertag «${data.name}» wurde erfolgreich gespeichert`, redirect }));
            }
        } else {
            dispatch(createDay({ data: { ...data }, success: `Der Feiertag «${data.name}» wurde erfolgreich erstellt`, redirect }));
        }
    };

    const setOverWrite = (checked) => {
        setOverWriteLocalPublicHolidays(checked);
    };

    return (
        <Window>
            <Panel title={data.id ? "Feiertag ändern" : "Neuer Feiertag"} size="small" padding>
                {data.id && (
                    <div className={styles.radios}>
                        <label>
                            <input type="checkbox" name="delete" value="yes" checked={data.delete} onChange={changeDelete} />
                            Feiertag entfernen
                        </label>
                    </div>
                )}
                {!data.delete && (
                    <Fragment>
                        <label className={styles.name}>
                            Name
                            <input type="text" ref={ref} name="name" value={data.name} maxLength={32} onChange={change} />
                        </label>
                        <div className={styles.radios}>
                            <label>
                                <input type="radio" name="type" value="0" checked={isGlobal} onChange={changeType} />
                                Globaler Feiertag
                            </label>
                            <label>
                                <input type="radio" name="type" value={serviceCircleId} checked={!isGlobal} onChange={changeType} disabled={serviceCircleId === "0"} />
                                Feiertag im gewähltem Dienstkreis
                            </label>
                            {serviceCircleId !== "" && <div className={styles.embed}>({currentServiceCircle})</div>}
                        </div>
                    </Fragment>
                )}
                {otherLocalDayData.length > 0 && (
                    <div className={styles.otherPublicHolidays}>
                        <Fieldset legend="Feiertage anderer Dienstkreise an diesem Tag">
                            <ul className={isGlobal && overWriteLocalPublicHolidays ? styles.deleteLocalPublicHolidays : ""}>
                                {otherLocalDayData.map((entry) => (
                                    <li key={entry.id}>{entry.name}</li>
                                ))}
                            </ul>
                            {isGlobal && (
                                <Fragment>
                                    <br />
                                    <CheckBox checked={overWriteLocalPublicHolidays} name="Mir ist bewusst dass durch die Definition eines globalen Feiertages die Feiertage der Dienstkreise überschrieben werden" set={setOverWrite} />
                                </Fragment>
                            )}
                        </Fieldset>
                    </div>
                )}
                <Note marginTop>
                    Globale Feiertage gelten für alle Dienstkreise. Möchte ein Arzt an einem globalen Feiertag nicht arbeiten, wird dieser Tag seinem Kontingent an Feiertagen abgezogen.
                    <p>Feiertage eines einzelnen Dienstkreises werden nicht am Kontingent der Feiertage eines Arztes abgezogen.</p>
                </Note>
                <FooterButtons paddingTop>
                    <button type="button" onClick={cancel}>
                        Abbrechen
                    </button>
                    <button type="button" onClick={saveDay} disabled={disabled}>
                        Speichern
                    </button>
                </FooterButtons>
            </Panel>
        </Window>
    );
};

export default EditPublicHoliday;
