import { getDateObjectFromMySqlDate } from "../../../shared/functions";
import { monthNames, dayNames } from "../../../shared/globals";

import Day from "./Day/Day";

const Month = ({ dates }) => {
	const calendar = [];
	let i = 0;

	// Dayname Header
	dayNames.forEach((day) => {
		calendar.push(<div key={day}>{day}</div>);
	});

	// Empty Days before start of month
	let firstDay = getDateObjectFromMySqlDate(dates[0].date).getDay();
	firstDay = firstDay ? firstDay - 1 : 6;
	// Sunday - Saturday : 0 - 6
	for (i = 0; i < firstDay; i++) {
		calendar.push(
			<div key={"eb" + i}>
				<br />
			</div>
		);
	}

	// Fill Calendar with submitted days
	dates.forEach((entry) => {
		i++;
		calendar.push(<Day key={entry.date} data={entry} />);
	});

	// Fill Calendar after if days ends not with last day
	const lastDate = getDateObjectFromMySqlDate(dates[dates.length - 1].date);
	const lastDay = lastDate.getDate();
	lastDate.setDate(1);
	lastDate.setMonth(lastDate.getMonth() + 1);
	lastDate.setDate(0);
	if (lastDay !== lastDate.getDate()) {
		for (i = lastDay + 1; i <= lastDate.getDate(); i++) {
			calendar.push(
				<div className="disabled" key={"va" + i}>
					{i}
				</div>
			);
		}
	}

	// Empty days after last day so that the calendar has always same the height
	while (calendar.length < 49) {
		i++;
		calendar.push(
			<div key={"ea" + i}>
				<br />
			</div>
		);
	}

	return (
		<div className="calendarMonth">
			<span>
				{monthNames[lastDate.getMonth()]} {lastDate.getFullYear()}
			</span>
			{calendar}
		</div>
	);
};

export default Month;
