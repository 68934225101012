import { useState } from "react";
import Panel from "../../../hoc/Panel/Panel";
import Window from "../../../hoc/Window/Window";
import EditLabel from "../EditLabel/EditLabel";

import styles from "./EditEMailAddress.module.css";
import FooterButtons from "../../../ui/FooterButtons/FooterButtons";
import { labelColors } from "../../../shared/globals";

const eMailDataTemplate = {
	id: 0,
	label: "",
	labelColor: "green",
	eMail: ""
};

const EditEMailAddress = ({ cancel, data = null, save, labelPlaceholder }) => {
	const [eMailData, seteMailData] = useState(data ? { ...data } : { ...eMailDataTemplate });

	const disabled = !eMailData.eMail;

	const saveEMailAddress = () => {
		save(eMailData);
		cancel();
	};

	const change = (fieldname, fieldvalue) => {
		seteMailData((prev) => ({ ...prev, [fieldname]: fieldvalue }));
	};

	const title = data ? "E-Mail Adresse bearbeiten" : "Neue E-Mail Adresse";
	const labelColor = eMailData.labelColor ? eMailData.labelColor : Object.keys(labelColors)[0];

	return (
		<Window>
			<Panel title={title} size="medium" padding>
				<EditLabel label={eMailData.label} labelColor={labelColor} labelPlaceholder={labelPlaceholder} change={change} />
				<br />
				<label className={styles.label}>
					E-Mail Adresse
					<input type="text" name="eMail" value={eMailData.eMail} onChange={(e) => change(e.target.name, e.target.value)} maxLength={128} />
				</label>
				<FooterButtons paddingTop>
					<button type="button" onClick={() => cancel()}>
						Abbrechen
					</button>
					<button type="button" onClick={saveEMailAddress} disabled={disabled}>
						OK
					</button>
				</FooterButtons>
			</Panel>
		</Window>
	);
};

export default EditEMailAddress;
