import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../shared/axios";
import { logout } from "../auth/authSlice";

const namespace = "values";

const initialState = {
	color: null,
	areasOfExpertise: null,
	phoneCountry: [],
	users: null
};

export const getUsers = createAsyncThunk(`${namespace}/getUsers`, async (_, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.get("auth/users");
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const getColors = createAsyncThunk(`${namespace}/getColors`, async (_, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.get("colors");
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const getPhoneCountries = createAsyncThunk(`${namespace}/getPhoneCountries`, async (_, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.get("phoneCountries");
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const getAreasOfExpertise = createAsyncThunk(`${namespace}/getAreasOfExpertise`, async (_, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.get("areasOfExpertise");
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

const valuesSlice = createSlice({
	name: namespace,
	initialState,
	extraReducers: (builder) => {
		builder
			.addCase(getUsers.fulfilled, (state, { payload }) => {
				state.users = payload;
			})
			.addCase(getUsers.rejected, (state, { payload }) => {
				state.users = [];
			})
			.addCase(getColors.fulfilled, (state, { payload }) => {
				state.color = payload;
			})
			.addCase(getColors.rejected, (state, { payload }) => {
				state.color = [];
			})
			.addCase(getPhoneCountries.fulfilled, (state, { payload }) => {
				state.phoneCountry = payload;
			})
			.addCase(getAreasOfExpertise.fulfilled, (state, { payload }) => {
				state.areasOfExpertise = payload;
			})
			.addCase(getAreasOfExpertise.rejected, (state, { payload }) => {
				state.areasOfExpertise = [];
			})
			.addCase(logout, (state) => {
				return initialState;
			});
	}
});

export default valuesSlice.reducer;
