import styles from "./EditLabel.module.css";

import { labelColors } from "../../../shared/globals";

const EditLabel = ({ label, labelColor, change, labelPlaceholder }) => {
	return (
		<div className={styles.radios}>
			<label className={styles.label}>Label</label>
			<input type="text" maxLength={24} style={labelColors[labelColor]} className={styles.labelField} name="label" value={label} onChange={(e) => change(e.target.name, e.target.value)} placeholder={labelPlaceholder} />
			<div className={styles.colors}>
				{Object.keys(labelColors).map((key) => {
					return <div key={key} onClick={() => change("labelColor", key)} className={key === labelColor ? styles.selected : ""} style={labelColors[key]}></div>;
				})}
			</div>
		</div>
	);
};

export default EditLabel;
