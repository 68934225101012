import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearError } from "../features/message/messageSlice";

import { ReactComponent as Warning } from "../assets/images/icon_warning_red.svg";

import styles from "./Error.module.css";

const Error = () => {
	const error = useSelector((state) => state.message.error);
	const dispatch = useDispatch();

	useEffect(() => {
		return () => {
			dispatch(clearError());
		};
	}, [dispatch]);

	if (!error) {
		return null;
	}
	if (typeof error === "string") {
		return <div className="error">{error}</div>;
	}
	return (
		error && (
			<div className={styles.error}>
				<div>
					<Warning />
					<div>
						{error.map((message, index) => (
							<div key={index}>{message}</div>
						))}
					</div>
				</div>
			</div>
		)
	);
};

export default Error;
