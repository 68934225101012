import { useState } from "react";
import { useDispatch } from "react-redux";

import Panel from "../../../../hoc/Panel/Panel";
import Window from "../../../../hoc/Window/Window";
import FooterButtons from "../../../../ui/FooterButtons/FooterButtons";

import { createPerson } from "../../../../features/person/personSlice";

import styles from "./NewPerson.module.css";
import { validateEmail } from "../../../../shared/functions";
import * as routes from "../../../../shared/routes";
import Error from "../../../../ui/Error";

const genders = {
	w: "Weiblich",
	m: "Männlich"
};

const NewPerson = ({ cancel }) => {
	const [person, setPerson] = useState({
		type: "",
		gender: "",
		title: "",
		firstname: "",
		lastname: "",
		eMail: ""
	});
	const dispatch = useDispatch();

	const disabled = person.type === "" || person.gender === "" || person.firstname.trim().length < 2 || person.lastname.trim().length < 2 || !validateEmail(person.eMail);

	const save = () => {
		dispatch(createPerson({ redirect: routes.PERSONS + "/{id}" + routes.DOCTOR_ACCOUNT, data: { ...person } }));
	};

	const change = (e) => {
		const fieldname = e.target.name;
		const value = e.target.value;

		setPerson((prev) => ({ ...prev, [fieldname]: value }));
	};

	return (
		<Window>
			<Panel title="Neue Person" size="medium" empty padding>
				<Error />
				<div className={styles.content}>
					<label className={styles.label}>
						<span>Typ</span>
						<div className={styles.radios}>
							<label className={styles.radioLabel}>
								<input type="radio" name="type" value="doctor" checked={person.type === "doctor"} onChange={change} />
								Arzt
							</label>
							<label className={styles.radioLabel}>
								<input type="radio" name="type" value="mpa" checked={person.type === "mpa"} onChange={change} />
								Büezer
							</label>
						</div>
					</label>
					<div className={styles.twoCols}>
						<label className={styles.label}>
							<span>Titel</span>
							<input type="text" name="title" value={person.title} onChange={change} maxLength={32} />
						</label>
						<label className={styles.label}>
							<span>Geschlecht</span>
							<div className={styles.radios}>
								<label className={styles.radioLabel}>
									<input type="radio" name="gender" value="w" checked={person.gender === "w"} onChange={change} />
									{genders.w}
								</label>
								<label className={styles.radioLabel}>
									<input type="radio" name="gender" value="m" checked={person.gender === "m"} onChange={change} />
									{genders.m}
								</label>
							</div>
						</label>
					</div>
					<div className={styles.twoCols}>
						<label className={styles.label}>
							<span>Vorname</span>
							<input type="text" name="firstname" value={person.firstname} onChange={change} maxLength={64} />
						</label>
						<label className={styles.label}>
							<span>Nachname</span>
							<input type="text" name="lastname" value={person.lastname} onChange={change} maxLength={64} />
						</label>
					</div>
					<div>
						<label className={styles.label}>
							<span>E-Mail</span>
							<input type="text" name="eMail" value={person.eMail} onChange={change} maxLength={128} className={person.eMail.length === 0 || validateEmail(person.eMail) ? "" : styles.error} />
						</label>
					</div>
					<FooterButtons paddingTop>
						<button type="button" onClick={cancel}>
							Abbrechen
						</button>
						<button type="button" onClick={save} disabled={disabled}>
							Ok
						</button>
					</FooterButtons>
				</div>
			</Panel>
		</Window>
	);
};

export default NewPerson;
