import React, { useEffect, useRef } from "react";

import { ReactComponent as Remove } from "../../assets/images/icon_close.svg";
import styles from "./SearchFilter.module.css";

const SearchFilter = ({ filterText, setFilterText, placeholder = "Suche", focusOnLoad = false }) => {
	const ref = useRef();

	const removeSeachText = () => {
		setFilterText("");
	};

	useEffect(() => {
		if (focusOnLoad) {
			ref.current.focus();
		}
	}, [focusOnLoad]);

	return (
		<div className={styles.searchFilter}>
			{filterText && <Remove onClick={removeSeachText} />}
			<input type="text" ref={ref} placeholder={placeholder} value={filterText} onChange={(event) => setFilterText(event.target.value)} />
		</div>
	);
};

export default SearchFilter;
