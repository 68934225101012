import { ReactComponent as ArrowRight } from "../../../../assets/images/icon_chevron_right.svg";
import { ReactComponent as Edit } from "../../../../assets/images/icon_edit.svg";
import styles from "./CategoryColItem.module.css";

const CategoryColItem = ({ selected, children, showArrow = false, action = null, edit = null }) => {
	const classNames = [styles.item];
	selected && classNames.push(styles.selected);
	action && classNames.push(styles.click);
	return (
		<li className={classNames.join(" ")} onClick={action ? () => action() : null}>
			{children}
			{showArrow && !edit && <ArrowRight />}
			{edit && <Edit className={styles.icon} onClick={() => edit()} />}
		</li>
	);
};

export default CategoryColItem;
