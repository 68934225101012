import { Fragment } from "react";
import Phone from "../../../ui/Phone/Phone";
import ResourceRow from "../../../ui/ResourceRow/ResourceRow";
import { phoneTypes } from "../../../shared/globals";
import EmailAddress from "../../../ui/EmailAddress/EmailAddress";
import ResourceList from "../../../ui/List/ResourceList/ResourceList";

const MedicalPractice = ({ medicalPractice }) => {
	const phoneEntries = medicalPractice.phones.map((phone) => ({
		id: phone.id,
		name: (
			<ResourceRow label={phone.label || `${phoneTypes[phone.type]} Praxis`} labelColor={phone.labelColor} icon={phone.type} secret={phone.secret}>
				<Phone phone={phone} />
			</ResourceRow>
		)
	}));

	const eMailEntries = medicalPractice.eMails.map((eMail) => ({
		id: eMail.id,
		name: (
			<ResourceRow label={eMail.label || "Praxis"} labelColor={eMail.labelColor} icon="eMail">
				<EmailAddress eMail={eMail.eMail} />
			</ResourceRow>
		)
	}));

	return (
		<Fragment>
			<div>
				<label>
					<span>Praxis</span>
					{medicalPractice.name && (
						<>
							{medicalPractice.name}
							<br />
						</>
					)}
					{medicalPractice.street}
					<br />
					{medicalPractice.street2 && (
						<>
							{medicalPractice.street2}
							<br />
						</>
					)}
					{medicalPractice.zip} {medicalPractice.city}
				</label>
				{medicalPractice.gln && (
					<label>
						<span>GLN</span>
						{medicalPractice.gln}
					</label>
				)}
			</div>
			<div>
				<label>
					<span>Telefon & Fax</span>
					<ResourceList entries={phoneEntries} alternating={false} />
				</label>
				<label>
					<span>E-Mail Adressen</span>
					<ResourceList entries={eMailEntries} alternating={false} />
				</label>
			</div>
		</Fragment>
	);
};

export default MedicalPractice;
