import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ask, clear } from "../../../../../features/ask/askSlice";
import { clearInsert, showCategorySelector } from "../../../../../features/category/categorySlice";
import { addPersonToServiceType, removePersonFromServiceType } from "../../../../../features/person/personSlice";
import SmallPanel from "../../../../../hoc/SmallPanel/SmallPanel";
import * as askTypes from "../../../../../shared/askTypes";
import { ReactComponent as Trash } from "../../../../../assets/images/icon_trash.svg";
import styles from "./ServiceTypes.module.css";

const ServiceTypes = ({ serviceTypeIds, personId }) => {
	const dispatch = useDispatch();
	const insert = useSelector((state) => state.category.insert);
	const categories = useSelector((state) => state.category.categories);
	const serviceTypes = useSelector((state) => state.category.serviceTypes);
	const answer = useSelector((state) => state.ask.answer);

	useEffect(() => {
		if (insert) {
			dispatch(addPersonToServiceType({ personId, data: { serviceTypeId: insert.serviceTypeId } }));
			dispatch(clearInsert());
		}
	}, [insert, dispatch, personId]);

	useEffect(() => {
		if (answer) {
			if (answer.type === askTypes.REMOVE_DOCTOR_FROM_SERVICE_TYPE && answer.value !== null) {
				dispatch(removePersonFromServiceType({ personId, id: answer.value }));
				dispatch(clear());
			}
		}
	}, [answer, dispatch, personId]);

	if (!categories || !serviceTypes) {
		return null;
	}

	const addServiceType = () => {
		dispatch(showCategorySelector({ requiredLevel: 2, mode: "insert" }));
	};

	const removeServiceType = (e, id) => {
		if (e.altKey) {
			dispatch(removePersonFromServiceType({ personId, id }));
		} else {
			const serviceType = serviceTypes.find((entry) => entry.id === id);
			const category = categories.find((entry) => entry.id === serviceType.categoryId);
			dispatch(
				ask({
					type: askTypes.REMOVE_DOCTOR_FROM_SERVICE_TYPE,
					title: "Dienstart löschen",
					question: "Möchtest Du den Arzt wirklich aus der Dienstart «" + category.name + " - " + serviceType.name + "» entfernen?",
					buttons: [
						{ label: "Ja, entfernen", answer: id },
						{ label: "Abbrechen", answer: null }
					]
				})
			);
		}
	};

	return (
		<SmallPanel title="Dienstarten" add={addServiceType}>
			<ul className={styles.content}>
				{serviceTypeIds.length > 0 ? (
					serviceTypeIds.map((serviceTypeId) => {
						const serviceType = serviceTypes.find((entry) => entry.id === serviceTypeId.id);
						const category = categories.find((entry) => entry.id === serviceType.categoryId);
						return (
							<li key={serviceTypeId.id}>
								<div>
									{category.name} - {serviceType.name}
								</div>
								<div className={styles.icons}>
									<Trash className={styles.icon} onClick={(e) => removeServiceType(e, serviceTypeId.id)} />
								</div>
							</li>
						);
					})
				) : (
					<li className={styles.none}>Keine</li>
				)}
			</ul>
		</SmallPanel>
	);
};

export default ServiceTypes;
