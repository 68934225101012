import React, { useRef, useState } from "react";

import styles from "./InputTime.module.css";

const formatTime = (hour, minute) => {
    return `${hour.padStart(2, "0")}:${minute.padStart(2, "0")}:00`;
};

const InputTime = ({ time = null, set, error = false, disabled = false }) => {
    // time ---> 04:30:00
    const [hour, minute] = time ? time.split(":") : ["", ""];
    const [editHour, setEditHour] = useState(hour);
    const [editMinute, setEditMinute] = useState(minute);
    const minuteRef = useRef();

    const classNames = [styles.time];
    error && classNames.push(styles.error);

    const changeHour = (e) => {
        const value = e.target.value;

        if (value === "" || (value.match(/^(\d){1,2}$/) && parseInt(value) < 24)) {
            setEditHour(value);
            set(formatTime(value, editMinute));
            if (value.length === 2) {
                minuteRef.current.focus();
            }
        }
    };

    const changeMinute = (e) => {
        const value = e.target.value;

        if (value === "" || (value.match(/^(\d){1,2}$/) && parseInt(value) < 60)) {
            setEditMinute(value);
            set(formatTime(editHour, value));
        }
    };

    const selectAll = (e) => {
        e.target.select();
    };

    return (
        <div className={classNames.join(" ")}>
            <input type="text" value={editHour} maxLength={2} onChange={changeHour} placeholder="hh" disabled={disabled} />:<input type="text" value={editMinute} ref={minuteRef} maxLength={2} onFocus={selectAll} onChange={changeMinute} placeholder="mm" disabled={disabled} />
        </div>
    );
};

export default InputTime;
