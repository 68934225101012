import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../shared/axios";
import { logout } from "../auth/authSlice";
import { addMedicalPracticeNote, createMedicalPractice, deleteMedicalPractice, updateMedicalPractice, updateMedicalPracticeNote } from "../medicalPractice/medicalPracticeSlice";

const namespace = "medicalPractices";

const initialState = {
	entries: null,
	filter: {
		search: "",
		errors: false,
		areaOfExpertise: 0,
		byServiceType: false,
		byServiceCircle: false,
		bySelector: null
	},
	rosterPeriodDoctorIds: null,
	zipsOfServiceCircle: {
		serviceCircleId: null,
		zips: null
	},
	orderBy: null,
	reload: false
};

export const getMedicalPractices = createAsyncThunk(`${namespace}/getMedicalPractices`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.get("medicalPractices");
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

const medicalPracticesSlice = createSlice({
	name: namespace,
	initialState,
	reducers: {
		setSearch: (state, { payload }) => {
			state.filter.search = payload;
		},
		filterBySelector: (state, { payload }) => {
			state.filter.bySelector = payload;
		},
		toggleFilter: (state, { payload }) => {
			state.filter[payload] = !state.filter[payload];
			if (payload === "byServiceType") {
				state.filter.bySelector = "category";
			}
			if (payload === "byServiceCircle") {
				if (state.filter[payload]) {
					state.filter.byServiceType = true;
				}
				state.filter.bySelector = "category";
			}
		},
		setOrderBy: (state, { payload }) => {
			state.orderBy = payload;
		},
		changeOrderBy: (state, { payload }) => {
			if (state.orderBy.key === payload) {
				state.orderBy.order = state.orderBy.order === "asc" ? "desc" : "asc";
			} else {
				state.orderBy.key = payload;
			}
		},
		clearMedicalPractices: (state) => {
			state.entries = null;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getMedicalPractices.fulfilled, (state, { payload }) => {
				state.entries = payload;
				state.reload = false;
			})
			.addCase(getMedicalPractices.rejected, (state, { payload }) => {
				state.entries = null;
				state.reload = false;
			})
			.addCase(updateMedicalPractice.fulfilled, (state, { payload }) => {
				state.entries = state.entries.map((entry) => (entry.id === payload.id ? payload : entry));
				state.reload = false;
			})
			.addCase(deleteMedicalPractice.fulfilled, (state, { payload }) => {
				state.entries = state.entries.filter((entry) => entry.id !== payload.id);
			})
			.addCase(createMedicalPractice.fulfilled, (state, { payload }) => {
				state.entries = payload.medicalPractices;
				state.reload = false;
			})
			.addCase(addMedicalPracticeNote.fulfilled, (state, { payload }) => {
				state.reload = true;
			})
			.addCase(updateMedicalPracticeNote.fulfilled, (state, { payload }) => {
				state.reload = true;
			})
			.addCase(logout.fulfilled, (state, { payload }) => {
				return initialState;
			});
	}
});

export const { setSearch, filterBySelector, toggleFilter, setAreaOfExpertise, clearMedicalPractices, setOrderBy, changeOrderBy } = medicalPracticesSlice.actions;

export default medicalPracticesSlice.reducer;
