import { useEffect, useRef, useState } from "react";
import Panel from "../../../../hoc/Panel/Panel";
import Window from "../../../../hoc/Window/Window";
import FieldLabel from "../../../../ui/FieldLabel/FieldLabel";

import styles from "./EditSubscription.module.css";
import CheckBox from "../../../../ui/CheckBox/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import { updateRosterSubscribtion, subscribeToRoster, unsubscribeFromRoster } from "../../../../features/roster/rosterSlice";
import { ask, clear } from "../../../../features/ask/askSlice";
import PanelError from "../../../../ui/PanelError/PanelError";
import { dateExists, formatDate, getDateObjectFromMySqlDate, getNfdLoadFromWorkLoad } from "../../../../shared/functions";
import * as askTypes from "../../../../shared/askTypes";
import HideableOptions from "../../../../hoc/HideableOptions/HideableOptions";
import InputDate from "../../../../ui/InputDate/InputDate";
import Note from "../../../Note/Note";
import ShowPerson from "../../../Display/ShowPerson/ShowPerson";
import { rosterStates } from "../../../../shared/globals";

const subscriptionObject = {
    firstname: "",
    lastname: "",
    birthdate: "",
    workload: 0,
    id: "",
    personId: 0,
    minAmount: "",
    maxAmount: "",
    balance: "0",
    canLockOutEvening: false,
    canLockOutEveningDisabled: false,
    lockWeekDays: "",
    lockWeekendDays: "",
    canLockPublicHolidays: false,
    workBegin: null,
    workEnd: null
};

const getWorkBeginEndError = (start, end, rosterPeriodStart, rosterPeriodEnd) => {
    const errors = [];

    const periodStart = getDateObjectFromMySqlDate(rosterPeriodStart);
    const periodEnd = getDateObjectFromMySqlDate(rosterPeriodEnd);

    let hasStartDate = false;
    let hasEndDate = false;
    if (start !== null) {
        if (dateExists(start)) {
            const desiredStart = getDateObjectFromMySqlDate(start);
            if (!(desiredStart > periodStart)) {
                errors.push("Der erste Arbeitstag muss nach dem Beginn der Dienstplan Periode sein.");
            } else if (!(desiredStart < periodEnd)) {
                errors.push("Der erste Arbeitstag muss vor dem Ende der Dienstplan Periode sein.");
            } else {
                hasStartDate = true;
            }
        }
    }
    if (end !== null) {
        if (dateExists(end)) {
            const desiredEnd = getDateObjectFromMySqlDate(end);
            if (!(desiredEnd > periodStart)) {
                errors.push("Der letzte Arbeitstag muss nach dem Beginn der Dienstplan Periode sein.");
            } else if (!(desiredEnd < periodEnd)) {
                errors.push("Der letzte Arbeitstag muss vor dem Ende der Dienstplan Periode sein.");
            } else {
                hasEndDate = true;
            }
        }
    }
    if (hasStartDate && hasEndDate) {
        const desiredStart = getDateObjectFromMySqlDate(start);
        const desiredEnd = getDateObjectFromMySqlDate(end);
        if (!(desiredStart < desiredEnd)) {
            errors.push("Der erste Arbeitstag muss vor dem letzten Arbeitstag sein.");
        }
    }
    return errors;
};

const EditSubscription = ({ close, subscriptionData, rosterPeriodId, serviceByDoctor }) => {
    const [subscription, setSubscription] = useState({ ...subscriptionObject, ...subscriptionData });
    const answer = useSelector((state) => state.ask.answer);
    const rosterPeriodes = useSelector((state) => state.category.rosterPeriodes);
    const rosterPeriod = rosterPeriodes.find((entry) => entry.id === rosterPeriodId);
    console.log(rosterPeriod);

    const canChangeRosterSubscription = rosterStates.find((entry) => entry.state === rosterPeriod.state).privilegs.includes("changeSubscription");
    const canCancelRosterSubscription = rosterStates.find((entry) => entry.state === rosterPeriod.state).privilegs.includes("cancelSubscription");

    const dispatch = useDispatch();
    const ref = useRef();
    const addToRoster = subscription.id === "";

    useEffect(() => {
        ref.current.focus();
    }, []);

    useEffect(() => {
        if (answer) {
            if (answer.type === askTypes.REMOVE_DOCTOR_FROM_ROSTER && answer.value !== null) {
                dispatch(unsubscribeFromRoster({ rosterPeriodId, subscriptionId: subscription.id }));
                dispatch(clear());
            }
        }
    }, [answer, dispatch, rosterPeriodId, subscription.id]);

    useEffect(() => {
        if (subscription.id === "" && (subscription.canLockOutEveningDisabled || !serviceByDoctor)) {
            setSubscription((prev) => ({ ...prev, canLockOutEvening: serviceByDoctor, canLockOutEveningDisabled: true }));
        }
    }, [dispatch, subscription.canLockOutEveningDisabled, subscription.id, serviceByDoctor]);

    const change = (e) => {
        const fieldname = e.target.name;
        const fieldvalue = e.target.value;
        if (fieldvalue.match(/^\d*$/)) {
            setSubscription((prev) => ({ ...prev, [fieldname]: fieldvalue }));
        }
    };

    const changeBalance = (e) => {
        const fieldvalue = e.target.value;
        if (fieldvalue.match(/^-?\d*\.?\d*$/)) {
            setSubscription((prev) => ({ ...prev, balance: fieldvalue }));
        }
    };

    const save = () => {
        dispatch(updateRosterSubscribtion({ rosterPeriodId, subscriptionId: subscription.id, data: subscription }));
    };

    const add = () => {
        dispatch(subscribeToRoster({ rosterPeriodId, personId: subscription.personId, data: subscription }));
    };

    const hasValue = (value) => {
        return !(value === "" || value === "0" || value === 0);
    };

    const setWorkBegin = (workBegin) => {
        setSubscription((prev) => ({ ...prev, workBegin }));
    };

    const setWorkEnd = (workEnd) => {
        setSubscription((prev) => ({ ...prev, workEnd }));
    };

    const removePerson = () => {
        dispatch(
            ask({
                type: askTypes.REMOVE_DOCTOR_FROM_ROSTER,
                title: "Arzt aus dem Dienstplan entfernen",
                question: `Möchtest Du ${subscriptionData.firstname} ${subscriptionData.lastname} wirklich aus dem Dienstplan entfernen?`,
                buttons: [
                    { label: "Abbrechen", answer: null },
                    { label: "Ja, entfernen", answer: true }
                ]
            })
        );
    };

    const notes = [];
    if (subscriptionData.error) {
        notes.push(...subscriptionData.error.split("\n"));
    }
    if (!subscriptionData.hasMedicalPracticeInServiceCircle) {
        notes.push(`${subscriptionData.firstname} ${subscriptionData.lastname} hat keine Praxis in diesem Dienstkreis.`);
    }

    const workBeginEndError = getWorkBeginEndError(subscription.workBegin, subscription.workEnd, rosterPeriod.start, rosterPeriod.end);

    const showOptions = hasValue(subscription.maxAmount) || hasValue(subscription.lockWeekendDays) || subscription.canLockPublicHolidays || subscription.workBegin !== null || subscription.workEnd !== null;

    return (
        <Window>
            <Panel title="Diensteinstellungen" size="medium2">
                <form className={styles.content}>
                    <div className={styles.rows}>
                        <div className={styles.doctor}>
                            <ShowPerson id={subscriptionData.personId} />
                            <div>
                                Notfalldienstpensum: {getNfdLoadFromWorkLoad(subscription.workload)}%<br />
                                Geburtsdatum: {formatDate(getDateObjectFromMySqlDate(subscription.birthdate)) || "-"}
                            </div>
                        </div>
                        {notes.length > 0 && <Note notes={notes} />}
                        {workBeginEndError.length > 0 && <PanelError errors={workBeginEndError} />}
                        <div className={styles.cols}>
                            <FieldLabel label="Pensum">
                                <input className={styles.number} ref={ref} type="text" name="minAmount" value={subscription.minAmount} onChange={change} maxLength={4} disabled={!canChangeRosterSubscription} />%
                            </FieldLabel>
                            <FieldLabel label="Sperrtage/Woche">
                                <input className={styles.number} type="text" name="lockWeekDays" value={subscription.lockWeekDays} onChange={change} maxLength={1} placeholder="0 - 5" disabled={!canChangeRosterSubscription} />
                            </FieldLabel>
                            <FieldLabel label="Punktesaldo">
                                <input className={styles.number} type="text" name="balance" value={subscription.balance} onChange={changeBalance} maxLength={5} disabled={!canChangeRosterSubscription} />
                            </FieldLabel>
                            <FieldLabel label="Kann Abenddienst sperren">
                                <CheckBox checked={subscription.canLockOutEvening} value={true} name="Ja" disabled={subscription.canLockOutEveningDisabled || !canChangeRosterSubscription} set={(lock) => setSubscription((prev) => ({ ...prev, canLockOutEvening: lock }))} />
                            </FieldLabel>
                        </div>
                        <HideableOptions title="Weitere Optionen" showInitial={showOptions} disabled={showOptions || !canChangeRosterSubscription}>
                            <div className={styles.cols}>
                                <FieldLabel label="Maximales Pensum">
                                    <input className={styles.number} type="text" name="maxAmount" value={subscription.maxAmount} onChange={change} maxLength={4} disabled={!canChangeRosterSubscription} />%
                                </FieldLabel>

                                <FieldLabel label="Wochenend-Sperrtage/Woche">
                                    <input className={styles.number} type="text" name="lockWeekendDays" value={subscription.lockWeekendDays} onChange={change} maxLength={1} placeholder="0 - 2" disabled={!canChangeRosterSubscription} />
                                </FieldLabel>

                                <FieldLabel label="Kann Dienst an Feiertagen sperren">
                                    <CheckBox checked={subscription.canLockPublicHolidays} value={true} name="Ja" set={(lock) => setSubscription((prev) => ({ ...prev, canLockPublicHolidays: lock }))} disabled={!canChangeRosterSubscription} />
                                </FieldLabel>
                            </div>
                            <div className={styles.cols}>
                                <HideableOptions title="Erster Arbeitstag" showInitial={subscription.workBegin !== null} changeStateExternal={(value) => setSubscription((prev) => ({ ...prev, workBegin: value ? "" : null }))} disabled={!canChangeRosterSubscription}>
                                    {subscription.workBegin !== null && <InputDate date={subscription.workBegin} set={setWorkBegin} error={subscription.workBegin !== "--" && !dateExists(subscription.workBegin)} disabled={!canChangeRosterSubscription} />}
                                </HideableOptions>
                                <HideableOptions title="Letzter Arbeitstag" showInitial={subscription.workEnd !== null} changeStateExternal={(value) => setSubscription((prev) => ({ ...prev, workEnd: value ? "" : null }))} disabled={!canChangeRosterSubscription}>
                                    {subscription.workEnd !== null && <InputDate date={subscription.workEnd} set={setWorkEnd} error={subscription.workEnd !== "--" && !dateExists(subscription.workEnd)} disabled={!canChangeRosterSubscription} />}
                                </HideableOptions>
                            </div>
                        </HideableOptions>
                    </div>
                    <div className={styles.buttons}>
                        <button type="button" onClick={removePerson} disabled={addToRoster || !canCancelRosterSubscription}>
                            Arzt aus dem Dienstplan entfernen
                        </button>
                        <div>
                            <button type="button" onClick={close}>
                                Abbrechen
                            </button>
                            {addToRoster ? (
                                <button type="button" onClick={add}>
                                    In Dienstplan aufnehmen
                                </button>
                            ) : (
                                <button type="button" onClick={save} disabled={workBeginEndError.length > 0 || !canChangeRosterSubscription}>
                                    OK
                                </button>
                            )}
                        </div>
                    </div>
                </form>
            </Panel>
        </Window>
    );
};

export default EditSubscription;
