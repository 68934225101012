import { useSelector } from "react-redux";
import ColorPanel from "../../../../../hoc/ColorPanel/ColorPanel";

import styles from "./Period.module.css";
import DisplayDate from "../../../../Display/DisplayDate/DisplayDate";

const Period = ({ period }) => {
	const categories = useSelector((state) => state.category.categories);
	const serviceTypes = useSelector((state) => state.category.serviceTypes);
	const serviceCircles = useSelector((state) => state.category.serviceCircles);
	const rosterPeriodes = useSelector((state) => state.category.rosterPeriodes);

	const rosterPeriod = rosterPeriodes.find((entry) => entry.id === period.rosterPeriodId);
	const serviceCircle = rosterPeriod && serviceCircles.find((entry) => entry.id === rosterPeriod.serviceCircleId);
	const serviceTyp = serviceCircle && serviceTypes.find((entry) => entry.id === serviceCircle.serviceTypeId);
	const category = serviceTyp && categories.find((entry) => entry.id === serviceTyp.categoryId);

	// <div>
	// 	{rosterPeriod ? (
	// 		<Fragment>
	// 			<DisplayDate date={rosterPeriod.start} short /> - <DisplayDate date={rosterPeriod.end} short />{" "}
	// 		</Fragment>
	// 	) : (
	// 		""
	// 	)}
	// </div>

	return (
		<ColorPanel color="gold">
			<div className={styles.content}>
				<table>
					<tbody>
						<tr>
							<td>
								<label>Dienstkreis</label>
							</td>
							<td>
								<div className={styles.serviceCircle}>
									<div>{category ? category.name : ""}</div>
									<div>{serviceTyp ? serviceTyp.name : ""}</div>
									<div>{serviceCircle ? serviceCircle.name : ""}</div>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<label>Periode</label>
							</td>
							<td>
								<DisplayDate date={rosterPeriod.start} short /> - <DisplayDate date={rosterPeriod.end} short />
							</td>
						</tr>
						<tr>
							<td>
								<label>Pensum</label>
							</td>
							<td>
								{period.minAmount}
								{period.maxAmount > 0 && ` – ${period.maxAmount}`}%
							</td>
						</tr>
						<tr>
							<td>
								<label>Kann Dienst am Abend sperren</label>
							</td>
							<td>{period.canLockOutEvening === "no" ? "Nein" : "Ja"}</td>
						</tr>
						<tr>
							<td>
								<label>Sperrtage/Woche</label>
							</td>
							<td>{period.lockWeekDays}</td>
						</tr>
						<tr>
							<td>
								<label>Sperrtage/Wochenende</label>
							</td>
							<td>{period.lockWeekendDays}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</ColorPanel>
	);
};

export default Period;
