import ToolTip from "../../../../../ui/ToolTip/ToolTip";
import TimeBar from "./TimeBar/TimeBar";

import styles from "./Day.module.css";
import { serviceTemplateTypes } from "../../../../../shared/globals";
import ShowPerson from "../../../../Display/ShowPerson/ShowPerson";

const Day = ({ date, dateInt, entries, disabled, publicHoliday }) => {
    const dayClassNames = [];

    let toolTip = null;
    if (publicHoliday) {
        toolTip = (
            <ToolTip>
                <span>{publicHoliday.name}</span>
            </ToolTip>
        );
        if (publicHoliday.isGlobal) {
            dayClassNames.push(styles.publicHoliday);
        } else {
            dayClassNames.push(styles.regionalHoliday);
        }
    }

    const bars = [];
    if (entries.length) {
        const maxPos = Math.max(...entries.map((entry) => entry.pos));
        for (let i = 0; i <= maxPos; i++) {
            const entry = entries.find((entry) => entry.pos === i);
            if (entry) {
                const grouped = entry.groupId !== null;
                const barClasses = [styles.bar];
                entry.type === serviceTemplateTypes.fix && barClasses.push(styles.fix);
                const style = {};
                const barStart = entry.times.find((timeEntry) => timeEntry.startDate < dateInt) === undefined;
                barStart && barClasses.push(styles.barStart);
                entry.times.find((timeEntry) => timeEntry.endDate > dateInt) === undefined && barClasses.push(styles.barEnd);
                grouped && barClasses.push(styles.group);
                const times = entry.times.filter((time) => time.startDate <= dateInt && time.endDate >= dateInt).map((time) => <TimeBar key={time.id} time={time} dateInt={dateInt} />);

                bars.push(
                    <div className={barClasses.join(" ")} style={style} key={i}>
                        {barStart && <ShowPerson id={entry.bookedFrom} hideName />}
                        {times}
                    </div>
                );
            } else {
                bars.push(<div className={styles.empty} key={i}></div>);
            }
        }
    }

    return (
        <div className={disabled ? styles.disabled : ""}>
            <div className={styles.head}>
                <div className={dayClassNames.join(" ")}>
                    {toolTip}
                    {(date.getDate() + 100).toString().slice(1)}
                </div>
            </div>
            {bars}
        </div>
    );
};

export default Day;
