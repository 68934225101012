import { ReactComponent as Edit } from "../../../../../assets/images/icon_edit.svg";

// import styles from "./ContingentRow.module.css";

const ContingentRow = ({ row, editable, editId, setEditId }) => {
	return (
		<tr>
			<td>{row.year}</td>
			<td>{row.workDays}</td>
			<td>{row.weekendDays}</td>
			<td>{row.publicHoliDays}</td>
			<td>{editable && <Edit onClick={() => setEditId(row.id)} />}</td>
		</tr>
	);
};

export default ContingentRow;
