import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SmallPanel from "../../../../../hoc/SmallPanel/SmallPanel";
import ResourceRow from "../../../../../ui/ResourceRow/ResourceRow";
import EmailAddress from "../../../../../ui/EmailAddress/EmailAddress";
import ResourceList from "../../../../../ui/List/ResourceList/ResourceList";
import EditEMailAddress from "../../../../ResourceEditors/EditEMailAddress/EditEMailAddress";

import * as askTypes from "../../../../../shared/askTypes";
import { ask, clear } from "../../../../../features/ask/askSlice";
import { addEMail, deleteEMail, updateEMail } from "../../../../../features/medicalPractice/medicalPracticeSlice";

const EMails = ({ medicalPracticeId, eMails }) => {
	const dispatch = useDispatch();
	const [editEmailId, setEditEmailId] = useState(null);
	const answer = useSelector((state) => state.ask.answer);

	useEffect(() => {
		if (answer) {
			if (answer.type === askTypes.DELETE_EMAIL && answer.value !== null) {
				dispatch(deleteEMail({ medicalPracticeId, id: answer.value }));
				dispatch(clear());
			}
		}
	}, [answer, dispatch, medicalPracticeId]);

	const removeEMail = (e, id) => {
		if (e.altKey) {
			dispatch(deleteEMail({ medicalPracticeId, id }));
		} else {
			const eMail = eMails.find((entry) => entry.id === id)?.eMail || "?";
			dispatch(
				ask({
					type: askTypes.DELETE_EMAIL,
					title: `E-Mail Adresse löschen`,
					question: `Möchtest Du die E-Mail Adresse «${eMail}» wirklich löschen?`,
					buttons: [
						{ label: "Ja, löschen", answer: id },
						{ label: "Abbrechen", answer: null }
					]
				})
			);
		}
	};

	const save = (data) => {
		if (data.id) {
			dispatch(updateEMail({ id: medicalPracticeId, data }));
		} else {
			dispatch(addEMail({ id: medicalPracticeId, data }));
		}
	};

	const getData = () => {
		return eMails.find((entry) => entry.id === editEmailId);
	};

	const entries = eMails.map((eMail) => ({
		id: eMail.id,
		name: (
			<ResourceRow label={eMail.label || "Praxis"} labelColor={eMail.labelColor} icon="eMail" auth={eMail.auth}>
				<EmailAddress eMail={eMail.eMail} />
			</ResourceRow>
		)
	}));

	return (
		<Fragment>
			<SmallPanel title="E-Mail Adressen" add={() => setEditEmailId(0)}>
				<ResourceList entries={entries} editItem={setEditEmailId} deleteItem={removeEMail} />
			</SmallPanel>
			{editEmailId !== null && <EditEMailAddress labelPlaceholder="Praxis" save={save} data={getData()} cancel={() => setEditEmailId(null)} />}
		</Fragment>
	);
};

export default EMails;
