import { useEffect, useRef, useState } from "react";

import styles from "./MutableString.module.css";

const MutableString = ({ value, existingValues = [], id, directEdit = false, editing = null, update, maxLength = 32, minLength = 3 }) => {
	const [newValue, setNewValue] = useState(value);
	const [edit, setEdit] = useState(directEdit);
	const [error, setError] = useState(null);
	const ref = useRef();

	useEffect(() => {
		if (ref.current) {
			ref.current.focus();
		}
	}, [edit]);

	const change = (e) => {
		const string = e.target.value;
		setNewValue(string);
		if (string.trim().length < minLength) {
			setError("Der Name muss mindestens " + minLength + " Zeichen lang sein.");
		} else if (existingValues.includes(string)) {
			setError("Dieser Name wird bereits verwendet.");
		} else {
			setError(null);
		}
	};

	const cancelEdit = () => {
		setEdit(false);
		setError(null);
		setNewValue(value);
		editing && editing(false);
	};

	const keyUp = (e) => {
		if (e.key === "Enter" && !error) {
			update(id, newValue);
			setEdit(false);
			editing && editing(false);
		} else if (e.key === "Escape") {
			cancelEdit();
		}
	};

	const startEditing = () => {
		setEdit(true);
		editing && editing(true);
	};

	return edit ? (
		<div className={styles.editContainer}>
			<input ref={ref} className={styles.editString} type="text" maxLength={maxLength} value={newValue} onChange={change} onKeyUp={keyUp} onBlur={cancelEdit} />
			{error && <div className={styles.error}>{error}</div>}
		</div>
	) : (
		<span className={styles.mutableString} onClick={startEditing}>
			{value}
		</span>
	);
};

export default MutableString;
