export const LOGIN = "/anmelden";
export const STRUCTURE = "/struktur";
export const STRUCTURE_GENERAL = "/allgemein";
export const STRUCTURE_CONTINGENT = "/kontingente";
export const GEOGRAPHY = "/geographie";
export const REGION = "/regionen";
export const CATEGORY = "/kategorie";
export const PUBLIC_HOLIDAYS = "/feiertage";
export const MAP = "/plan";
export const AREA = "/bereich";
export const PLAN = "/plan";
export const TIME = "/zeit";
export const SERVICE_TEMPLATES = "/dienstvorlagen";
export const SERVICECICRLE = "/dienstkreis";
export const ROSTER = "/dienstplan";
export const ROSTER_CREATE = "erstellen";
export const ROSTER_TIMES = "zeiten";
export const ROSTER_STATE = "status";
export const ROSTER_DOCTORS = "aerzte";
export const ROSTER_ALLOCATION = "zuteilung";
export const ROSTER_ALLOCATION_TABLE = "tabelle";
export const ROSTER_ALLOCATION_CALENDAR = "kalender";
export const ROSTER_BOOKINGS = "buchungen";
export const MEDICAL_PRACTICES = "/praxen";
export const PERSONS = "/personen";
export const DOCTOR_ACCOUNT = "/konto";
export const DOCTOR_ROSTERS = "/dienstplaene";
export const DOCTOR_POINT_BALANCE = "/punktesaldo";
export const DOCTOR_LOG = "/log";
export const PRAXIS = "praxen";
export const PRAXIS_DATA = "/daten";
export const PRAXIS_LOG = "/log";
