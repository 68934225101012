import { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import SearchFilter from "../../SearchFilter/SearchFilter";
import Note from "../../Note/Note";
import FilterPanel from "./FilterPanel/FilterPanel";
import NewPerson from "../Person/NewPerson/NewPerson";

import { getZipsOfServiceCircleFromRosterPeriod, setAreaOfExpertise, setSearch, filterBySelector, filterByNfdState, toggleFilter, getPersonsOfRosterPeriod, filterByNfdStateDetails } from "../../../features/persons/personsSlice";

import styles from "./PersonFilter.module.css";
import RadioSelect from "../../../ui/RadioSelect/RadioSelect";

const nfdStates = [
    { key: "active_service", value: "Pflichtig, im Einsatz" },
    { key: "active_pay", value: "Pflichtig, bezahlt Ersatzabgabe" },
    { key: "inactive", value: "Nicht pflichtig" }
];

const PersonFilter = () => {
    const [addPerson, setAddPerson] = useState(false);
    const filter = useSelector((state) => state.persons.filter);
    const persons = useSelector((state) => state.persons.entries);
    const zipsOfServiceCircle = useSelector((state) => state.persons.zipsOfServiceCircle.zips);
    const areasOfExpertise = useSelector((state) => state.values.areasOfExpertise) || [];
    const { pathname } = useLocation();

    const { categoryId, serviceTypeId, serviceCircleId, rosterPeriodId } = useSelector((state) => state.category.selected);

    const dispatch = useDispatch();

    const nfdStateDetails =
        filter.nfdState === "inactive"
            ? persons
                  .filter((entry) => entry.nfdState === "inactive")
                  .map((entry) => entry.nfdStateDetails)
                  .filter((entry, index, allPersons) => allPersons.indexOf(entry) === index)
                  .map((entry) => ({ key: entry, value: entry }))
            : null;

    useEffect(() => {
        if (serviceCircleId) {
            dispatch(getZipsOfServiceCircleFromRosterPeriod({ serviceCircleId, rosterPeriodId }));
        }
    }, [serviceCircleId, rosterPeriodId, dispatch]);

    useEffect(() => {
        if (rosterPeriodId) {
            dispatch(getPersonsOfRosterPeriod(rosterPeriodId));
        }
    }, [rosterPeriodId, dispatch]);

    useEffect(() => {
        setAddPerson(false);
    }, [pathname]);

    useEffect(() => {
        if (filter.nfdState === "inactive" && filter.nfdStateDetails !== "" && !nfdStateDetails.find((entry) => entry.value === filter.nfdStateDetails)) {
            dispatch(filterByNfdStateDetails(""));
        }
    }, [persons, filter.nfdState, filter.nfdStateDetails, nfdStateDetails, dispatch]);

    const setFilterText = (value) => {
        dispatch(setSearch(value));
    };

    const changeAreaOfExpertise = (e) => {
        const id = e.target.value;
        dispatch(setAreaOfExpertise(id));
    };

    const setNfdState = (value) => {
        dispatch(filterByNfdState(value));
    };

    const setNfdStateDetails = (value) => {
        dispatch(filterByNfdStateDetails(value));
    };

    return (
        <div className={styles.content}>
            <div className={styles.contentTop}>
                <button type="button" onClick={() => setAddPerson(true)}>
                    Neue Person
                </button>
                <SearchFilter filterText={filter.search} setFilterText={setFilterText} placeholder="Suche Nachname, Vorname, GLN, Notiz" />
            </div>
            <FilterPanel name="Aktueller Selektor">
                <div>
                    <label className={styles.radio}>
                        <input type="radio" checked={filter.bySelector === null} onChange={() => dispatch(filterBySelector(null))} />
                        <span>Nicht beachten</span>
                    </label>
                </div>
                <label className={styles.radio}>
                    <input type="radio" checked={filter.bySelector === "category"} disabled={!(categoryId > 0)} onChange={() => dispatch(filterBySelector("category"))} />
                    <span>Fachbereich</span>
                </label>
                {serviceTypeId > 0 && (
                    <Fragment>
                        <div className={styles.indent}>
                            <label className={styles.radio}>
                                <input type="checkbox" checked={filter.byServiceType} disabled={filter.bySelector !== "category" || filter.byServiceCircle} onChange={() => dispatch(toggleFilter("byServiceType"))} />
                                <span>Dienstart</span>
                            </label>
                            {serviceCircleId > 0 && rosterPeriodId > 0 && (
                                <div className={styles.indent}>
                                    <label className={styles.radio}>
                                        <input type="checkbox" checked={filter.byServiceCircle} disabled={filter.bySelector !== "category" || zipsOfServiceCircle === null || zipsOfServiceCircle.length === 0} onChange={() => dispatch(toggleFilter("byServiceCircle"))} />
                                        <span>Dienstkreis</span>
                                    </label>
                                    {(zipsOfServiceCircle === null || zipsOfServiceCircle.length === 0) && <Note>Am ersten Tag der gewählten Dienstperiode wurde keine Basiskarte hinterlegt oder diese enthält keine Orte für den gewählten Dienstkreis</Note>}
                                </div>
                            )}
                        </div>
                    </Fragment>
                )}
                <div>
                    <label className={styles.radio}>
                        <input type="radio" checked={filter.bySelector === "period"} disabled={!(rosterPeriodId > 0)} onChange={() => dispatch(filterBySelector("period"))} />
                        <span>Dienstperiode</span>
                    </label>
                </div>
            </FilterPanel>
            <FilterPanel name="Andere Filter">
                <div className={styles.filters}>
                    <div>
                        <label className={styles.smallLabel}>Spezialgebiet</label>
                        <select size={1} value={filter.areaOfExpertise} onChange={changeAreaOfExpertise}>
                            <option value="">Alle Spezialgebiete</option>
                            {areasOfExpertise.map((entry) => (
                                <option key={entry.id} value={entry.id}>
                                    {entry.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <RadioSelect data={nfdStates} label="Notfalldienst-Status" keyName="key" valueName="value" selected={filter.nfdState} setSelected={setNfdState} none="" noneLabel="Alle" />
                        {nfdStateDetails && (
                            <div className={styles.indent}>
                                <RadioSelect data={nfdStateDetails} label="Grund" keyName="key" valueName="value" selected={filter.nfdStateDetails} setSelected={setNfdStateDetails} none="" noneLabel="Alle" />
                            </div>
                        )}
                    </div>
                    <label className={styles.radio}>
                        <input type="checkbox" checked={filter.errors} onChange={() => dispatch(toggleFilter("errors"))} />
                        <span>Personen mit fehlerhaften Daten</span>
                    </label>
                </div>
            </FilterPanel>
            {addPerson && <NewPerson cancel={() => setAddPerson(false)} />}
        </div>
    );
};

export default PersonFilter;
