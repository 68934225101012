import { useSelector } from "react-redux";
import { hasWhiteText } from "../../shared/functions";

const ColorRect = ({ colorId, className = "", children }) => {
	const colors = useSelector((state) => state.values.color);
	const color = colors.find((entry) => entry.id === colorId);
	const style = { backgroundColor: color ? "#" + color.color : "white" };
	if (hasWhiteText(color.color)) {
		style.color = "white";
	}

	return (
		<div className={className} style={style}>
			{children}
		</div>
	);
};

export default ColorRect;
