import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../shared/axios";
import { logout } from "../auth/authSlice";
import { setError, setTempError, setTempSuccess } from "../message/messageSlice";
import { setRedirect } from "../app/appSlice";
import { clearRosterState } from "../roster/rosterSlice";

const namespace = "person";

const initialState = {
	person: null,
	log: null,
	rosterPeriods: null,
	edit: null
};

export const getPerson = createAsyncThunk(`${namespace}/getPerson`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.get("person/" + payload);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const updatePerson = createAsyncThunk(`${namespace}/updatePerson`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.patch("person/" + payload.id, payload.data);
		dispatch(setTempSuccess("Die persönlichen Daten wurden erfolgreich gespeichert."));
		dispatch(clearRosterState());
		return data;
	} catch (error) {
		if (error.response.data.message) {
			dispatch(setError(error.response.data.message));
		}
		return rejectWithValue(error.response.status);
	}
});

export const createPerson = createAsyncThunk(`${namespace}/createPerson`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("person", payload.data);
		dispatch(setTempSuccess("Die Person wurde erfolgreich erstellt."));
		dispatch(setRedirect(payload.redirect.replace("{id}", data.id)));
		return data;
	} catch (error) {
		if (error.response.status === 409) {
			dispatch(setError("Diese E-Mail Adresse exisiert bereits"));
		}
		return rejectWithValue(error.response.status);
	}
});

export const deletePerson = createAsyncThunk(`${namespace}/deletePerson`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.delete("person/" + payload.personId);
		dispatch(setRedirect(payload.redirect));
		dispatch(setTempSuccess("Die Person wurde erfolgreich gelöscht."));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const addAreaOfExpertise = createAsyncThunk(`${namespace}/addAreaOfExpertise`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("person/" + payload.id + "/areaOfExpertise", payload.data);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const deleteAreaOfExpertise = createAsyncThunk(`${namespace}/deleteAreaOfExpertise`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.delete("person/" + payload.personId + "/areaOfExpertise/" + payload.id);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const addPhone = createAsyncThunk(`${namespace}/addPhone`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("person/" + payload.id + "/phone", payload.data);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const updatePhone = createAsyncThunk(`${namespace}/updatePhone`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.patch("person/" + payload.id + "/phone/" + payload.data.id, payload.data);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const deletePhone = createAsyncThunk(`${namespace}/deletePhone`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.delete("person/" + payload.personId + "/phone/" + payload.id);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const addEMail = createAsyncThunk(`${namespace}/addEMail`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("person/" + payload.id + "/eMail", payload.data);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const updateEMail = createAsyncThunk(`${namespace}/updateEMail`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.patch("person/" + payload.id + "/eMail/" + payload.data.id, payload.data);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const deleteEMail = createAsyncThunk(`${namespace}/deleteEMail`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.delete("person/" + payload.personId + "/eMail/" + payload.id);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const docBoxChangesAccepted = createAsyncThunk(`${namespace}/docBoxChangesAccepted`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.patch("person/" + payload + "/docBoxChanges");
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const getPersonLog = createAsyncThunk(`${namespace}/getPersonLog`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.get("person/" + payload + "/log");
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const getPersonRosterPeriods = createAsyncThunk(`${namespace}/getPersonRosterPeriods`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.get("person/" + payload + "/rosterPeriods");
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const addPersonToServiceType = createAsyncThunk(`${namespace}/addPersonToServiceType`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("person/" + payload.personId + "/serviceType", payload.data);
		dispatch(clearRosterState());
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const removePersonFromServiceType = createAsyncThunk(`${namespace}/removePersonFromServiceType`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.delete("person/" + payload.personId + "/serviceType/" + payload.id);
		dispatch(clearRosterState());
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const removePersonFromMedicalPractice = createAsyncThunk(`${namespace}/removePersonFromMedicalPractice`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.delete("person/" + payload.personId + "/medicalPractice/" + payload.medicalPracticeId);
		dispatch(setTempSuccess("Die Person wurde erfolgreich aus der Praxis entfernt."));
		dispatch(clearRosterState());
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const addOrUpdatePersonFromMedicalPractice = createAsyncThunk(`${namespace}/addOrUpdatePersonFromMedicalPractice`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.patch("person/" + payload.personId + "/medicalPractice/" + payload.medicalPracticeId, payload.data);
		dispatch(setTempSuccess("Die Person wurde erfolgreich gespeichert."));
		dispatch(clearRosterState());
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const addPersonNote = createAsyncThunk(`${namespace}/addPersonNote`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("person/" + payload.personId + "/note", payload.data);
		return data;
	} catch (error) {
		dispatch(setTempError("Die Notiz konnte nicht erstellt werden."));
		return rejectWithValue(error.response.status);
	}
});

export const updatePersonNote = createAsyncThunk(`${namespace}/updatePersonNote`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.patch("person/" + payload.personId + "/note/" + payload.id, payload.data);
		return data;
	} catch (error) {
		dispatch(setTempError("Die Notiz konnte nicht aktualisiert werden."));
		return rejectWithValue(error.response.status);
	}
});

const personSlice = createSlice({
	name: namespace,
	initialState,
	reducers: {
		clearPersonLog: (state) => {
			state.log = null;
		},
		clearPerson: (state) => {
			return initialState;
		},
		startEdit: (state, { payload }) => {
			state.edit = payload;
		},
		stopEdit: (state) => {
			state.edit = null;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getPerson.fulfilled, (state, { payload }) => {
				state.person = payload;
			})
			.addCase(docBoxChangesAccepted.fulfilled, (state, { payload }) => {
				state.person = payload;
			})
			.addCase(updatePerson.fulfilled, (state, { payload }) => {
				state.person = { ...state.person, ...payload };
				state.edit = null;
			})
			.addCase(deletePerson.fulfilled, (state, { payload }) => {
				return initialState;
			})
			.addCase(addAreaOfExpertise.fulfilled, (state, { payload }) => {
				state.person.areasOfExpertise.push(payload);
			})
			.addCase(deleteAreaOfExpertise.fulfilled, (state, { payload }) => {
				state.person.areasOfExpertise = state.person.areasOfExpertise.filter((entry) => entry.id !== payload.id);
			})
			.addCase(addPhone.fulfilled, (state, { payload }) => {
				state.person.phones.push(payload);
			})
			.addCase(updatePhone.fulfilled, (state, { payload }) => {
				state.person.phones = state.person.phones.map((entry) => {
					if (entry.id === payload.phone.id) {
						return payload.phone;
					} else {
						return entry;
					}
				});
				state.person.error = payload.error;
			})
			.addCase(deletePhone.fulfilled, (state, { payload }) => {
				state.person.phones = state.person.phones.filter((entry) => entry.id !== payload.id);
				state.person.error = payload.error;
			})
			.addCase(addEMail.fulfilled, (state, { payload }) => {
				state.person.eMails.push(payload);
			})
			.addCase(updateEMail.fulfilled, (state, { payload }) => {
				state.person.eMails = state.person.eMails.map((entry) => {
					if (entry.id === payload.eMail.id) {
						return { ...entry, ...payload.eMail };
					} else {
						return entry;
					}
				});
				state.person.error = payload.error;
			})
			.addCase(deleteEMail.fulfilled, (state, { payload }) => {
				state.person.eMails = state.person.eMails.filter((entry) => entry.id !== payload.id);
				state.person.error = payload.error;
			})
			.addCase(getPersonLog.fulfilled, (state, { payload }) => {
				state.log = payload;
			})
			.addCase(getPersonLog.rejected, (state, { payload }) => {
				state.log = [];
			})
			.addCase(getPersonRosterPeriods.fulfilled, (state, { payload }) => {
				state.rosterPeriods = payload;
			})
			.addCase(getPersonRosterPeriods.rejected, (state, { payload }) => {
				state.rosterPeriods = [];
			})
			.addCase(addPersonToServiceType.fulfilled, (state, { payload }) => {
				state.person.serviceTypes.push(payload);
			})
			.addCase(removePersonFromServiceType.fulfilled, (state, { payload }) => {
				state.person.serviceTypes = state.person.serviceTypes.filter((entry) => entry.id !== payload.id);
			})
			.addCase(addOrUpdatePersonFromMedicalPractice.fulfilled, (state, { payload }) => {
				state.person.practices = payload;
			})
			.addCase(removePersonFromMedicalPractice.fulfilled, (state, { payload }) => {
				state.person.practices = payload;
			})
			.addCase(addPersonNote.fulfilled, (state, { payload }) => {
				state.person.notes = payload;
			})
			.addCase(updatePersonNote.fulfilled, (state, { payload }) => {
				state.person.notes = payload;
			})
			.addCase(logout.fulfilled, (state, { payload }) => {
				return initialState;
			});
	}
});

export const { clearPersonLog, clearPerson, startEdit, stopEdit } = personSlice.actions;

export default personSlice.reducer;
