import { useState } from "react";
import Panel from "../../../../hoc/Panel/Panel";
import Window from "../../../../hoc/Window/Window";
import RadioButton from "../../../../ui/RadioSelect/RadioButton/RadioButton";
import styles from "./InsertOptions.module.css";
import FooterButtons from "../../../../ui/FooterButtons/FooterButtons";

const InsertOptions = ({ title, cancel, insert }) => {
	const [insertMode, setInsertMode] = useState("single");

	const setMode = (mode) => {
		setInsertMode(mode);
	};

	const ok = () => {
		insert(insertMode);
	};

	return (
		<Window>
			<Panel title={title} size="small" padding>
				<div className={styles.label}>
					<RadioButton value="single" name="An diesem Tag" selected={insertMode} set={setMode} />
					<RadioButton value="daily" name="Täglich bis zum Ende des Dienstplans" selected={insertMode} set={setMode} />
					<RadioButton value="weekly" name="Wöchentlich bis zum Ende des Dienstplans" selected={insertMode} set={setMode} />
				</div>
				<FooterButtons paddingTop>
					<button type="button" onClick={cancel}>
						Abbrechen
					</button>
					<button type="button" onClick={ok}>
						OK
					</button>
				</FooterButtons>
			</Panel>
		</Window>
	);
};

export default InsertOptions;
