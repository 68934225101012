import EditableListItem from "./EditableListItem/EditableListItem";
import styles from "./EditableList.module.css";

const EditableList = ({ entries, selected, select, edit = null, deleteItem = null, changeColor }) => {
	const resultList = entries.filter((entry) => !entry.disabled);
	return (
		<ul className={styles.list}>
			{resultList.map((entry) => {
				const deleteAction = (deleteItem && !entry.usedCount) || entry.usedCount === 0 ? deleteItem : null;
				return (
					<EditableListItem key={entry.id} id={entry.id} name={entry.name} colorId={entry.colorId} select={select} selected={entry.id === selected} disabled={entry.disabled} edit={edit} deleteItem={deleteAction} changeColor={changeColor} usedCount={entry.usedCount} locked={entry.locked} />
				);
			})}
			{resultList.length === 0 && (
				<li key="none" className={styles.none}>
					Keine
				</li>
			)}
		</ul>
	);
};

export default EditableList;
