import { useDispatch, useSelector } from "react-redux";
import Panel from "../../hoc/Panel/Panel";
import Window from "../../hoc/Window/Window";
import styles from "./PersonInformation.module.css";
import { clearPerson } from "../../features/personInformation/personInformationSlice";
import SmallPanel from "../../hoc/SmallPanel/SmallPanel";
import { useNavigate } from "react-router-dom";
import * as routes from "../../shared/routes";
import Person from "./Person/Person";
import MedicalPractice from "./MedicalPractice/MedicalPractice";

const PersonInformation = () => {
	const person = useSelector((state) => state.personInformation.person);
	const medicalPractices = useSelector((state) => state.personInformation.medicalPractices);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	if (person === null) {
		return null;
	}

	const clear = () => {
		dispatch(clearPerson());
	};

	const goToPerson = () => {
		clear();
		navigate(routes.PERSONS + "/" + person.id + routes.DOCTOR_ACCOUNT);
	};

	const goToMedicalPractice = (id) => {
		clear();
		navigate(routes.MEDICAL_PRACTICES + "/" + id + routes.PRAXIS_DATA);
	};

	return (
		<Window>
			<Panel title="Informationen zum Arzt" size="large" close={clear} padding>
				<div className={styles.content}>
					<SmallPanel title="Arzt" jump={goToPerson}>
						<div className={styles.panelContent}>
							<Person person={person} />
						</div>
					</SmallPanel>
					{medicalPractices.map((medicalPractice) => (
						<SmallPanel key={medicalPractice.id} title="Praxis" jump={() => goToMedicalPractice(medicalPractice.id)}>
							<div className={styles.panelContent}>
								<MedicalPractice medicalPractice={medicalPractice} />
							</div>
						</SmallPanel>
					))}
				</div>
			</Panel>
		</Window>
	);
};

export default PersonInformation;
