import React from "react";

import { ReactComponent as NotesIcon } from "../../assets/images/icon_postit_gelb.svg";

import styles from "./NoteIcon.module.css";

const NoteIcon = ({ count, marked }) => {
	return (
		<div className={styles.content}>
			<NotesIcon className={styles.noteIcon} />
			{count !== undefined && (
				<div className={styles.count}>
					<div title="Anzahl markierter Notizen">{marked ? marked : "0"}</div>
					<div title="Anzahl Notizen">{count}</div>
				</div>
			)}
		</div>
	);
};

export default NoteIcon;
