import { useEffect, useState } from "react";
import { useNavigate, useParams, useRoutes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Panel from "../../../hoc/Panel/Panel";
import Window from "../../../hoc/Window/Window";
import Contingent from "./Contingent/Contingent";

import { clearSettings, getSettings } from "../../../features/serviceCircle/serviceCircleSlice";
import * as routes from "../../../shared/routes";
import SettingsBrowser from "../../../hoc/SettingsBrowser/SettingsBrowser";

const pages = [
	{
		path: routes.STRUCTURE_CONTINGENT,
		element: <Contingent />,
		name: "Kontingente"
	}
];

const EditServiceCircle = () => {
	const [serviceCircleSettings, setServiceCircleSettings] = useState(null);
	const navigate = useNavigate();
	const { id } = useParams();
	const dispatch = useDispatch();
	const settings = useSelector((state) => state.serviceCircle.settings);

	const baseUrl = routes.STRUCTURE + "/" + id;

	const showRoutes = useRoutes([
		{
			path: "",
			element: <SettingsBrowser menus={pages} baseUrl={baseUrl} />,
			children: pages
		}
	]);

	useEffect(() => {
		dispatch(getSettings(id));
	}, [dispatch, id]);

	useEffect(() => {
		if (settings) {
			setServiceCircleSettings({ ...settings });
		}
	}, [settings]);

	useEffect(() => {
		return () => dispatch(clearSettings());
	}, [dispatch]);

	if (!serviceCircleSettings) {
		return null;
	}

	const cancel = () => {
		navigate(routes.STRUCTURE);
	};

	return (
		<Window>
			<Panel title="Dienstkreis bearbeiten" size="large" close={cancel}>
				{showRoutes}
			</Panel>
		</Window>
	);
};

export default EditServiceCircle;
