import React, { useEffect, useRef, useState } from "react";
import styles from "./InputWithPredefinedValues.module.css";

const InputWithPredefinedValues = ({ value, name, setValue, predefinedValues, placeholder = "", maxLength = 0 }) => {
	const [hasFocus, setHasFocus] = useState(false);
	const [selected, setSelected] = useState(-1);
	const ref = useRef();

	useEffect(() => {
		setSelected(predefinedValues.indexOf(value.trim()));
	}, [value, predefinedValues]);

	const gotFocus = () => {
		setHasFocus(true);
		setSelected(predefinedValues.indexOf(value.trim()));
	};

	const change = (e) => {
		setValue(e.target.value);
	};

	const keyDown = (e) => {
		setHasFocus(true);
		if (predefinedValues.length > 0) {
			if (e.code === "Enter") {
				setHasFocus(false);
			} else {
				let setToIndex = -1;
				if (e.code === "ArrowDown") {
					if (selected !== -1) {
						if (predefinedValues.length > selected + 1) {
							setToIndex = selected + 1;
						}
					} else {
						setToIndex = 0;
					}
				} else if (e.code === "ArrowUp") {
					if (selected !== -1) {
						if (selected > 0) {
							setToIndex = selected - 1;
						}
					} else {
						setToIndex = predefinedValues.length - 1;
					}
				}
				if (setToIndex > -1) {
					setValue(predefinedValues[setToIndex]);
					ref.current.select();
					e.preventDefault();
					e.stopPropagation();
				}
			}
		}
	};

	return (
		<div className={styles.inputWithPredefinedValues}>
			<input ref={ref} type="text" name={name} value={value} onChange={change} maxLength={maxLength} onFocus={gotFocus} onBlur={() => setHasFocus(false)} onKeyDown={keyDown} placeholder={placeholder} />

			{hasFocus && predefinedValues.length > 0 && (
				<ul>
					{predefinedValues.map((predefinedValue, index) => (
						<li className={index === selected ? styles.selected : ""} key={predefinedValue} onMouseDown={() => setValue(predefinedValue)}>
							{predefinedValue}
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default InputWithPredefinedValues;
