import { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import Month from "./Month/Month";

import styles from "./PublicHolidayCalendar.module.css";
import { dateIsInFuture, getMySqlDateFromDateObject } from "../../shared/functions";

import * as routes from "../../shared/routes";

const dateValidAndInFuture = (year, month, day) => {
	const checkDate = new Date(year, month - 1, day);
	if (year === checkDate.getFullYear() && month - 1 === checkDate.getMonth() && day === checkDate.getDate()) {
		return dateIsInFuture(day, month, year);
	}
	return false;
};

const PublicHolidayCalendar = () => {
	const { year, month, day } = useParams();
	const navigate = useNavigate();
	const publicHolidayRoster = useSelector((state) => state.publicHoliday.roster);

	const serviceCircleId = useSelector((state) => state.category.selected.serviceCircleId);
	const publicHolidays = publicHolidayRoster ? publicHolidayRoster.filter((entry) => entry.serviceCircleId === "0" || entry.serviceCircleId === serviceCircleId) : [];

	useEffect(() => {
		if (year.match(/^\d{4}$/)) {
			if (month === undefined && day === undefined) return;
			if (!dateValidAndInFuture(parseInt(year, 10), parseInt(month, 10), parseInt(day, 10))) {
				navigate(routes.PUBLIC_HOLIDAYS + "/" + year);
			}
		} else {
			navigate(routes.PUBLIC_HOLIDAYS + "/" + new Date().getFullYear());
		}
	}, [year, month, day, navigate]);

	const calendars = [...Array(12)].map((dummy, month) => {
		const currentDate = new Date(year, month, 1);
		const dates = [];

		while (currentDate.getMonth() === month) {
			const date = getMySqlDateFromDateObject(currentDate);
			const dayData = publicHolidays ? publicHolidays.find((entry) => entry.date === date) : undefined;
			dates.push({
				date,
				name: dayData?.name || null,
				id: dayData ? dayData.id : "0",
				serviceCircleId: dayData ? dayData.serviceCircleId : "0"
			});
			currentDate.setDate(currentDate.getDate() + 1);
		}
		return <Month key={month} dates={dates} />;
	});

	return (
		<Fragment>
			<div className={styles.content}>{calendars}</div>
			<Outlet />
		</Fragment>
	);
};

export default PublicHolidayCalendar;
