import React from "react";

import { ReactComponent as Add } from "../../../../assets/images/icon_plus.svg";
import styles from "./ColHeader.module.css";

const ColHeader = ({ title, add }) => {
	return (
		<div className="header">
			{title}
			{add && <Add className={styles.add} onClick={add} />}
		</div>
	);
};

export default ColHeader;
