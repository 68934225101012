import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ask, clear } from "../../../features/ask/askSlice";
import { cancelEditBaseMap, deleteBaseMap, getBaseMap, newBaseMap, selectBaseMap, updateBaseMapColor } from "../../../features/geographyPlan/geographyPlanSlice";
import SmallPanel from "../../../hoc/SmallPanel/SmallPanel";
import ColorChooser from "../../ColorChooser/ColorChooser";
import EditableList from "../../EditableList/EditableList";

import * as askTypes from "../../../shared/askTypes";
import EditMap from "../EditMap/EditMap";

const BaseMapSelector = ({ serviceTypeId }) => {
	const baseMaps = useSelector((state) => state.geographyPlan.baseMaps);
	const selectedBaseMapId = useSelector((state) => state.geographyPlan.selectedBaseMapId);
	const map = useSelector((state) => state.geographyPlan.map);
	const answer = useSelector((state) => state.ask.answer);
	const [chooseColor, setChooseColor] = useState(0);
	const dispatch = useDispatch();

	useEffect(() => {
		if (answer) {
			if (answer.type === askTypes.DELETE_BASE_MAP && answer.value !== null) {
				const map = baseMaps.find((entry) => entry.id === answer.value);
				dispatch(deleteBaseMap({ id: map.id, success: `Die Basiskarte «${map.name}» wurde erfolgreich gelöscht.` }));
				dispatch(clear());
			}
		}
	}, [answer, dispatch, baseMaps]);

	if (!baseMaps || !serviceTypeId) {
		return null;
	}

	const onSelect = (id) => {
		dispatch(selectBaseMap(id));
	};

	const edit = () => {
		dispatch(getBaseMap({ serviceTypeId, baseMapId: selectedBaseMapId }));
	};

	const cancelEdit = () => {
		dispatch(cancelEditBaseMap());
	};

	const newMap = () => {
		dispatch(newBaseMap());
	};

	const deleteItem = (id) => {
		const map = baseMaps.find((entry) => entry.id === id);
		dispatch(
			ask({
				type: askTypes.DELETE_BASE_MAP,
				title: "Basiskarte löschen",
				question: `Möchtest Du die Basiskarte «${map.name}» wirklich löschen?`,
				buttons: [
					{ label: "Abbrechen", answer: null },
					{ label: "Ja, löschen", answer: id }
				]
			})
		);
	};

	const changeColor = (id) => {
		setChooseColor(id);
	};

	const selectedColor = chooseColor ? baseMaps.find((entry) => entry.id === chooseColor).colorId : 0;

	const setColor = (colorId) => {
		if (selectedColor !== colorId) {
			dispatch(updateBaseMapColor({ id: chooseColor, data: { colorId }, success: "Die Farbe wurde erfolgreich gespeichert." }));
		}
		setChooseColor(0);
	};

	return (
		<div>
			<SmallPanel title="Basiskarten" add={newMap}>
				<EditableList entries={baseMaps} selected={selectedBaseMapId} select={onSelect} edit={edit} deleteItem={deleteItem} changeColor={changeColor} />
			</SmallPanel>
			{chooseColor > 0 && <ColorChooser usedColors={baseMaps.map((entry) => entry.colorId)} selected={selectedColor} cancel={() => setChooseColor(0)} setColor={setColor} colorMustBeUnique={false} />}
			{map && <EditMap cancel={cancelEdit} />}
		</div>
	);
};

export default BaseMapSelector;
