import { useState } from "react";
import styles from "./HideableOptions.module.css";

const HideableOptions = ({ title, showInitial, disabled, changeStateExternal = null, children }) => {
    const [showOptions, setShowOptions] = useState(showInitial);

    const classNames = [styles.fieldset];

    const display = changeStateExternal === null ? showOptions : showInitial;

    !display && classNames.push(styles.hide);

    const change = () => {
        if (changeStateExternal === null) {
            setShowOptions((prev) => !prev);
        } else {
            changeStateExternal(!showInitial);
        }
    };

    return (
        <fieldset className={classNames.join(" ")}>
            <legend>
                <label>
                    <input type="checkbox" checked={display} onChange={change} disabled={disabled} />
                    {title}
                </label>
            </legend>
            <div className={styles.content}>{children}</div>
        </fieldset>
    );
};

export default HideableOptions;
